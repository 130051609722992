import { Col, Container, Row, Card } from "react-bootstrap"
import { t } from '../../../misc/utils'

import { useMediaQuery } from 'react-responsive'

const BusinessReadyComponent = () => {
    const Desktop = useMediaQuery({ query: '(max-width:1199px)' })
    const mobile1 = useMediaQuery({ query: '(max-width:743px)' })
    return (
        <>
            {Desktop && mobile1 == false ?
                <div className='card-7-register'>
                    <Card.Body className='background-pink card-7'>
                        <div className="container-default">
                            <Row>
                                <Col xs={6}>
                                    <div className='left-side-7'>
                                        <b>{t.addgoods.card2.textTitle}</b>
                                        <p className='text'>
                                            {t.addgoods.card2.textContent}
                                        </p>
                                    </div>
                                </Col>
                                <Col xs={6}>
                                    <div className='right-side-7'>
                                        <a alt='' href='/register' className='btn btn-warning-up'>{t.addgoods.card2.textButton}</a>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Card.Body>
                </div>
                : Desktop && mobile1 == true ?
                    <div className='card-7-register'>
                        <Card.Body className='background-pink card-7'>
                            <div className="container-default">
                                <Row>
                                    <Col xs={12}>
                                        <div className='left-side-7'>
                                            <b>{t.addgoods.card2.textTitle}</b>
                                            <p className='text'>
                                                {t.addgoods.card2.textContent}
                                            </p>
                                        </div>
                                    </Col>
                                    <Col xs={12}>
                                        <div className='left-side'>
                                            <a alt='' href='/register' className='btn btn-warning-up'>{t.addgoods.card2.textButton}</a>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Card.Body>
                    </div>
                    :
                    <div className='card-7-register'>
                        <Card.Body className='background-pink card-7'>
                            <div className="container-default">
                                <Row>
                                    <Col xs={6}>
                                        <div className='left-side-7'>
                                            <b>{t.addgoods.card2.textTitle}</b>
                                            <p className='text'>
                                                {t.addgoods.card2.textContent}
                                            </p>
                                        </div>
                                    </Col>
                                    <Col xs={6}>
                                        <div className='right-side-7'>
                                            <a alt='' href='/register' className='btn btn-warning-up'>{t.addgoods.card2.textButton}</a>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Card.Body>
                    </div>

            }
        </>
    )
}

export default BusinessReadyComponent