import { React } from 'react'
import { useMediaQuery } from 'react-responsive'
import { Container, Card, Row, Col } from 'react-bootstrap';

import { TopNavHome, TopNavMain, Footer, GuidesComponent, QNA } from '../index'
import './style.css'
import AcceptOrderComponent from '../component/home/guides/acceptOrder';
import BusinessReadyComponent from '../component/home/businessReady';


const Acceptorder = () => {
    return (
        <div className='main'>
            <TopNavMain />
            <AcceptOrderComponent />
            <GuidesComponent />
            <BusinessReadyComponent/>
            <QNA />
            <Footer />
        </div >
    )
};

export default Acceptorder

