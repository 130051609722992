import { Container, Card, Row, Col } from 'react-bootstrap'
import { t } from '../../../misc/utils'
import step1regis from '../../../assets/image/step1regis.svg'
import step2regis from '../../../assets/image/step2regis.svg'
import step3regis from '../../../assets/image/step3regis.svg'
import step4regis from '../../../assets/image/step4regis.svg'

const Registration = ({ mobile, desktop }) => {
    return (
        <div className='tutorial'>
            <div className='container-default'>
                {mobile ? <b>{t.addgoods.textTitle}</b> : <p className='label'>{t.addgoods.textTitle}</p>}
                <Row>
                    <Col xs={mobile ? 12 : 6} className={mobile ? 'img-center' : 'img-center padding-vertical70' }>
                        <img src={step1regis} height={mobile ? 160 : "100%"}></img>
                    </Col>
                    <Col xs={mobile ? 12 : 6} className={mobile ? 'img-center padding-vertical25' : 'img-center padding-vertical70'}>
                        <ul>
                            <li>
                                <p>{t.guides.register.textStep1}</p>
                            </li>                            
                        </ul>
                    </Col>
                    <Col xs={mobile ? 12 : 6} className={mobile ? 'img-center' : 'img-center padding-vertical70' }>
                        <img src={step2regis} height={mobile ? 160 : "100%"}></img>
                    </Col>
                    <Col xs={mobile ? 12 : 6} className={mobile ? 'img-center padding-vertical25' : 'img-center padding-vertical70'}>
                        <ul>
                            <li>
                                <p>{t.guides.register.textStep2}</p>
                            </li>                            
                        </ul>
                    </Col>
                    <Col xs={mobile ? 12 : 6} className={mobile ? 'img-center' : 'img-center padding-vertical70' }>
                        <img src={step3regis} height={mobile ? 160 : "100%"}></img>
                    </Col>
                    <Col xs={mobile ? 12 : 6} className={mobile ? 'img-center padding-vertical25' : 'img-center padding-vertical70'}>
                        <ul>
                            <li>
                                <p>{t.guides.register.textStep3}</p>
                            </li>                            
                        </ul>
                    </Col>
                    <Col xs={mobile ? 12 : 6} className={mobile ? 'img-center' : 'img-center padding-vertical70' }>
                        <img src={step4regis} height={mobile ? 160 : "100%"}></img>
                    </Col>
                    <Col xs={mobile ? 12 : 6} className={mobile ? 'img-center padding-vertical25' : 'img-center padding-vertical70'}>
                        <ul>
                            <li>
                                <p>{t.guides.register.textStep4}</p>
                            </li>
                        </ul>
                    </Col>
                </Row>
            </div >
        </div>
    )
}

export default Registration