import { Accordion, AccordionDetails, AccordionSummary, InputAdornment, TextField, Typography } from "@mui/material"
import { Dropdown, Modal } from "react-bootstrap"
import { AccountCircle, ExpandMore, Language, Search } from '@mui/icons-material'
import { LanguageComponent } from "../.."
import './style.css'
import { useDispatch, useSelector } from "react-redux"
import React from "react"
import { getCity, getCountry } from "../../../application/action"
import SearchIcon from '../../../assets/component/SearchIcon.svg'
import CroosIcon from '../../../assets/component/icon_cross.svg'
import logomaxim from '../../../assets/image/logo.png'
import { t } from '../../../misc/utils'

const listLanguage = [
    { id: 1, type: 'en', name: 'English' },
    { id: 1, type: 'es', name: 'Español' },
    { id: 1, type: 'id', name: 'Bahasa Indonesia' },
    { id: 1, type: 'ms', name: 'Bahasa Melayu' },
    { id: 1, type: 'ph', name: 'Filipino' },
    { id: 1, type: 'pt', name: 'Português (Brasileiro)' },
    { id: 1, type: 'th', name: 'ไทย' },
    { id: 1, type: 'zh', name: '中文' },
]

export const ModalInitial = ({ show, onHide, handleModalChosser, mobile, smallDesktop, hide }) => {
    const { localCountries, localLanguage, localCity } = {
        localCountries: JSON.parse(localStorage.getItem('country')),
        localLanguage: localStorage.getItem('language'),
        localCity: localStorage.getItem('city')
    }
    const [dropdownLang, setDropdownlang] = React.useState(false)
    const [langBefore, setLangBefore] = React.useState(null)
    const [lang, setLang] = React.useState(localLanguage)
    const [modalLangCountry, setModallangCountry] = React.useState(false)
    const [modalCity, setModalCity] = React.useState(false)
    const [countries, setCountries] = React.useState(null)
    const [inputSearch, setInputSearch] = React.useState('')
    const [search, setSearch] = React.useState(null)
    const dispatch = useDispatch()
    React.useEffect(() => {
        dispatch(getCountry())
    }, [])
    const { country, city } = useSelector((state) => {
        return {
            country: state.Register.data_country,
            city: state.Register.data_cities
        }
    })

    const handleChooser = (item, typeFunction) => {
        if (typeFunction === 'language') {
            setLangBefore(localLanguage)
            localStorage.setItem(typeFunction, item.type)
            setLang(item.type)
            setDropdownlang(false)
        }
        else if (typeFunction === 'country') {
            localStorage.setItem(typeFunction, JSON.stringify({ name: item.name, id: item.id }))
            setCountries({ name: item.name, id: item.id })
            if (lang) {
                setModallangCountry(false)
                setModalCity(true)
            }
        }
        else {
            localStorage.setItem(typeFunction, JSON.stringify({ name: item.name, id: item.id, country_id: item.country_id, address: item.address_contact, email: item.email }))
            setModalCity(false)
            onHide(false)
           window.location.reload()

        }
    }
    const onSearchChange = (e) => {
        if(e.target.value.length > 0) {
            const searched = city?.filter((item) => item.name.toLowerCase().indexOf(e.target.value.toLowerCase()) >= 0)
            setSearch(searched)
        }
        else {
            setSearch(null)
        }
        setInputSearch(e.target.value)
    }
    const onKeyUp = (e) => {
        if (e.keyCode === 13) {
            e.target.blur();
        }
    }

    React.useEffect(() => {
        if (countries) {
            dispatch(getCity(countries?.id))
        }
    }, [countries])


    React.useEffect(() => {
        if (show) {
            if (!localCountries) {
                setModallangCountry(true)
            }
            else {
                setModalCity(true)
            }
            document.body.style.overflow = 'hidden';
            if ((hide && mobile) || (hide && smallDesktop)) {
                document.body.style.overflow = "hidden"
            } else {
                return () => document.body.style.overflow = 'unset';
            }
            
        }
    }, [show]);
    return (
        <>
            {mobile ?
                <>
                    <div className={show ? "backdrop-modal-initial" : 'backdrop-modal-initial-close'} />
                    <div className="modal-initial-container">
                        <div className={`transition-modal-initial-city-${modalLangCountry ? 'mobile-open' : 'close'}`}>
                            <div className="container-content-modal-initial-city">
                                <div className="container-content-modal-initial-city-chooser-mobile">
                                    <div>
                                        <p><a href="/"><img className="logo-maxim" alt='' src={logomaxim} /></a></p>

                                    </div>
                                    {localCity &&
                                        <div onClick={() => {
                                            onHide(false)
                                            setModalCity(false)
                                        }}>
                                            <img src={CroosIcon} />
                                        </div>
                                    }
                                </div>
                                <div className="fake-dropdown-content">
                                                <p>{t.languageModal.textTitle}</p>
                                                <div className="btn-chooser-language-container" onClick={() => setDropdownlang(prev => !prev)} >
                                                    <div className="btn-chooser-language-container-flex">
                                                        <Language style={{ marginRight: 18 }} />{listLanguage.map((item) => item.type === lang ? item.name : '')}
                                                    </div>
                                                </div>
                                                <div className={`transition-dropdown language-${dropdownLang ? 'open' : 'close'}`}>
                                                    <div className="list-language-drop-down">
                                                        {listLanguage.map((item, index) => {
                                                            return (
                                                                <>
                                                                    <span key={index} className={lang === item.type ? "card-language-choosed" : "card-language"} onClick={() => handleChooser(item, 'language')}>{item.name}</span>
                                                                </>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                                <div className="list-country-chooser">
                                                    {country?.result?.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
                                                        .map((item, index) => {
                                                            return (
                                                                <>
                                                                    <span key={index} className={countries?.id === item.id ? "card-country-selector-1-selected" : "card-country-selector-1"} onClick={() => handleChooser(item, 'country')}>{item.name}</span>
                                                                </>
                                                            )
                                                        })}
                                                </div>
                                            </div>


                            </div>
                        </div>
                    </div>
                    <div className="modal-initial-container">
                        <div className={`transition-modal-initial-city-${modalCity ? 'mobile-open' : 'close'}`}>
                            <div className="container-content-modal-initial-city">
                                <div className="container-content-modal-initial-city-chooser-mobile">
                                    <div>
                                        <p><a href="/"><img className="logo-maxim" alt='' src={logomaxim} /></a></p>

                                    </div>
                                    {localCity &&
                                        <div onClick={() => {
                                            onHide(false)
                                            setModalCity(false)
                                        }}>
                                            <img src={CroosIcon} />
                                        </div>
                                    }
                                </div>
                                <div className="margin-top20">
                                    <p className="text-city-chooser">{t.languageModal.textChooseCity}</p>
                                </div>
                                <div className="search-input-container-mobile">
                                    <div className='search-input-button-wrapper'>
                                        <img src={SearchIcon} className='search-input-button' />
                                    </div>
                                    <input
                                        type="text"
                                        className="search-input"
                                        placeholder={t.languageModal.search}
                                        onChange={onSearchChange}
                                        onKeyUp={onKeyUp}
                                    />
                                </div>
                                <div className="list-city-chooser-mobile">
                                    {search ?
                                    search?.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
                                    .map((item, index) => {
                                        return (
                                            <>
                                                <span  className="city-card" key={index} onClick={() => handleChooser(item, 'city')}>{item.name}</span>
                                            </>
                                        )
                                    })
                                    :
                                    city?.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
                                        .map((item, index) => {
                                            return (
                                                <>
                                                    <span  className="city-card" key={index} onClick={() => handleChooser(item, 'city')}>{item.name}</span>
                                                </>
                                            )
                                        })}
                                </div>


                            </div>
                        </div>
                    </div>
                </>
                :
                <>
                    <div className={show ? "backdrop-modal-initial" : 'backdrop-modal-initial-close'} />
                    <div className="modal-initial-container">
                        <div className={`transition-modal-initial-${modalLangCountry ? 'open' : 'close'}`}>
                            <div className="modal-initial-body">
                                <p>{t.languageModal.textTitle}</p>
                                <div className="btn-chooser-language-container" onClick={() => setDropdownlang(prev => !prev)} >
                                    <div className="btn-chooser-language-container-flex">
                                        <Language style={{ marginRight: 18 }} />{listLanguage.map((item) => item.type === lang ? item.name : '')}
                                    </div>
                                </div>
                                <div className={`transition-dropdown language-${dropdownLang ? 'open' : 'close'}`}>
                                    <div className="list-language-drop-down">
                                        {listLanguage.map((item, index) => {
                                            return (
                                                <>
                                                    <span key={index} className={lang === item.type ? "card-language-choosed" : "card-language"} onClick={() => handleChooser(item, 'language')}>{item.name}</span>
                                                </>
                                            )
                                        })}
                                    </div>
                                </div>
                                <div className="list-country-chooser">
                                    {country?.result?.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
                                        .map((item, index) => {
                                            return (
                                                <>
                                                    <span key={index} className={countries?.id === item.id ? "card-country-selector-1-selected" : "card-country-selector-1"} onClick={() => handleChooser(item, 'country')}>{item.name}</span>
                                                </>
                                            )
                                        })}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-initial-container">
                        <div className={`transition-modal-initial-city-${modalCity ? 'open' : 'close'}`}>
                            <div className="container-content-modal-initial-city">
                                <div className="container-content-modal-initial-city-chooser">
                                    <div>
                                        <p>{t.languageModal.textChooseCity}</p>
                                    </div>
                                    {localCity &&
                                        <div onClick={() => {
                                            onHide(false)
                                            setModalCity(false)
                                        }}>
                                            <img src={CroosIcon} />
                                        </div>
                                    }
                                </div>
                                <div className="search-input-container">
                                    <div className='search-input-button-wrapper'>
                                        <img src={SearchIcon} className='search-input-button' />
                                    </div>
                                    <input
                                        type="text"
                                        className="search-input"
                                        placeholder={t.languageModal.search}
                                        onChange={onSearchChange}
                                        onKeyUp={onKeyUp}
                                    />
                                </div>
                                <div className="list-city-chooser">
                                {search ?
                                    search?.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
                                    .map((item, index) => {
                                        return (
                                            <>
                                                <span  className="city-card" key={index} onClick={() => handleChooser(item, 'city')}>{item.name}</span>
                                            </>
                                        )
                                    })
                                    :
                                    city?.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
                                        .map((item, index) => {
                                            return (
                                                <>
                                                    <span  className="city-card" key={index} onClick={() => handleChooser(item, 'city')}>{item.name}</span>
                                                </>
                                            )
                                        })}
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    )
}

export const ModalChooser = ({ type, show, onHide }) => {
    const { localCity, localCountry, localLanguage } = {
        localCity: JSON.parse(localStorage.getItem('city')),
        localCountry: JSON.parse(localStorage.getItem('country')),
        localLanguage: localStorage.getItem('language')
    }
    const dispatch = useDispatch()
    const [countryId, setCountryId] = React.useState(localCountry?.id)

    React.useEffect(() => {
        dispatch(getCountry())
        dispatch(getCity(countryId))
    }, [countryId])

    const { country, city } = useSelector((state) => {
        return {
            country: state.Register.data_country,
            city: state.Register.data_cities
        }
    })
    const handleChooser = (item, typeFunction) => {
        if (typeFunction === 'lang') {
            localStorage.setItem('language', item.type)
        }
        else {
            localStorage.setItem(typeFunction, JSON.stringify(typeFunction === 'country' ? { name: item.name, id: item.id } : typeFunction === 'city' && { name: item.name, id: item.id, country_id: item.country_id }))
            if (typeFunction === 'country') {
                setCountryId(item.id)
                if (item.id !== localCity?.country_id) localStorage.removeItem('city')
            }
        }
        onHide(false)
        window.location.reload()
    }
    return (

        <Modal
            show={false}
            onHide={() => onHide(false)}
            size="sm"
            // aria-labelledby="contained-modal-title-vcenter"
            centered
            scrollable

        >
            <Modal.Body>
                <div style={{ overflowY: 'scroll', height: 500, paddingRight: 10 }}>
                    {type === 'lang' &&
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <h4>Choose Language</h4>
                            <br />
                            {listLanguage.map((item, index) => {
                                return (
                                    <>
                                        {/* <span style={{ backgroundColor:'orange', padding:10, marginTop:5, marginBottom:5  }}>{item.name}</span> */}
                                        <span key={index} className={localLanguage === item.type ? "card-language-choosed" : "card-language"} onClick={() => handleChooser(item, type)}>{item.name}</span>
                                    </>

                                )
                            })}
                        </div>
                    }
                    {type === 'country' &&
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <h4>Choose Country</h4>
                            <br />
                            {country?.result?.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
                                .map((item, index) => {
                                    return (
                                        <>
                                            <span key={index} className={localCountry?.id === item.id ? "card-language-choosed" : "card-language"} onClick={() => handleChooser(item, type)}>{item.name}</span>
                                        </>
                                    )
                                })}
                        </div>
                    }
                    {type === 'city' &&
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <h4>Choose City</h4>
                            <br />
                            {city?.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
                                .map((item, index) => {
                                    return (
                                        <>
                                            <span key={index} className={localCity?.id === item.id ? "card-language-choosed" : "card-language"} onClick={() => handleChooser(item, type)}>{item.name}</span>
                                        </>
                                    )
                                })}
                        </div>
                    }
                </div>
                {/* <div style={{ display: 'grid', gridTemplateColumns: '50% 50%', marginTop: 60 }}>
                        <span className="card-country-selector-1">Indonesia</span>
                        <span className="card-country-selector-1">Malaysia</span>
                        <span className="card-country-selector-1">Thailand</span>
                        <span className="card-country-selector-1">Philipines</span>

                    </div> */}

            </Modal.Body>

        </Modal>
    )
}