import { React } from 'react'
import { Container, Card, Button, Row, Col } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive'
import ContactUsComponent from '../component/home/contactUs';

import { TopNavHome, TopNavMain, Footer } from '../index'
import './style.css'

const ContactUs = () => {
    const mobile = useMediaQuery({
        query: '(max-width:1235px)'
    });
    
    const smallMobile = useMediaQuery({
        query: '(max-width:743px)'
    });

    return (
        <div className='main'>
            <TopNavMain />
            <ContactUsComponent mobile={mobile} smallMobile={smallMobile}/>
            <Footer />
        </div>
    )
}

export default ContactUs