import React from "react";
import { t } from "../../../misc/utils";

// Component
import { Button } from "@mui/material";
import { Language } from "@mui/icons-material";
import { useMediaQuery } from "react-responsive";
import { LanguageComponent } from "../../";

// Assets
import BG from "../../../assets/image/bg-d_1.svg";
import SPLASH from "../../../assets/image/splash_1.svg";

const CompleteAdd = () => {
  const mobile = useMediaQuery({
    query: "(max-width:990px)",
  });
  const translate = localStorage.getItem("language");

  return (
    <div>
      <div
        style={{
          float: "left",
          width: mobile ? "100%" : "50%",
          margin: "auto",
          padding: 30,
        }}
      >
        <div
          style={{
            color: "#000000",
            borderColor: "#000000",
            borderRadius: 20,
            fontSize: 20,
            float: "right",
            padding: 8,
            width: 200,
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Language style={{ margin: 10 }} />
          <LanguageComponent value={translate} />
        </div>
        <div style={{ marginTop: 100, textAlign: "center" }}>
          <h1
            style={{
              fontSize: 25,
              fontWeight: 500,
              maxWidth: 397,
              margin: "auto",
            }}
          >
            {t.webView.complete_add_title}
          </h1>
          <h1 style={{ fontSize: 22, fontWeight: 400, marginTop: 50 }}>
            {t.webView.complete_add_description}
          </h1>
          <div
            style={{ display: "flex", flexDirection: "column", width: "100%" }}
          >
            <Button
              onClick={() => window.history.back()}
              style={{
                fontWeight: 500,
                width: 160,
                fontSize: 16,
                margin: "auto",
                borderRadius: 30,
                height: 60,
                marginTop: 30,
                marginBottom: 70,
                backgroundColor: "#D02C48",
                textTransform: "none",
              }}
              variant="contained"
            >
              {t.merchant.btn.add_more}
            </Button>
          </div>
        </div>
      </div>
      {mobile ? (
        <></>
      ) : (
        <div
          style={{
            float: "right",
            width: "50%",
            height: "100vh",
            backgroundImage: "url(" + BG + ")",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            position: "fixed",
            right: 0,
          }}
        >
          <img
            alt=""
            src={SPLASH}
            style={{ right: 0, bottom: 0, position: "fixed" }}
          />
        </div>
      )}
    </div>
  );
};

export default CompleteAdd;

const styles = {
  formField: {
    marginTop: 10,
    // marginLeft: 10,
    marginBottom: 10,
    width: "100%",
    borderRadius: 60,
  },
  button_container: {
    margin: "auto",
  },
};
