import { FormProduct, ErrorFetchModalProduct, ErrorImageModalProduct } from "../../";
import "./product.css";
import vector from "../../../assets/image/ImageChooseProduct.png";
import { useEffect, useState } from "react";
import heic2any from "heic2any";
import { useDispatch, useSelector } from "react-redux";
import { createItem, editItem, fetchItem_Categories, RemoveErrProduct } from "../../../application/action";
import { Button, Modal } from "react-bootstrap";
import { dataURLtoFile, toUpper } from "../../../misc/utils";
import ReactLoading from "react-loading";
import { t } from '../../../misc/utils'
import { En, Es, Id, Ms, Ph, Pt, Th, Zh } from "../../../application/language";
import { getCroppedImg } from "../../../misc/imgutils";
import { useCallback } from "react";

import LoadingComponent from '../../../component/Loading/LoadingComponent.jsx';
import LoadingError from '../../../component/Loading/LoadingError.jsx';

const ProductEdit = (props) => {
    const dispatch = useDispatch();
    const token = new URLSearchParams(props.location.search).get("token");
    const name = new URLSearchParams(props.location.search).get('name')
    const desc = new URLSearchParams(props.location.search).get('desc')
    const discount = new URLSearchParams(props.location.search).get('discount')
    const discount_amount = new URLSearchParams(props.location.search).get('discount_amount')
    const is_rec = new URLSearchParams(props.location.search).get('is_rec')
    const price = new URLSearchParams(props.location.search).get('price')
    const image = new URLSearchParams(props.location.search).get('image')
    const is_empty = new URLSearchParams(props.location.search).get('is_empty')
    const item_categories_id = new URLSearchParams(props.location.search).get('category_id')
    const itemID = new URLSearchParams(props.location.search).get('id')
    const lang = new URLSearchParams(props.location.search).get("lang");
    const variation = new URLSearchParams(props.location.search).get('variation')
    const t_props = lang === 'es' ? Es : lang === 'id' ? Id : lang === 'ms' ? Ms : lang === 'ph' ? Ph : lang === 'pt' ? Pt : lang === 'th' ? Th : lang === 'zh' ? Zh : En


    const [valAuto, setValAuto] = useState(null);
    const [ImagePreview, setImagePreview] = useState(image);
    const [cropper, setCropper] = useState();
    const [loadHeic, setLoadHeic] = useState(false);
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [rotation, setRotation] = useState(0)
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    const [croppedImage, setCroppedImage] = useState(null)

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])

    const [stringPrice, setStringPrice] = useState(price ? price?.toString().replace(/\d{1,3}(?=(\d{3})+(?!\d))/g, "$&,") : '0');
    const [stringDisc, setStringDisc] = useState(discount_amount ? discount_amount?.toString().replace(/\d{1,3}(?=(\d{3})+(?!\d))/g, "$&,") : '0');
    const [focusPrice, setFocusPrice] = useState(false);
    const [focusDisc, setFocusDisc] = useState(false);

    const [error, setError] = useState(false);
    const [errImage, setErrImage] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingError, setLoadingError] = useState(false);    

    const [item, setItem] = useState({
        name: name,
        desc: desc,
        discount_amount: discount_amount,
        price: price,
        discount: parseInt(discount) === 1 ? true : false,
        is_rec: parseInt(is_rec) === 1 ? true : false,
        is_empty: parseInt(is_empty) === 1 ? true : false,
        item_categories_id: item_categories_id,
    });

    const { category, FetchErr } = useSelector((state) => {
        return {
            category: state.Product.category,
            FetchErr: state.Product.errors_message,
        };
    });


    useEffect(() => {
        if (category.length === 0) {
            dispatch(fetchItem_Categories(token));
        }
        if (category.length > 0) {
            setValAuto(category[category.findIndex((item) => item.id === parseInt(item_categories_id))])
        }
    }, [category]);

    const handleSubmit = async () => {
        try {
            if (!item.name || !item.desc || !item.price || !item.item_categories_id || (item.discount && !item.discount_amount) || (ImagePreview === vector)) {
                return await setError(true)
            }
            else {
                setError(false)
                await setLoading(true);
                if (image !== ImagePreview) {
                    const croppedImage = await getCroppedImg(
                        ImagePreview,
                        croppedAreaPixels,
                        rotation
                    )
                    if (!croppedImage) {
                        await setLoading(false);
                        await setErrImage(true);
                        // #Note kalau crop gambar gagal
                    }
                    else {
                        const file_image_crop = await dataURLtoFile(croppedImage, Date.now());
                        const data = {
                            ...item,
                            name: toUpper(item.name),
                            image: file_image_crop,
                            discount_amount: item.discount ? parseInt(item.discount_amount) : 0,
                            price: parseInt(item.price),
                            discount: item.discount ? 1 : 0,
                            is_rec: item.is_rec ? 1 : 0,
                            is_empty: item.is_empty ? 1 : 0 // Available to order 1 | Not Available to order 0
                        };
                        const res = await editItem(token, data, itemID);
                        if (res) {
                            await setLoading(false);
                            await window.open('/complete_edit', '_self')
                        } else {
                            setLoading(false);
                        }
                    }
                }
                else {
                    const data = {
                        ...item,
                        name: toUpper(item.name),
                        discount_amount: item.discount ? parseInt(item.discount_amount) : 0,
                        price: parseInt(item.price),
                        discount: item.discount ? 1 : 0,
                        is_rec: item.is_rec ? 1 : 0,
                        is_empty: item.is_empty ? 1 : 0 // Available to order 1 | Not Available to order 0
                    };
                    const res = await editItem(token, data, itemID);
                    if (res) {
                        await setLoading(false);
                        await window.open('/complete_edit', '_self')
                    } else {
                        setLoading(false);
                    }
                }
            }
        }
        catch (err) {

        }
    };

    const FormAction = {
        valAuto,
        loadHeic,
        setCropper,
        ImagePreview,
        handleItemForm: (key, value) => {
            if (key != "image_preview") {
                setItem((prevState) => ({
                    ...prevState,
                    [key]: value,
                }));
            } else setImagePreview(value);
        },
        handleChooseImage: (e) => {
            let imageName = e.target.value;
            let extImage = imageName.substr(imageName.lastIndexOf(".") + 1);
            let img = e.target.files[0];
            if (img) {
                if (img.size <= 5000000) {
                    setImagePreview(URL.createObjectURL(img));
                    // setImage(img);
                    if (extImage.toLowerCase() === "heic") {
                        setLoadHeic(true);
                        heic2any({ blob: img, toType: "image/jpg", quality: 1 }).then(
                            (newImage) => {
                                //   setImage(newImage);
                                setImagePreview(URL.createObjectURL(newImage));
                                setLoadHeic(false);
                            }
                        );
                    }
                } else {
                    alert(t.webView.imageUploadError);
                }
            } else setImagePreview(image);

        },
        onKeyUp: (e) => {
            if (e.keyCode === 13) {
                e.target.blur();
            }
        },
        handleVariationAttached: () => {
            window.open(`/variation?id=${itemID}&token=${token}`, '_self')
        }
    };

    const NumberAction = {
        focus: {
            price: focusPrice,
            disc: focusDisc,
        },
        string: {
            price: stringPrice,
            disc: stringDisc,
        },
        onBlur: (e, type) => {
            if (type === "price") {
                setStringPrice(
                    e.target.value.toString().replace(/\d{1,3}(?=(\d{3})+(?!\d))/g, "$&,")
                );
                setFocusPrice(false);
            }
            if (type === "disc") {
                setStringDisc(
                    e.target.value.toString().replace(/\d{1,3}(?=(\d{3})+(?!\d))/g, "$&,")
                );
                setFocusDisc(false);
            }
        },
        onFocus: (e, type) => {
            if (type === "price") setFocusPrice(true);
            if (type === "disc") setFocusDisc(true);
            if (stringPrice || stringDisc) {
                // e.target.select()
                if (type === "price") setStringPrice();
                if (type === "disc") setStringDisc();
            }
        },
    };

    return (
        <div className="container-product">
            <div className="container-product-content">
                <FormProduct
                    Action={{ FormAction, NumberAction, handleSubmit }}
                    category={category}
                    item={item}
                    error={error}
                    loading={loading}
                    valAuto={valAuto}
                    setValAuto={setValAuto}
                    defaultImage={image}
                    type='edit'
                    t={lang ? t_props : t}
                    cropImage={{ zoom, crop, rotation, setCrop, setRotation, onCropComplete, setZoom }}
                    variationAttached={variation}
                />
                <ErrorFetchModalProduct dispatch={dispatch} FetchErr={FetchErr} t={lang ? t_props : t} />
                <ErrorImageModalProduct errImage={errImage} setErrImage={setErrImage} t={lang ? t_props : t} />
                {loading ? (<LoadingComponent />):(<></>)}
                {loadingError ? (<LoadingError setLoadingError={setLoadingError} />) : (<></>) }
            </div>
        </div>
    );
};

export default ProductEdit;
