import { Container, Card, Row, Col } from 'react-bootstrap';
import { t } from '../../../../misc/utils'

const AcceptOrderComponent = () => {
    return (
        <div className='tutorial'>
            <div className='container-default'>
                <p className='label'>{t.addgoods.textTitle}</p>
                <Row>
                    <Col xs={12}>
                        <iframe src="https://www.youtube.com/embed/1TXawP9Pns0"></iframe>
                    </Col>
                </Row>
            </div>
        </div>

    )
}

export default AcceptOrderComponent