const En = {
  languageModal: {
    textTitle: "Por favor, selecione o seu idioma e país",
    textChooseCity: "Selecione uma cidade",
    search: "Busca",
  },
  navbar: {
    textItem1: "TUTORIAIS",
    textItem2: "CONTATOS",
    textButton: "Registrar agora",
    textRegis: "Registro",
    textRegistration: "Como adicionar seus produtos",
    textProcess: "Como processar pedidos",
    textOrders: "(Para clientes) Como fazer pedidos",
    textComplete: "Como completar pedidos",
    textSm: "SIGA-NOS NAS REDES SOCIAIS",
    textSm1: "Receba dias e comendações sobre como usar o app e o serviço",
  },
  footer: {
    text: "2022 Maxim - todos os direitos reservados",
    textPrivacy: "Política de Privacidade",
  },
  qna: {
    textTitle: "Perguntas Frequentes",
    textContent1: "Posso registrar mais de um vendedor?",
    textContent2: "Qual é a taxa de comissão?",
    textContent3: "Quem paga ao vendedor?",
    textContent4: "Como eu abro a minha loja no app?",
    textContent5: "Como eu deleto meus itens no app?",
    textContent6: "Como eu mudo a sequência de categorias?",
    textSubcontent1:
      "Você pode, mas é preferível usar um e-mail diferente para cada loja que você registra.",
    textSubcontent2:
      "Atualmente, você pode ganhar dinheiro com nosso Serviço livre de comissão. Você pode especificar o preço dos pratos como em um menu.",
    textSubcontent3:
      "O motorista paga pelo pedido com o próprio dinheiro, e então recebe o pagamento do cliente.",
    textSubcontent4:
      "Clique em Abrir se você já recebeu pedidos e em Fechar quando terminar o trabalho.",
    textSubcontent5:
      "Você não pode fazer isso agora. Apenas desative o seu item ou substitua-o por algum outro. ",
    textSubcontent6:
      "Categorias são organizadas da mais antiga para as mais novas, e você não pode mudar a sua sequência manualmente.",
  },
  guides: {
    register: {
      textStep1:
        "Complete as suas informações: insira uma senha que você usará para fazer login, assim como o seu contato telefônico.",
      textStep2: "Especifique o endereço do seu café ou ponto de envio.",
      textStep3:
        "Faça upload do logotipo da sua companhia ou uma imagem da sua loja.",
      textStep4:
        "Pronto! Agora você pode prosseguir para o carregamento dos seus produtos.",
    },
    addProduct: {
      textStep1:
        "Para começar, crie uma categoria de produtos, por exemplo, Salgadinhos. Você pode adicionar quantas categorias precisar.",
      textStep2:
        'Clique em "+" próximo ao nome da categoria para adicionar os seus produtos. ',
      textStep3_1:
        "Insira as informações sobre o seu produto, especifique um desconto, se você deseja oferecer algum. Um desconto e uma foto de alta qualidade farão o seu produto se destacar.",
      textStep3_2:
        "Se você marcar seu produto como Recomendado, ele será mostrado entre os primeiros da sua loja.",
    },
    processOrder: {
      textStep1:
        "Clique em Abrir para começar a trabalhar. Quando houver um novo pedido, você receberá uma notificação.",
      textStep2_1:
        'Clique em "Confirmar pedido" para começar a trabalhar, ou "Cancelar pedido" se, por exemplo, seu produto está fora de estoque no momento.',
      textStep2_2:
        "Se você não confirmar um pedido dentro de três minutos, ele será cancelado automaticamente.",
      textStep3:
        "Nós recomendamos que você espere o entegador antes de começar a preparar o pedido.",
    },
    placeOrder: {
      textStep1:
        'O cliente seleciona a taxa Foods&Goods e clica em "O que comprar"',
      textStep2:
        "Eles podem selecionar as lojas da lista ou buscar por um produto específico.",
      textStep3:
        "Os produtos que você marcar como Recomendados aparecem primeiro.",
      textStep4:
        "O cliente pode especificar a quantidade dos produtos e o preço será calculado automaticamente.",
      textStep5:
        "O app tem todos os recuros para a conveniência do cliente, como fazer pedidos para uma pessoa diferente para comodidade do tempo de entreg.",
      textStep6:
        "O cliente pode rastrear o entegador com o seu pedido no mapa online.",
    },
  },
  home: {
    card1: {
      textTitle: "Venda seus produtos com a Maxim",
      textContent:
        "Obtenha acesso a uma audência ativa e pagadora com nenhum custo extra.",
      textButton: "Registre-se agora",
    },
    card2: {
      textTitle: "Consiga mais clientes",
      textContent1:
        "Publique seus produtos para milhares de usuários do aplicativo Maxim verem.",
      textContent2: "Veja todas as vantagens de colaborar conosco.",
      textContentTitle1: "Livre de taxa",
      textContentTitle2: "Entrega rápida",
      textContentTitle3: "Publicidade gratuita",
      textSubcontent1:
        "Nós não cobramos uma porcentagem pelas vendas. Receba toda a sua renda da venda dos produtos como um menu para atrair consumidores.",
      textSubcontent2:
        "Seu pedido será entregue pelo entregador mais próximo do ponto de envio. A entrega é paga pelo cliente. ",
      textSubcontent3:
        "Vamos fazer um banner conjunto para a sua empresa e postar informações em nossos aplicativos para motoristas e clientes.",
    },
    card3: {
      textTitle: "Faça pedidos em um app útil",
      textContent1:
        "Adicione quantas lojas para uma única marca que você precisar. ",
      textContent2:
        "Crie qualquer categoria que você quiser. Por exemplo, bebidas, sopas, segundos cursos, saladas, etc. ",
      textContent3:
        "Carregue fotos atraentes para se destacar de outras empresas. ",
      textContent4: "Defina um desconto para atrair mais clientes.",
      textContent5:
        "Analise as estatísticas de compra para oferecer itens mais populares.",
    },
    card4: {
      textTitle: "Comece agora!",
      textContent1: "Registrar-se",
      textContent2: "Baixe o aplicativo",
      textContent3: "Adicione seus produtos",
      textContent4: "Aguarde os seus primeiros clientes!",
      textButton: "Registrar agora",
    },
    card5: {
      textTitle: "Anúncios grátis offline",
      textContent1:
        "Faça sua empresa se destacar. Nós vamos projetar um layout e criar um banner conjunto para você, gratuitamente. Isso vai ajudá-lo a atrair clientes e contar sobre seu serviço de encomenda on-line. ",
      textContent2:
        "Comprar um banner está disponível para vendedores registrados. Entre em contato com um dos nossos escritórios.",
      textButton: "Ver endereços dos escritórios",
    },
    card6: {
      textTitle: "Guias detalhadas",
      textContent1: "Saiba como usar o nosso serviço.",
      textSubcontent1: "Como se cadastrar",
      textSubcontent2: "Como adicionar produtos",
      textSubcontent3: "Como processar pedidos",
      textSubcontent4: "(Para clientes) Como fazer pedidos",
      textSubcontent5: "(Para entregadores) Como entregar pedidos",
    },
  },
  contact: {
    card1: {
      textTitle: "Escritório",
      textContent:
        "Jl. KH. Noer Ali No. 192, Kayuringin Jaya, Kec. Bekasi Sel., Kota Bks, West Java 17144",
      textPhone: "+ 6221-5092-8720",
    },
  },
  addgoods: {
    card1: {
      textTitle: "Como adicionar seus produtos no aplicativo MaximMerchant",
      textContent1:
        'Na aba ITEM, selecione CATEGORIA, clique em "+" e crie uma nova categoria.',
      textContent2: "Crie quantas categorias precisar.",
      textContent3: 'Na aba ITEM, selecione MENU e clique em "+".',
      textContent4_0:
        "Preencha todos os campos. Se quiser que seus produtos sejam exibidos na página inicial do vendedor, ative a recomendação. Na descrição, você pode indicar o nome do item.",
      textContent4_1:
        "Ative a opção para ativar o item. Agora, os clientes vão vê-lo em seus aplicativos. Atualmente, você não pode excluir seus itens, mas você pode desativá-los ou substituí-los por outra coisa.",
      textContent4_2:
        "Toque em ABRIR quando estiver pronto para começar a fazer pedidos. Depois que terminar o trabalho, certifique-se de clicar em FECHAR.",
    },
    card2: {
      textTitle: "Pronto para começar?",
      textContent: "Cadastre-se e comece a promover o seu negócio conosco!",
      textButton: "Registro",
    },
  },
  merchant: {
    product: {
      labelShowCustomer: "Mostrar aos clientes",
      labelName: "Nome do Produto",
      labelDesc: "Descrição",
      labelCat: "Categoria",
      labelPrice: "Preço",
      labelDisc: "Desconto",
      labelDiscAmount: "Quantidade de desconto",
      labelRecommend: "Recomendado",
      labelChoosePhoto: "Selecione uma foto",
      labelSaveAdd: "Salve o produto",
      labelSaveEdit: "Salve as alterações",
      labelRequired: "Obrigatório",
      labelNotRequired: "Não é necessário",
      LabelCancelEdit: "Cancelar",
      LabelVariationAttach: "Variação vinculada",
    },
    btn: {
      pic: "Selecione uma foto",
      submit: "Enviar",
      close: "Fechar",
      add_more: "Adicionar mais",
      schedule: "Adicionar nova agenda",
      edit: "Editar",
      delete: "Deletar",
      variationAttach:
        "Clique aqui para visualizar a variação anexada a este item",
    },
    schedule: {
      text: {
        title: "Horário de trabalho",
        add: { title: "Adicionar agenda" },
        edit: { title: "Editar agenda" },
        delete: {
          title: "Deletar agenda",
          alert: "Tem certeza de que deseja deletar os dados?",
        },
      },
      day: {
        sunday: "Domingo",
        monday: "Segunda-feira",
        tuesday: "Terça-feira",
        wednesday: "Quarta-feira",
        thursday: "Quinta-feira",
        friday: "Sexta-feira",
        saturday: "Sábado",
      },
      form: {
        day: "Dia",
        open: "Horário aberto",
        close: "Horário Fechado",
      },
    },
    err: {
      pic_fail: `Não é possível carregar imagem. Por favor, use uma foto diferente`,
      empty: `O formulário não pode ficar vazio`,
      no_pic: "Selecione uma foto para carregar",
      get: "Erro na obtenção dos dados",
      no_data: "Sem dados",
      add: "Erro ao adicionar dados",
      edit: "Erro ao editar dados",
      delete: "Erro ao deletar dados",
      categoryFetch:
        "Falha ao obter a categoria, por favor tente fazer login novamente",
      variationFetch:
        "Falha ao obter a variação, por favor, tente fazer login novamente",
      productFetch: "Falha ao obter o produto, tente fazer login novamente",
      connectedFetch:
        "Não foi possível obter a variação anexada. Por favor, tente fazer login novamente",
      processImage:
        "Falha ao processar a imagem. Por favor, tente novamente ou tente carregar uma imagem diferente",
    },
  },
  register: {
    err: {
      title: "Erro!",
      no_pic: "Selecione uma foto para carregar",
      sent: "Erro ao enviar dados para o servidor",
      email: "E-mail já cadastrado",
      empty: `O formulário não pode estar vazio`,
      no_loc:
        "Escolha a sua localização no mapa (clique no mapa duas vezes para obter a sua localização ou mova o ponteiro para a sua localização)",
      required: "Campo obrigatório",
      email_not_valid: "E-mail inválido",
      pass_not_strong:
        "A senha não é forte o bastante. Use pelo menos 7 caracteres e tente combinar letras maiúsculas, minúsculas, números e caracteres especiais (!@#$%^&*_+=-)",
      pass_strong: "Senha forte",
      pass_perfect: "Senha perfeita",
    },
    btn: {
      close: "Fechar",
      continue: "Continuar",
      back: "Passo anterior",
      next: "Próximo passo",
      register: "Registrar-se",
      photo: "Selecione uma foto",
    },
    text: {
      step: {
        first: "Passo 1 de 3",
        second: "Passo 2 de 3",
        third: "Passo 3 de 3",
        done: "Pronto",
      },
      title: {
        merchant_info: "Informações sobre o vendedor",
        merchant_location: "Localização da loja",
        merchant_picture: "Imagem da Loja",
      },
      note: {
        registe_image:
          "Você pode carregar seu logotipo ou a imagem do seu produto principal.",
        agreement: "Ao clicar em registrar-se, eu concordo com",
        tnc: "Termos e condições",
        done: "Cadastro bem sucedido. Para começar a trabalhar, você deve esperar até confirmarmos a sua conta, que normalmente leva alguns dias. Vamos ligar para você ou enviar um e-mail.",
        doneAndDownload:
          "Enquanto isso, você pode se preparar para vender suas mercadorias baixando o nosso aplicativo e adicionando-as lá.",
      },
    },
    form: {
      phone: "Telefone",
      name: "Nome da loja",
      email: "E-mail",
      desc: "Descrição",
      password: "Senha",
      referral: "Indicação (se você tiver)",
      country: "País",
      city: "Cidade",
      district: "Distrito",
      address: "Endereço",
    },
    question: {
      any: "Alguma pergunta?",
      check: "Confira os nossos tutoriais.",
      button: "Ver tutoriais",
    },
  },
  webView: {
    complete_add_title: "ADICIONAR PRODUTO COM SUCESSO",
    complete_add_description:
      "Produto adicionado. Você pode adicionar mais ou pode fechar esta página.",
    complete_edit_title: "EDITE O PRODUTO COM SUCESSO",
    complete_edit_description: "Produto editado. Você pode fechar esta página.",
    imageUploadError: "O arquivo de imagem é muito grande! Máximo de 5 MB.",
  },
};
export default En;
