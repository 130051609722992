import { Container, Card, Row, Col } from "react-bootstrap"
import { t } from '../../../../misc/utils'
import step1 from '../../../../assets/image/order-step1.svg'
import step2 from '../../../../assets/image/order-step2.svg'
import step3 from '../../../../assets/image/order-step3.svg'
import step4 from '../../../../assets/image/order-step4.svg'
import step5 from '../../../../assets/image/order-step5.svg'
import step6 from '../../../../assets/image/order-step6.svg'

const OrderGuideComponent = ({ mobile }) => {
    return (
        <div className="tutorial">
            <div className="container-default">
                {mobile ? <b>{t.addgoods.textTitle}</b> : <p className='label'>{t.addgoods.textTitle}</p>}
                <Row>
                    <Col xs={mobile ? 12 : 6} className={mobile ? 'img-center' : 'img-center padding-vertical70' }>
                        <img src={step1} height={mobile ? 160 : "100%"}></img>
                    </Col>
                    <Col xs={mobile ? 12 : 6} className={mobile ? 'img-center padding-vertical25' : 'img-center padding-vertical70'}>
                        <ul>
                            <li>
                                <p>{t.guides.placeOrder.textStep1}</p>
                            </li>                            
                        </ul>
                    </Col>
                    <Col xs={mobile ? 12 : 6} className={mobile ? 'img-center' : 'img-center padding-vertical70' }>
                        <img src={step2} height={mobile ? 160 : "100%"}></img>
                    </Col>
                    <Col xs={mobile ? 12 : 6} className={mobile ? 'img-center padding-vertical25' : 'img-center padding-vertical70'}>
                        <ul>
                            <li>
                                <p>{t.guides.placeOrder.textStep2}</p>
                            </li>                                                                              
                        </ul>
                    </Col>
                    <Col xs={mobile ? 12 : 6} className={mobile ? 'img-center' : 'img-center padding-vertical70' }>
                        <img src={step3} height={mobile ? 160 : "100%"}></img>
                    </Col>
                    <Col xs={mobile ? 12 : 6} className={mobile ? 'img-center padding-vertical25' : 'img-center padding-vertical70'}>
                        <ul>
                            <li>
                                <p>{t.guides.placeOrder.textStep3}</p>
                            </li>                                                                             
                        </ul>
                    </Col>
                    <Col xs={mobile ? 12 : 6} className={mobile ? 'img-center' : 'img-center padding-vertical70' }>
                        <img src={step4} height={mobile ? 160 : "100%"}></img>
                    </Col>
                    <Col xs={mobile ? 12 : 6} className={mobile ? 'img-center padding-vertical25' : 'img-center padding-vertical70'}>
                        <ul>
                            <li>
                                <p>{t.guides.placeOrder.textStep4}</p>
                            </li>                                                                             
                        </ul>
                    </Col>
                    <Col xs={mobile ? 12 : 6} className={mobile ? 'img-center' : 'img-center padding-vertical70' }>
                        <img src={step5} height={mobile ? 160 : "100%"}></img>
                    </Col>
                    <Col xs={mobile ? 12 : 6} className={mobile ? 'img-center padding-vertical25' : 'img-center padding-vertical70'}>
                        <ul>
                            <li>
                                <p>{t.guides.placeOrder.textStep5}</p>
                            </li>                                                                             
                        </ul>
                    </Col>
                    <Col xs={mobile ? 12 : 6} className={mobile ? 'img-center' : 'img-center padding-vertical70' }>
                        <img src={step6} height={mobile ? 160 : "100%"}></img>
                    </Col>
                    <Col xs={mobile ? 12 : 6} className={mobile ? 'img-center padding-vertical25' : 'img-center padding-vertical70'}>
                        <ul>
                            <li>
                                <p>{t.guides.placeOrder.textStep6}</p>
                            </li>                                                                             
                        </ul>
                    </Col>                    
                </Row>
            </div>
        </div>
    )
}

export default OrderGuideComponent