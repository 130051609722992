import React from 'react'
import { Navbar, Container, Nav, NavDropdown, Modal } from "react-bootstrap"
import { useMediaQuery } from "react-responsive"
import logomaxim from '../../../assets/image/logo.png'
import { ModalChooser, ModalInitial } from "../chooser/modalInitial"
import { TopNavLanguage } from '../../index'
import { t } from '../../../misc/utils'
import { useEffect, useState } from "react"
import instagram from '../../../assets/component/instagram.png'
import './style.css'
import CroosIcon from '../../../assets/component/icon_cross.svg'

const TopNavHome = ({
    register,
    handleModalChooser,
    country,
    city,
    lang,
    initialModal,
    setInitialModal,
    modalType,
    setModalType,
    modalChooser,
    setModalChooser,
}) => {
    const [hide, setHide] = useState(false);
    const [langModal, setLangModal] = React.useState(false)
    const listLanguage = [
        { id: 1, type: 'en', name: 'English' },
        { id: 1, type: 'es', name: 'Español' },
        { id: 1, type: 'id', name: 'Bahasa Indonesia' },
        { id: 1, type: 'ms', name: 'Bahasa Melayu' },
        { id: 1, type: 'ph', name: 'Filipino' },
        { id: 1, type: 'pt', name: 'Português (Brasileiro)' },
        { id: 1, type: 'th', name: 'ไทย' },
        { id: 1, type: 'zh', name: '中文' },
    ]

    const smallDesktop = useMediaQuery({
        query: '(max-width:1235px)'
    })

    const mobile = useMediaQuery({
        query: '(max-width:743px)'
    })

    useEffect(() => {
        if ((hide && mobile) || (hide && smallDesktop)) {
            document.body.style.overflow = "hidden"
        } else {
            document.body.style.overflow = "scroll"
        }
    }, [hide]);


    const handleChooserLanguage = (item) => {
        if (lang === item.type) {
            setLangModal(false)
        }
        else {
            localStorage.setItem('language', item.type)
            window.location.reload()
        }
    }
    return (
        <>
            {mobile || smallDesktop ?
                !hide ?
                    <Navbar bg="" expand="lg" className="container-default-navbar">
                        <Nav.Link href="/"><img className="logo-maxim" alt='' src={logomaxim} /></Nav.Link>
                        <div className="ms-auto">
                            <button className="btn" onClick={() => setHide(!hide)}><i class="fa-solid fa-bars"></i></button>
                        </div>
                    </Navbar>
                    :
                    <></>
                :
                <Navbar bg="" expand="lg" className="container-default-navbar">
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            <Nav.Link href="/" className="logo-maxim"><img alt='' src={logomaxim} /></Nav.Link>
                            {!register &&
                                <>
                                    <Nav.Link onClick={() => setHide(!hide)} >{t.navbar.textItem1}  {hide ? <i class="fa-solid fa-chevron-up"></i> : <i class="fa-solid fa-angle-down"></i>}</Nav.Link>
                                    <div style={mobile ? {} : { marginTop: 10, display: 'flex' }}>
                                        <Nav.Link href="/contact-us" align="right">{t.navbar.textItem2}</Nav.Link>
                                    </div>
                                </>
                            }
                        </Nav>
                        {!register &&
                            <Nav className="ms-auto">
                                <Nav.Link href="/register"><a className='btn btn-regisnow'>{t.navbar.textButton}</a></Nav.Link>
                            </Nav>
                        }
                    </Navbar.Collapse>
                </Navbar>
            }

            {/* dropdown navbar */}
            {
                hide && mobile ?
                    <div className="fake-dropdown background-pink-absolute">
                        <div className="container-default">
                            <div className="content">
                                <div className="row">
                                    <div className="col-6">
                                        <p><a href="/"><img className="logo-maxim" alt='' src={logomaxim} /></a></p>
                                    </div>
                                    <div className="col-6">
                                        <p align='right'><a align='right' onClick={() => setHide(!hide)}><i class="fa-solid fa-xmark"></i></a></p>
                                    </div>
                                    <div className="fake-dropdown-content">
                                        <div className="col-xs-6">
                                            <p className="fake-dropdown-content-title">{t.navbar.textItem1}</p>
                                            <p><a href='/guides/registration'>{t.navbar.textRegis}</a></p>
                                            <p><a href='/guides/addingproduct'>{t.navbar.textRegistration}</a></p>
                                            <p><a href='/guides/processproduct'>{t.navbar.textProcess}</a></p>
                                            <p><a href='/guides/makeorder'>{t.navbar.textOrders}</a></p>
                                            <p><a href='/guides/acceptorder'>{t.navbar.textComplete}</a></p>
                                            <div className="fake-dropdown-contact">
                                                <p className="fake-dropdown-content-title"><a href='/contact-us'>{t.navbar.textItem2}</a></p>
                                                {!register &&
                                                    <p><a href="/register" className='btn btn-regisnow'>{t.navbar.textButton}</a></p>
                                                }
                                            </div>
                                        </div>
                                        <div className="col-xs-6">
                                            <p onClick={() => setLangModal(prev => !prev)}>{lang?.toUpperCase()}</p>

                                            <div className="row">
                                                <div className="col-6" onClick={() => handleModalChooser('country')}>
                                                    <p>{country ? country.name : 'Select Country'}</p>
                                                </div>
                                                <div className="col-6" onClick={() => handleModalChooser('city')}>
                                                    <p>{city ? city.name : 'Select City'}</p>
                                                </div>
                                            </div>
                                            <p><a src={instagram} href='https://www.instagram.com/maximmerchant_id/' target='_blank'><img className="instagram" src={instagram} alt='' /></a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* modalnya */}
                        <div className="modal-initial-container">
                            <div className={`transition-modal-initial-city-${langModal ? 'mobile-open' : 'close'}`}>
                                <div className='modal-initial-container-content'>
                                    <div className='modal-initial-container-content-language'>
                                        <div>
                                            <p><a href="/"><img className="logo-maxim" alt='' src={logomaxim} /></a></p>
                                        </div>
                                        <div onClick={() => {
                                            setLangModal(false)
                                        }}>
                                            <img src={CroosIcon} />
                                        </div>
                                    </div>
                                    <div>
                                        <div className='modal-list-language'>
                                            {listLanguage.map((item, index) => {
                                                return (
                                                    <>
                                                        <span key={index} className={lang === item.type ? "card-language-choosed-navbar-home-mobile" : "card-language-navbar-home-mobile"} onClick={() => handleChooserLanguage(item)}>{item.name}</span>
                                                    </>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    // not really use
                    hide && smallDesktop ?
                        <div className="fake-dropdown background-pink-absolute">
                            <div className="container-default">
                                <div className="content">
                                    <div className="row">
                                        <div className="col-6">
                                            <p><a href="/"><img className="logo-maxim" alt='' src={logomaxim} /></a></p>
                                        </div>
                                        <div className="col-6">
                                            <p align='right'><a align='right' onClick={() => setHide(!hide)}><i class="fa-solid fa-xmark"></i></a></p>
                                        </div>
                                        <div className="fake-dropdown-content">
                                            <div className='row'>
                                                <div className="col-sm-6">
                                                    <p className="fake-dropdown-content-title">{t.navbar.textItem1}</p>
                                                    <p><a href='/guides/registration'>{t.navbar.textRegis}</a></p>
                                                    <p><a href='/guides/addingproduct'>{t.navbar.textRegistration}</a></p>
                                                    <p><a href='/guides/processproduct'>{t.navbar.textProcess}</a></p>
                                                    <p><a href='/guides/makeorder'>{t.navbar.textOrders}</a></p>
                                                    <p><a href='/guides/acceptorder'>{t.navbar.textComplete}</a></p>
                                                    <div className="fake-dropdown-contact">
                                                        {!register &&
                                                            <p><a href="/register" className='btn btn-regisnow'>{t.navbar.textButton}</a></p>
                                                        }
                                                    </div>
                                                </div>
                                                <div className='col-sm-6'>
                                                    <p className="fake-dropdown-content-title"><a href='/contact-us'>{t.navbar.textItem2}</a></p>
                                                </div>
                                                <div className="col-sm-6">
                                                    <p onClick={() => setLangModal(prev => !prev)}>{lang?.toUpperCase()}</p>
                                                    <div className={`transition-dropdown-navbar-home language-${langModal && !mobile ? 'open' : 'close'}`}>
                                                        <div>
                                                            <div className='modal-list-language'>
                                                                {listLanguage.map((item, index) => {
                                                                    return (
                                                                        <>
                                                                            <span key={index} className={lang === item.type ? "card-language-choosed-navbar-home" : "card-language-navbar-home"} onClick={() => handleChooserLanguage(item)}>{item.name}</span>
                                                                        </>
                                                                    )
                                                                })}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-6" onClick={() => handleModalChooser('country')}>
                                                            <p>{country ? country.name : 'Select Country'}</p>
                                                        </div>
                                                        <div className="col-6" onClick={() => handleModalChooser('city')}>
                                                            <p>{city ? city.name : 'Select City'}</p>
                                                        </div>
                                                    </div>
                                                    <p><a src={instagram} href='https://www.instagram.com/maximmerchant_id/' target='_blank'><img className="instagram" src={instagram} alt='' /></a></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        hide && !mobile && !smallDesktop ?
                            <div className="fake-dropdown">
                                <div className="container-default-navbar">
                                    <div className="content">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <p><a href='/guides/registration'>{t.navbar.textRegis}</a></p>
                                                <p><a href='/guides/addingproduct'>{t.navbar.textRegistration}</a></p>
                                                <p><a href='/guides/processproduct'>{t.navbar.textProcess}</a></p>
                                                <p><a href='/guides/makeorder'>{t.navbar.textOrders}</a></p>
                                                <p><a href='/guides/acceptorder'>{t.navbar.textComplete}</a></p>
                                            </div>
                                            <div className="col-md-6">
                                                <p>{t.navbar.textSm}</p>
                                                <p>{t.navbar.textSm1}</p>
                                                <p><a src={instagram} href='https://www.instagram.com/maximmerchant_id/' target='_blank'><img className="instagram" src={instagram} alt='' /></a></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            ''
            }
            <ModalInitial mobile={mobile} show={initialModal} onHide={setInitialModal} countries={country} lang={lang} handleModalChosser={handleModalChooser} hide={hide} smallDesktop={smallDesktop} />
            <ModalChooser show={modalChooser} onHide={setModalChooser} type={modalType} handleModalChosser={handleModalChooser} />
            {/* {
                hide && mobile || hide && smallDesktop || hide && !mobile && !smallDesktop ?
                    <TopNavLanguage hide={hide} setUnhide={setHide} register={register} />
                    :
                    <></>

            } */}
        </>
    )
}

export default TopNavHome