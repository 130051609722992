import { FormProduct, ErrorFetchModalProduct, ErrorImageModalProduct } from "../../";
import "./product.css";
import vector from "../../../assets/image/ImageChooseProduct.png";
import { useEffect, useState, useCallback } from "react";
import heic2any from "heic2any";
import { useDispatch, useSelector } from "react-redux";
import { createItem, fetchItem_Categories, RemoveErrProduct } from "../../../application/action";
import { Button, Modal } from "react-bootstrap";
import { dataURLtoFile, toUpper } from "../../../misc/utils";
import ReactLoading from "react-loading";
import { t } from '../../../misc/utils'
import { En, Es, Id, Ms, Ph, Pt, Th, Zh } from "../../../application/language";
import { getCroppedImg } from "../../../misc/imgutils";

import LoadingComponent from '../../../component/Loading/LoadingComponent.jsx';
import LoadingError from '../../../component/Loading/LoadingError.jsx';

const ProductAdd = (props) => {
    const dispatch = useDispatch();
    const token = new URLSearchParams(props.location.search).get("token");
    const lang = new URLSearchParams(props.location.search).get("lang");
    const t_props = lang === 'es' ? Es : lang === 'id' ? Id : lang === 'ms' ? Ms : lang === 'ph' ? Ph : lang === 'pt' ? Pt : lang === 'th' ? Th : lang === 'zh' ? Zh : En

    const [valAuto, setValAuto] = useState(null);
    const [ImagePreview, setImagePreview] = useState(vector);
    const [image, setImage] = useState({});
    const [cropper, setCropper] = useState();
    const [loadHeic, setLoadHeic] = useState(false);
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [rotation, setRotation] = useState(0)
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    const [croppedImage, setCroppedImage] = useState(null)

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])

    const [stringPrice, setStringPrice] = useState();
    const [stringDisc, setStringDisc] = useState();
    const [focusPrice, setFocusPrice] = useState(false);
    const [focusDisc, setFocusDisc] = useState(false);

    const [error, setError] = useState(false);
    const [errImage, setErrImage] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingError, setLoadingError] = useState(false);

    const [item, setItem] = useState({
        name: null,
        desc: null,
        discount_amount: null,
        price: null,
        discount: false,
        is_rec: false,
        is_empty: false,
        item_categories_id: null,
    });

    const { category, FetchErr } = useSelector((state) => {
        return {
            category: state.Product.category,
            FetchErr: state.Product.errors_message,
        };
    });


    useEffect(() => {
        if (category.length === 0) {
            dispatch(fetchItem_Categories(token));
        }
        // if (category.length > 0) {
        //   setValAuto(category[category.findIndex((item) => item.id === category_id_from_param)])
        // }
    }, [category]);


    // const handleChoose = (e) => {
    //     let img = e.target.files[0];
    //     if (img.size <= 5000000) {
    //         if (img) {
    //             handleItemForm('image_preview', URL.createObjectURL(img));
    //             handleItemForm('image', img);
    //         }
    //         else {
    //             setImagePreview(ItemImg);
    //             delete item['image'];
    //         }
    //     } else {
    //         alert(t.webView.imageUploadError);
    //     }
    // }
    const handleSubmit = async () => {
        try {
            if (!item.name || !item.desc || !item.price || !item.item_categories_id || (item.discount && !item.discount_amount) || (ImagePreview === vector)) {
                return await setError(true)
            }
            else {
                setError(false)
                await setLoading(true);

                const croppedImage = await getCroppedImg(
                    ImagePreview,
                    croppedAreaPixels,
                    rotation
                )
                if (!croppedImage) {
                    await setLoading(false);
                    await setErrImage(true);
                    // #Note kalau crop gambar gagal
                }
                else {
                    const file_image_crop = await dataURLtoFile(croppedImage, Date.now());
                    const data = {
                        ...item,
                        name: toUpper(item.name),
                        image: file_image_crop,
                        discount_amount: item.discount ? parseInt(item.discount_amount) : 0,
                        price: parseInt(item.price),
                        discount: item.discount ? 1 : 0,
                        is_rec: item.is_rec ? 1 : 0,
                        is_empty: item.is_empty ? 1 : 0 // Available to order 1 | Not Available to order 0
                    };
                    const res = await createItem(token, data);
                    // const res = false;
                    if (res) {
                        await setLoading(false);
                        await window.open("/complete_add", "_self");
                    } else {
                        setLoading(false);
                        setLoadingError(true);
                    }
                }
            }
        }
        catch (err) {

        }
    };

    const FormAction = {
        valAuto,
        loadHeic,
        setCropper,
        ImagePreview,
        handleItemForm: (key, value) => {
            if (key != "image_preview") {
                setItem((prevState) => ({
                    ...prevState,
                    [key]: value,
                }));
            } else setImagePreview(value);
        },
        handleChooseImage: (e) => {
            let imageName = e.target.value;
            let extImage = imageName.substr(imageName.lastIndexOf(".") + 1);
            let img = e.target.files[0];
            if (img) {
                if (img.size <= 5000000) {
                    setImagePreview(URL.createObjectURL(img));
                    setImage(img);
                    if (extImage.toLowerCase() === "heic") {
                        setLoadHeic(true);
                        heic2any({ blob: img, toType: "image/jpg", quality: 1 }).then(
                            (newImage) => {
                                setImage(newImage);
                                setImagePreview(URL.createObjectURL(newImage));
                                setLoadHeic(false);
                            }
                        );
                    }
                } else {
                    alert(t.webView.imageUploadError);
                }
            } else setImagePreview(vector);

        },
        onKeyUp: (e) => {
            if (e.keyCode === 13) {
                e.target.blur();
            }
        },
    };

    const NumberAction = {
        focus: {
            price: focusPrice,
            disc: focusDisc,
        },
        string: {
            price: stringPrice,
            disc: stringDisc,
        },
        onBlur: (e, type) => {
            if (type === "price") {
                setStringPrice(
                    e.target.value.toString().replace(/\d{1,3}(?=(\d{3})+(?!\d))/g, "$&,")
                );
                setFocusPrice(false);
            }
            if (type === "disc") {
                setStringDisc(
                    e.target.value.toString().replace(/\d{1,3}(?=(\d{3})+(?!\d))/g, "$&,")
                );
                setFocusDisc(false);
            }
        },
        onFocus: (e, type) => {
            if (type === "price") setFocusPrice(true);
            if (type === "disc") setFocusDisc(true);
            if (stringPrice || stringDisc) {
                // e.target.select()
                if (type === "price") setStringPrice();
                if (type === "disc") setStringDisc();
            }
        },
    };

    console.log(lang, 'lang')

    return (
        <div className="container-product">
            <div className="container-product-content">
                <FormProduct
                    Action={{ FormAction, NumberAction, handleSubmit }}
                    category={category}
                    item={item}
                    error={error}
                    loading={loading}
                    type='add'
                    t={lang ? t_props : t}
                    cropImage={{ zoom, crop, rotation, setCrop, setRotation, onCropComplete, setZoom }}
                />
                <ErrorFetchModalProduct dispatch={dispatch} FetchErr={FetchErr} t={lang ? t_props : t} />
                <ErrorImageModalProduct errImage={errImage} setErrImage={setErrImage} t={lang ? t_props : t} />
                {loading ? (<LoadingComponent />):(<></>)}
                {loadingError ? (<LoadingError setLoadingError={setLoadingError} />) : (<></>) }
            </div>
        </div>
    );
};

export default ProductAdd;
