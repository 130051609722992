import { createTheme } from "@mui/material"

export const themeFormProduct = createTheme({
    components: {
        MuiTextField: {
            styleOverrides: {
               root: {
                marginTop:9,
                marginBottom:9,
               }
            }
        },
        MuiAutocomplete: {
            styleOverrides: {
                root: {
                    // marginTop:6,
                    // marginBottom:5
                }
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color:'#969696',
                    "&.Mui-focused": {
                        color: "#517c92",
                      },
                    "&.Mui-error": {
                        color: "#E31E24"
                    }
                      
                },
            }
        },
        MuiInput: {
            styleOverrides: {
                root: {
                    "&::before": {
                        borderBottom: "2px solid #ebebeb",
                    },
                    "&::after": {
                        borderBottom: "2px solid #517c92",
                      },
                    "&.Mui-error": {
                        borderColor: "#E31E24"
                    }
                }
            }
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    "&.Mui-error": {
                        color: "#E31E24"
                    }
                }
            }
        },
        MuiFormControlLabel: {
            styleOverrides: {
                root:{
                    marginLeft:0,
                    marginRight:0,
                    marginTop:11,
                    marginBottom:3
                },
                labelPlacementStart: {
                    display:'flex',
                    justifyContent:'space-between',
                    // backgroundColor:'green'
                }
            }
        }
    }
})

