import { React, useState } from 'react'
import { Container, Card, Button, Row, Col } from 'react-bootstrap';
import { t } from '../../../../misc/utils'

import { Footer, Guides, GuidesComponent } from '../../../index'

import '../../../Home/style.css'

// Component
// import { Accordion, AccordionSummary, Typography, AccordionDetails } from '@mui/material'
// import { ExpandMore } from '@mui/icons-material'
import convenient from '../../../../assets/image/Convenient.png'
import order from '../../../../assets/image/Order.png'
import delivery from '../../../../assets/image/Delivery.png'

//fix
import card1 from '../../../../assets/component/card1.png'
import card21 from '../../../../assets/component/card2-1.svg'
import card22 from '../../../../assets/component/card2-2.svg'
import card23 from '../../../../assets/component/card2-3.svg'
import card3 from '../../../../assets/component/card3.png'
import appstore from '../../../../assets/component/appstore.png'
import googleplay from '../../../../assets/component/googleplay.png'
import huwawei from '../../../../assets/component/appgalery.png'

import step1 from '../../../../assets/image/1.png'
import step2 from '../../../../assets/image/2.png'
import step3 from '../../../../assets/image/3.png'
import step4 from '../../../../assets/image/4.png'
import teaser from '../../../../assets/image/Teaser.png'

export const DesktopScreen = () => {
    return (
        <div className='main'>
            <Card style={{ border: 'none' }}>
                <Card.Body className='background-pink card-1'>
                    <div className='container-default'>
                        <Row>
                            <Col xs={6}>
                                <div className='left-side'>
                                    <p className='label'>{t.home.card1.textTitle}</p>
                                    <p>{t.home.card1.textContent}</p>
                                    <a href='register' className='btn btn-warning-up'>{t.home.card1.textButton}</a>
                                </div>
                            </Col>
                            <Col xs={6}>
                                <div className='right-side ms-auto'>
                                    <img src={card1} />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Card.Body>
                <Card.Body className='card-white card-2'>
                    <div className='container-default'>
                        <Row>
                            <Col xs={6}>
                                <div className='card-text-rows-1'>
                                    <p className='label'>{t.home.card2.textTitle}</p>
                                    <p>{t.home.card2.textContent1} {t.home.card2.textContent2}</p>
                                </div>
                            </Col>
                            <Col xs={12}>
                                <div className='card-text-rows-2'>
                                    <Row>
                                        <Col xs={6}>
                                            <p><img src={card21} alt="" /></p>
                                            <p><b>{t.home.card2.textContentTitle1}</b></p>
                                            <p>{t.home.card2.textSubcontent1}</p>
                                        </Col>
                                        <Col xs={6}>
                                            <p><img src={card22} alt="" /></p>
                                            <p><b>{t.home.card2.textContentTitle2}</b></p>
                                            <p>{t.home.card2.textSubcontent2}</p>
                                        </Col>
                                        <Col xs={6}>
                                            <p><img src={card23} alt="" /></p>
                                            <p><b>{t.home.card2.textContentTitle3}</b></p>
                                            <p>{t.home.card2.textSubcontent3}</p>
                                        </Col>
                                    </Row>
                                </div>

                            </Col>
                        </Row>
                    </div>
                </Card.Body>
                <Card.Body className='background-pink card-3'>
                    <div className='container-default'>
                        <Row>
                            <Col xs={6}>
                                <div className='left-side'>
                                    <p className='label'>{t.home.card2.textTitle}</p>
                                    <ul>
                                        <li><p>{t.home.card3.textContent1}</p></li>
                                        <li><p>{t.home.card3.textContent2}</p></li>
                                        <li><p>{t.home.card3.textContent3}</p></li>
                                        <li><p>{t.home.card3.textContent4}</p></li>
                                        <li><p>{t.home.card3.textContent5}</p></li>
                                    </ul>
                                    <div className='downloads'>
                                        <Row>
                                            {/* <Col xs={4} className='background-black'><img src={appstore} alt="" /></Col> */}
                                            <Col xs={4} className='background-black'><a href='https://maxim-merchant.onelink.me/YyIq?pid=test&c=test&af_click_lookback=7d'><img src={googleplay} alt="" /></a></Col>
                                            {/* <Col xs={4} className='background-black'><img src={huwawei} alt="" /></Col> */}
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={6}>
                                <div className='right-side ms-auto'>
                                    <img src={card3} />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Card.Body>
                <Card.Body className='card-white card-4'>
                    <div className='container-default'>
                        <Row>
                            <Col xs={12}>
                                <div className='text'>
                                    <p className='label'>{t.home.card4.textTitle}</p>
                                    <Row className='text-second'>
                                        <Col xs={6}>
                                            <p><img src={step1} alt="" /></p>
                                            <p>{t.home.card4.textContent1}</p>
                                        </Col>
                                        <Col xs={6}>
                                            <p><img src={step2} alt="" /></p>
                                            <p>{t.home.card4.textContent2}</p>
                                        </Col>
                                        <Col xs={6}>
                                            <p><img src={step3} alt="" /></p>
                                            <p>{t.home.card4.textContent3}</p>
                                        </Col>
                                        <Col xs={6}>
                                            <p><img src={step4} alt="" /></p>
                                            <p>{t.home.card4.textContent4}</p>
                                        </Col>
                                    </Row>
                                    <a href='/register' className='btn btn-warning-up'>{t.home.card4.textButton}</a>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Card.Body>
                <Card.Body className='background-pink card-5'>
                    <div className='container-default'>
                        <Row>
                            <Col xs={6}>
                                <div className='right-side'>
                                    <p><img src={teaser} alt="" /></p>
                                </div>
                            </Col>
                            <Col xs={6}>
                                <div className='right-side-5'>
                                    <p className='label'>{t.home.card5.textTitle}</p>
                                    <p className='text'>
                                        {t.home.card5.textContent1}
                                    </p>
                                    <p className='text'>
                                        {t.home.card5.textContent2}
                                    </p>
                                    {/* <p className='text'>
                                        {t.home.card5.textButton}
                                    </p> */}
                                    <a href='/contact-us' className='btn btn-regisnow'>{t.home.card5.textButton}</a>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Card.Body>
                <GuidesComponent />
            </Card>
            <Footer />
        </div >
    )
}

export default DesktopScreen
