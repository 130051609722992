import { React } from 'react'
import { useMediaQuery } from 'react-responsive'
import { Container, Card, Row, Col } from 'react-bootstrap';
import { Accordion, AccordionSummary, Typography, AccordionDetails } from '@mui/material'
import { ExpandMore } from '@mui/icons-material'

import { TopNavMain, Footer, GuidesComponent, QNA } from '../index'
import './style.css'


import BusinessReadyComponent from '../component/home/businessReady';
import RegisteringGuideComponent from '../component/home/registration';

const Registering = () => {
    const Desktop = useMediaQuery({ query: '(max-width:1235px)' })    
    const mobile = useMediaQuery({ query: '(max-width:743px)' })  

    return (
        <div className='main'>
            <TopNavMain />
            <RegisteringGuideComponent mobile={mobile} desktop={Desktop} />
            <BusinessReadyComponent/>
            <GuidesComponent />
            <QNA />
            <Footer />
        </div >
    )
};

export default Registering

