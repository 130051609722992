import { Modal } from 'react-bootstrap';
import { 
    FormControl, 
    InputLabel, 
    MenuItem, 
    Select, 
    // TextField 
} from '@mui/material';
import { t } from '../../../misc/utils';
import './style.css';

export const ModalAddComponent = ({modalAdd, onChangeDay, options, timeOpen, timeClose, onChangeTime, SetModalAdd, handleSubmit}) => {
    return (
        <Modal show={modalAdd} centered>    
            <Modal.Body>
                <h5>{t.merchant.schedule.text.add.title}</h5>
                <hr />
                {/* <InputLabel>Days</InputLabel> */}

                <FormControl fullWidth>
                    <InputLabel>{t.merchant.schedule.form.day}</InputLabel>
                    <Select
                        label={t.merchant.schedule.form.day}
                        onChange={onChangeDay}
                    >
                        {options.map((item) => {
                            return (
                                <MenuItem value={item.value}>{item.name}</MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
                <div className="row">
                    <div className="col-md-6">
                        <InputLabel>{t.merchant.schedule.form.open}</InputLabel>
                        <input class='form-control' type='time' value={timeOpen} onChange={(e) => onChangeTime(e, 'open')} />
                    </div>
                    <div className="col-md-6">
                        <InputLabel>{t.merchant.schedule.form.close}</InputLabel>
                        <input class='form-control' type='time' value={timeClose} onChange={(e) => onChangeTime(e, 'close')} />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className='btn-modal' onClick={() => SetModalAdd(false)}>{t.merchant.btn.close}</button>
                <button className='btn-modal' onClick={() => handleSubmit('submit')}>{t.merchant.btn.submit}</button>
            </Modal.Footer>
        </Modal>
    )
}

export const ModalEditComponent = ({modalEdit, onChangeDay, day, options, timeOpen, timeClose, onChangeTime, setModalEdit, handleSubmit}) => {
    return (
        <Modal show={modalEdit} centered>
            <Modal.Body>
                <h5>{t.merchant.schedule.text.edit.title}</h5>
                <hr />
                <FormControl fullWidth>
                    <InputLabel>{t.merchant.schedule.form.day}</InputLabel>
                    <Select
                        label={t.merchant.schedule.form.day}
                        onChange={onChangeDay}
                        value={day}
                    >
                        {options.map((item) => {
                            return (
                                <MenuItem value={item.value}>{item.name}</MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
                <div class='row'>
                    <div className="col-md-6">
                        <InputLabel>{t.merchant.schedule.form.open}</InputLabel>
                        <input class='form-control' type='time' value={timeOpen} onChange={(e) => onChangeTime(e, 'open')} />
                    </div>
                    <div className="col-md-6">
                        <InputLabel>{t.merchant.schedule.form.close}</InputLabel>
                        <input class='form-control' type='time' value={timeClose} onChange={(e) => onChangeTime(e, 'close')} />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className='btn-modal' onClick={() => setModalEdit(false)}>{t.merchant.btn.close}</button>
                <button className='btn-modal' onClick={() => handleSubmit('edit')}>{t.merchant.btn.submit}</button>
            </Modal.Footer>
        </Modal>
    )
}

export const ModalDeleteComponent = ({modalDelete, setModalDelete, handleDelete}) => {
    return (
        <Modal show={modalDelete} centered>
            <Modal.Header>
                <h5>{t.merchant.schedule.text.delete.title}</h5>
            </Modal.Header>
            <Modal.Body>
                <InputLabel>{t.merchant.schedule.text.delete.alert}</InputLabel>
            </Modal.Body>
            <Modal.Footer>
                <button className='btn-modal' onClick={() => setModalDelete(false)}>{t.merchant.btn.close}</button>
                <button className='btn-modal' onClick={() => handleDelete('delete')}>{t.merchant.btn.submit}</button>
            </Modal.Footer>
        </Modal>
    )
}