import { React } from 'react'
import { useMediaQuery } from 'react-responsive'
import { Container, Card, Row, Col } from 'react-bootstrap';

import { TopNavMain, Footer, GuidesComponent, QNA } from '../'
import './style.css'

import BusinessReadyComponent from '../component/home/businessReady';
import ProcessGuideComponent from '../component/home/guides/processGuide';

const Process = () => {
    const Desktop = useMediaQuery({ query: '(max-width:1235px)' })    
    const mobile = useMediaQuery({ query: '(max-width:743px)' }) 

    return (
        <div className='main'>
            <TopNavMain />            
            <ProcessGuideComponent mobile={mobile} />
            <GuidesComponent />
            <BusinessReadyComponent/>
            <QNA />
            <Footer />
        </div >
    )
};

export default Process

