import { React } from 'react'
import { useMediaQuery } from 'react-responsive'
import { Container, Card, Row, Col } from 'react-bootstrap';
import { ExpandMore } from '@mui/icons-material'

import { TopNavMain, Footer, GuidesComponent, QNA} from '../'
import './style.css'

import BusinessReadyComponent from '../component/home/businessReady';

const Guides = () => {
    const mobile = useMediaQuery({
        query: '(max-width:1235px)'
    });

    return (
        <div className='main'>
            <TopNavMain />
            <GuidesComponent />
            <BusinessReadyComponent/>
            <QNA />
            <Footer />
        </div >
    )
};

export default Guides

