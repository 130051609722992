import React from "react"
import { Card, Col, Container, Row } from "react-bootstrap"
import { t } from '../../../misc/utils'

const ContactUsComponent = ({ mobile, smallMobile }) => {
    const { city, country, lang } = {
        city: JSON.parse(localStorage.getItem('city')),
        country: JSON.parse(localStorage.getItem('country')),
        lang: localStorage.getItem('language')
    }
    return (
        <Card className='card-contact-1 border-0'>
            <Card.Body>
                <div className="container-default">
                    <Row>
                        {city?.address &&
                        <Col xs={12}>
                            <iframe src={`https://maps.google.com/maps?q=Maxim Transportasi ${city?.address}&hl=en&ie=UTF-8&z=14&output=embed`} referrerpolicy="no-referrer-when-downgrade"></iframe>             
                        </Col>
                        }
                        {city?.address &&
                        <Col xs={mobile && smallMobile == false ? 6 : mobile && smallMobile == true ? 12 : 4}>
                            <Card className='inside'>
                                <Card.Body>
                                    <b>{t.contact.card1.textTitle}</b>
                                    <p>{city?.address}</p>
                                </Card.Body>
                            </Card>
                        </Col>                        
                        }
                        <Col xs={mobile && smallMobile == false ? 6 : mobile && smallMobile == true ? 12 : 4}>
                            <Card className='inside'>
                                <Card.Body>
                                    <b>{t.navbar.textItem2.charAt(0).toUpperCase() + t.navbar.textItem2.slice(1).toLocaleLowerCase()}</b>
                                    <p>{city?.email}</p>
                                    {/* <p>{t.contact.card1.textContent}</p> */}
                                    <p>{t.contact.card1.textPhone}</p>                                    
                                </Card.Body>
                            </Card>
                        </Col>                        
                    </Row>
                </div>
            </Card.Body>
        </Card>
    )
}

export default ContactUsComponent