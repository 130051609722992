// Component
import React, { useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { t } from '../../../misc/utils'

import logomaxim from '../../../assets/image/logo.png'
import instagram from '../../../assets/component/instagram.png'

const Footer = () => {
    const [toggle, setToggle] = useState(window.matchMedia("max-width: 639px").matches);
    const Desktop = useMediaQuery({ query: '(max-width:1235px)' })
    const mobile1 = useMediaQuery({ query: '(max-width:744px)' })

    return (
        <>
            {//mobile
                mobile1 == true ?
                    <>
                        <div className="navbar navbar-fixed-bottom container-default-navbar">
                            <a src={instagram} href='https://www.instagram.com/maximmerchant_id/' target='_blank'><img className="instagram" src={instagram} alt='' /></a>
                        </div>
                        <div className="navbar navbar-fixed-bottom container-default-navbar">
                            <p>
                                &copy; {t.footer.text} <br />
                                <a href='https://legal.taximaxim.com/privacy/-/policy/?country=ID&intl=en-US' alt=''>{t.footer.textPrivacy}</a>
                            </p>
                        </div>
                    </>
                    :

                    //else except Desktop middle and mobile
                    <div className='footer'>
                        <div className='container-default-navbar'>
                            <div className='row'>
                                <div className='col-sm-5'>
                                    <a href="/" className="logo-maxim"><img alt='' src={logomaxim} /></a>
                                </div>
                                <div className='col-sm-2'>
                                    <p className='text-center'>
                                        <a src={instagram} href='https://www.instagram.com/maximmerchant_id/' target='_blank'><img className="instagram" src={instagram} alt='' /></a>
                                    </p>
                                </div>
                                <div className='col-sm-5'>
                                    <p className='text-right'>
                                        &copy; {t.footer.text} <br />
                                        <a href='https://legal.taximaxim.com/privacy/-/policy/?country=ID&intl=en-US' alt=''>{t.footer.textPrivacy}</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
            }

        </>
    )
}

export default Footer