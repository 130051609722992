import {
  Autocomplete,
  Button,
  FormControlLabel,
  TextField,
  ThemeProvider,
} from "@mui/material";
import  Cropper  from "react-easy-crop";
import IOSSwitch from "../../../../component/switch/switch";
import { themeFormProduct } from "./formProductMuiStyle";
import ReactLoading from "react-loading";
import vector from "../../../../assets/image/ImageChooseProduct.png";
import { InputImageProduct, ButtonChoosePhoto, ButtonSaveProduct } from "../../../";
// import { t } from "../../../../misc/utils";

const FormProduct = ({
  Action,
  item,
  category,
  error,
  loading,
  valAuto,
  setValAuto,
  defaultImage,
  type,
  t,
  cropImage,
  variationAttached
}) => {
  console.log(t, 'translate')
  const { NumberAction, FormAction, handleSubmit } = Action
  return (
    <div className="container-form-product">
      <ThemeProvider theme={themeFormProduct}>
        <IOSSwitch
          checked={item.is_empty}
          label={t.merchant.product.labelShowCustomer}
          onChange={(e) => FormAction.handleItemForm("is_empty", e.target.checked)}
        />
        <TextField
          id="standard-basic"
          value={item.name}
          autoComplete="off"
          label={t.merchant.product.labelName}
          variant="standard"
          color="primary"
          className="input-product"
          onChange={(e) => FormAction.handleItemForm("name", e.target.value)}
          error={error && !item.name}
          helperText={(error && !item.name) && "Name can't empty"}
        />
        <TextField
          id="standard-basic"
          value={item.desc}
          autoComplete="off"
          label={t.merchant.product.labelDesc}
          variant="standard"
          color="primary"
          className="input-product"
          onChange={(e) => FormAction.handleItemForm("desc", e.target.value)}
          error={error && !item.desc}
          helperText={(error && !item.desc) && "Description can't empty"}
        />
        <Autocomplete
          disableClearable={true}
          disablePortal
          options={category}
          value={valAuto}
          onChange={(e, newvalue) => {
            if(setValAuto) {
              setValAuto(newvalue)
            }
            FormAction.handleItemForm('item_categories_id', newvalue.id)
          }} 
          getOptionLabel={(options) => (options ? options.name : "")}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                id="standard-basic"
                variant="standard"
                color="primary"
                className="input-product"
                label={t.merchant.product.labelCat}
                error={error && !item.item_categories_id}
                helperText={(error && !item.item_categories_id) && "Category can't empty"}
              />
            );
          }}
        />
        <TextField
          id="standard-basic"
          autoComplete="off"
          label={t.merchant.product.labelPrice}
          variant="standard"
          color="primary"
          className="input-product"
          type={NumberAction.focus.price ? "number" : "text"}
          value={NumberAction.string.price ? NumberAction.string.price : item?.price}
          onKeyUp={FormAction.onKeyUp}
          onFocus={(e) => NumberAction.onFocus(e, "price")}
          onBlur={(e) => NumberAction.onBlur(e, "price")}
          onChange={(e) => FormAction.handleItemForm("price", e.target.value)}
          error={error && !item.price}
          helperText={(error && !item.price) && "Price can't empty"}
        />
        <IOSSwitch
          checked={item.discount}
          label={t.merchant.product.labelDisc}
          onChange={(e) => FormAction.handleItemForm("discount", e.target.checked)}
        />
        <TextField
          id="standard-basic"
          autoComplete="off"
          label={t.merchant.product.labelDiscAmount}
          variant="standard"
          color="primary"
          className={"input-product"}
          hidden={!item.discount}
          type={NumberAction.focus.disc ? "number" : "text"}
          value={NumberAction.string.disc ? NumberAction.string.disc : item?.discount_amount}
          onKeyUp={FormAction.onKeyUp}
          onFocus={(e) => NumberAction.onFocus(e, "disc")}
          onBlur={(e) => NumberAction.onBlur(e, "disc")}
          onChange={(e) => FormAction.handleItemForm("discount_amount", e.target.value)}
          error={error && !item.discount_amount}
          helperText={(error && !item.discount_amount) && "Discount Amount can't empty"}
        />
        <IOSSwitch
          checked={item.is_rec}
          label={t.merchant.product.labelRecommend}
          onChange={(e) => FormAction.handleItemForm("is_rec", e.target.checked)}
        />
      </ThemeProvider>
      
      <div className="container-pick-image contain-margin-bottom">
        {FormAction.ImagePreview === vector || FormAction.ImagePreview === defaultImage ? (
          <InputImageProduct error={error && FormAction.ImagePreview === vector} ImagePreview={FormAction.ImagePreview} handleChooseImage={FormAction.handleChooseImage} imageDefault={vector} />
        ) : FormAction.loadHeic ? (
          <div className='container-image-loading'>
            <ReactLoading type="spin" color="#ffc900" height={'70%'} width={'70%'} />
          </div>
        ) : (
          <>
            <div style={{  position: 'relative',
                                        width: '100%',
                                        height: 200, 
                                        background: '#333',
                                }}>
                                            <Cropper
                                            image={FormAction.ImagePreview}
                                            crop={cropImage.crop}
                                            rotation={cropImage.rotation}
                                            zoom={cropImage.zoom}
                                            aspect={1 / 1}
                                            onCropChange={cropImage.setCrop}
                                            // onRotationChange={cropImage.setRotation}
                                            onCropComplete={cropImage.onCropComplete}
                                            onZoomChange={cropImage.setZoom}
                                          />
                                        </div>
            <ButtonChoosePhoto loading={loading} handleChoose={FormAction.handleChooseImage} ItemImg={vector} />
          </>
        )}

      </div>
      {
        variationAttached &&
        <div className="contain-margin-bottom" style={{ padding:10, boxShadow:'3px 2px 10px -2px rgba(0,0,0,0.47)', borderRadius:10 }}>
          <p>
          {t.merchant.product.LabelVariationAttach}
          </p>
          <div 
          className={loading ? 'button-yellow-container disabled-button-yellow-container cursor-pointer' : 'button-yellow-container cursor-pointer' } 
          onClick={() => {
            if(!loading){
              FormAction.handleVariationAttached()
            }
          }}>
              <span>{t.merchant.btn.variationAttach}</span>
              </div>
        </div>
      }
      <ButtonSaveProduct t={t} type={type} loading={loading} handleSubmit={handleSubmit} />
    </div>
  );
};

export default FormProduct;
