import { combineReducers } from "redux";

import Register from './register'
import Product from './product'
import Schedule from './schedule'
const allReducer = combineReducers({
    Register,
    Product,
    Schedule
})

export default allReducer