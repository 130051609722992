import {Switch, Route} from 'react-router-dom'

// import pages
import {
  // Home,
  HomePage,
  MerchantAgreement,
  Register,
  CompleteAdd,
  ProductAdd,
  ProductEdit,
  CompleteEdit,
  PrivacyPolicy,
  Schedule,
  ContactUs,
  Guides,
  Addingproduct,
  Process,
  Order,
  Acceptorder,
  Variation,
} from '../../screen'
// import RegisterNew from '../../screen/auth/register/new-auth/register';
import Registration from '../../screen/component/home/registration';
import Registering from '../../screen/Home/registeringPage';



function Routers() {
  const reload = () => window.location.reload()
  return (
    <div>
      <Switch>
        <Route path='/' component={HomePage} exact />

        <Route path='/register' component={Register} exact />

        <Route path='/merchant-agreement' component={MerchantAgreement} />
        <Route path='/tnc' component={MerchantAgreement} />
        <Route path='/privacypolicy' component={PrivacyPolicy} />
        <Route path='/register/reg' component={Register} />

        {/* Merchant Route */}
        <Route path='/additem' component={ProductAdd} />
        <Route path='/edititem' component={ProductEdit} />
        <Route path='/complete_add' component={CompleteAdd} />
        <Route path='/complete_edit' component={CompleteEdit}/>
        <Route path='/schedule-open' component={Schedule}/>
        <Route path='/variation' component={Variation}/>
        {/* Special Route */}
        <Route path="/app-ads.txt" onEnter={reload} />


        {/* New Route */}        
        <Route path='/contact-us' component={ContactUs} />
        <Route path='/guides' component={Guides} exact />
        <Route path='/guides/registration' component={Registering} />
        <Route path='/guides/addingproduct' component={Addingproduct} />
        <Route path='/guides/processproduct' component={Process}  />
        <Route path='/guides/makeorder' component={Order}  />
        <Route path='/guides/acceptorder' component={Acceptorder}  />
        {/* <Route path='/new-merchant' component={RegisterNew} /> */}
      </Switch>
    </div>
  );
}

export default Routers;