import Routers from './application/router';
import React from 'react'
import ReactGA from "react-ga4";
import { getBrowserLocales } from './misc/utils';

function App() {
  const language = localStorage.getItem('language')
  if(!language) localStorage.setItem('language', getBrowserLocales({languageCodeOnly:true})[0])
  

  // # production
  
  React.useEffect(() => {
    ReactGA.initialize("G-8N8384W646");
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  // # testing
  
  // React.useEffect(() => {
  //   ReactGa.initialize('UA-219754999-1')
  //   ReactGa.pageview(window.location.pathname)
  // },[])
  return (
    <div>
      <Routers />
    </div>
  );
}

export default App;
