import { React } from 'react'
import { t } from '../../../misc/utils'

// Component
import { Card, Container, Row, Col, Accordion } from "react-bootstrap"
import { } from '@mui/material'
import { ExpandMore } from '@mui/icons-material'
import { useMediaQuery } from "react-responsive"

const QNA = () => {
    const mobile = useMediaQuery({
        query: '(max-width:1236px)'
    })   

    return (
        <div>
            <Card.Body className='frequenly-asked'>
                <div className='container-default'>
                    <Row>
                        <p className='label'>{t.qna.textTitle}</p>
                        <Col xs={mobile ? 12 : 6}>
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>
                                        {t.qna.textContent1}
                                        {/* Apakah saya bisa mendaftarkan lebih dari 1 merchant? */}
                                    </Accordion.Header>
                                    <Accordion.Body className='background-pink'>
                                        {t.qna.textSubcontent1}
                                        {/* Bisa.  Jika anda memiliki lebih dari dua outlet, silahkan daftarkan masing-masing outlet menggunakan alamat email yang berbeda. */}
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>
                                        {t.qna.textContent2}
                                        {/* Berapa komisi penjualan untuk Maxim */}
                                    </Accordion.Header>
                                    <Accordion.Body className='background-pink'>
                                        {t.qna.textSubcontent2}
                                        {/* Saat ini Maxim tidak mengambil komisi dari penjualan. Anda dapat menampilkan harga sesuai dengan di menu. */}
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>
                                        {t.qna.textContent3}
                                        {/* Bagaimana cara pembayaran ke merchant */}
                                    </Accordion.Header>
                                    <Accordion.Body className='background-pink'>
                                        {t.qna.textSubcontent3}
                                        {/* Driver akan menalangi pembayaran secara cash untuk customer */}
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3">
                                    <Accordion.Header>
                                        {t.qna.textContent4}
                                        {/* Bagaimana cara mengubah urutan kategori */}
                                    </Accordion.Header>
                                    <Accordion.Body className='background-pink'>
                                        {t.qna.textSubcontent4}
                                        {/* Kategori diurutkan otomatis dari yang paling baru dibuat. Anda tidak dapat mengubah urutan secara manual */}
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="4">
                                    <Accordion.Header>
                                        {t.qna.textContent5}
                                        {/* Bagaimana merchant membuka / menutup outlet */}
                                    </Accordion.Header>
                                    <Accordion.Body className='background-pink'>
                                        {t.qna.textSubcontent5}
                                        {/* Anda hanya dapat membuka/tutup outlet melalui tombol BUKA/TUTUP yang ada di aplikasi. */}
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="5">
                                    <Accordion.Header>
                                        {t.qna.textContent6}
                                        {/* Bagaimana cara menghapus menu / item produk */}
                                    </Accordion.Header>
                                    <Accordion.Body className='background-pink'>
                                        {t.qna.textSubcontent6}
                                        {/* Menu / item produk yang sudah dibuat, tidak dapat dihapus. Anda dapat menon-aktifkannya, atau mengganti dengan menu / item produk yang baru */}
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>
                    </Row>
                </div>
            </Card.Body>
        </div>
    )
}

export default QNA
