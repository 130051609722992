import { Container, Card, Row, Col } from 'react-bootstrap'
import { t } from '../../../../misc/utils'
import step1 from '../../../../assets/image/add-step1.svg'
import step2 from '../../../../assets/image/add-step2.svg'
import step3 from '../../../../assets/image/add-step3.svg'
// import step4 from '../../../../assets/image/add-step4.png'

const AddingProductGuideComponent = ({ mobile, desktop }) => {
    return (
        <div className='tutorial'>
            <div className='container-default'>
                {mobile ? <b>{t.addgoods.textTitle}</b> : <p className='label'>{t.addgoods.textTitle}</p>}
                <Row>
                    <Col xs={mobile ? 12 : 6} className={mobile ? 'img-center' : 'img-center padding-vertical70' }>
                        <img src={step1} height={mobile ? 160 : "100%"}></img>
                    </Col>
                    <Col xs={mobile ? 12 : 6} className={mobile ? 'img-center padding-vertical25' : 'img-center padding-vertical70'}>
                        <ul>
                            <li>
                                <p>{t.guides.addProduct.textStep1}</p>
                            </li>                            
                        </ul>
                    </Col>
                    <Col xs={mobile ? 12 : 6} className={mobile ? 'img-center' : 'img-center padding-vertical70' }>
                        <img src={step2} height={mobile ? 160 : "100%"}></img>
                    </Col>
                    <Col xs={mobile ? 12 : 6} className={mobile ? 'img-center padding-vertical25' : 'img-center padding-vertical70'}>
                        <ul>
                            <li>
                                <p>{t.guides.addProduct.textStep2}</p>
                            </li>                            
                        </ul>
                    </Col>
                    <Col xs={mobile ? 12 : 6} className={mobile ? 'img-center' : 'img-center padding-vertical70' }>
                        <img src={step3} height={mobile ? 160 : "100%"}></img>
                    </Col>
                    <Col xs={mobile ? 12 : 6} className={mobile ? 'img-center padding-vertical25' : 'img-center padding-vertical70'}>
                        <ul>
                            <li>
                                <p>{t.guides.addProduct.textStep3_1}</p>
                            </li>                            
                            <li>
                                <p>{t.guides.addProduct.textStep3_2}</p>
                            </li>                            
                        </ul>
                    </Col>                    
                </Row>
            </div>
        </div>
    )
}

export default AddingProductGuideComponent