const En = {
  languageModal: {
    textTitle: "Silakan pilih bahasa dan negara Anda",
    textChooseCity: "Pilih kota",
    search: "Mencari",
  },
  navbar: {
    textItem1: "TUTORIAL",
    textItem2: "KONTAK",
    textButton: "Daftar sekarang",
    textRegis: "Pendaftaran",
    textRegistration: "Bagaimana cara menambahkan barang-barang Anda",
    textProcess: "Bagaimana cara memproses order",
    textOrders: "(Untuk pelanggan) Bagaimana cara memproses order",
    textComplete: "Bagaimana cara menyelesaikan order",
    textSm: "IKUTI KAMI DI MEDIA SOSIAL",
    textSm1:
      "Dapatkan saran dan rekomendasi mengenai cara menggunakan aplikasi dan layanan",
  },
  footer: {
    text: "2022 Maxim - semua hak dilindungi undang-undang",
    textPrivacy: "Kebijakan Privasi",
  },
  qna: {
    textTitle: "Tanya Jawab",
    textContent1: "Bisakah saya mendaftar lebih dari satu merchant?",
    textContent2: "Berapa biaya komisi Anda?",
    textContent3: "Siapa yang membayar merchant?",
    textContent4: "Bagaimana cara saya membuka outlet di dalam aplikasi?",
    textContent5: "Bagaimana cara saya menghapus barang di dalam aplikasi?",
    textContent6: "Bagaimana cara saya mengubah urutan kategori?",
    textSubcontent1:
      "Anda dapat melakukannya akan tetapi Anda harus menggunakan alamat email yang berbeda untuk setiap merchant yang Anda daftarkan.",
    textSubcontent2:
      "Saat ini, Anda dapat menghasilkan uang dengan Layanan kami tanpa komisi. Anda dapat menentukan harga masakan seperti di menu.",
    textSubcontent3:
      "Para pengemudi membayar order dengan uang mereka sendiri, dan kemudian menerima pembayaran dari pelanggan.",
    textSubcontent4:
      "Ketuk Buka apabila Anda siap menerima order dan ketuk Tutup setelah Anda selesai bekerja.",
    textSubcontent5:
      "Untuk saat ini, Anda tidak dapat melakukannya. Nonaktifkan saja barang Anda atau ganti dengan yang lain. ",
    textSubcontent6:
      "Kategori diurutkan dari yang terlama ke yang terbaru, dan Anda tidak dapat mengubah urutannya secara manual.",
  },
  guides: {
    register: {
      textStep1:
        "Isi informasi Anda: masukkan kata sandi yang akan Anda gunakan untuk masuk, serta nomor telepon Anda.",
      textStep2: "Tentukan alamat kafe atau titik pengiriman Anda.",
      textStep3: "Unggah logo perusahaan Anda atau gambar toko Anda.",
      textStep4:
        "Semua selesai! Anda sekarang dapat melanjutkan untuk mengunggah barang-barang Anda.",
    },
    addProduct: {
      textStep1:
        "Untuk memulainya, buat kategori produk, misalnya Makanan Ringan. Anda dapat menambahkan kategori sebanyak yang Anda butuhkan.",
      textStep2:
        "Ketuk “+” di sebelah nama kategori untuk menambahkan produk Anda. ",
      textStep3_1:
        "Masukkan informasi tentang produk Anda, tentukan diskon, bila Anda ingin memberikannya. Diskon dan foto berkualitas tinggi akan membuat barang Anda terlihat menonjol.",
      textStep3_2:
        "Apabila Anda menandai produk sebagai Direkomendasikan, produk tersebut akan ditampilkan di antara yang pertama di toko Anda.",
    },
    processOrder: {
      textStep1:
        "Ketuk Buka untuk mulai bekerja. Ketika ada order baru, Anda akan menerima notifikasi.",
      textStep2_1:
        'Ketuk "Konfirmasi order" untuk mulai mengerjakan order, atau "Batalkan order" apabila, seandainya, produk Anda saat ini sedang kehabisan stok.',
      textStep2_2:
        "Apabila Anda tidak mengonfirmasi order dalam tiga menit, order akan dibatalkan secara otomatis.",
      textStep3:
        "Kami merekomendasikan supaya Anda menunggu kurir sebelum mempersiapkan order.",
    },
    placeOrder: {
      textStep1: 'Pelanggan memilih tarif Foods&Goods dan mengetuk "Beli apa?"',
      textStep2:
        "Mereka dapat memilih toko dari daftar atau mencari produk tertentu.",
      textStep3:
        "Barang yang Anda tandai sebagai Direkomendasikan datang lebih dulu.",
      textStep4:
        "Pelanggan dapat menentukan jumlah barang, dan biaya akan dihitung ulang secara otomatis.",
      textStep5:
        "Aplikasi ini memiliki semua fitur untuk kenyamanan pelanggan, seperti misalnya memesan untuk orang yang berbeda atau waktu pengiriman yang nyaman.",
      textStep6:
        "Pelanggan dapat melacak kurir dengan order mereka di peta daring.",
    },
  },
  home: {
    card1: {
      textTitle: "Jual barang-barang Anda dengan Maxim",
      textContent:
        "Dapatkan akses ke audiens yang aktif dan tanpa membayar biaya tambahan.",
      textButton: "Daftar sekarang",
    },
    card2: {
      textTitle: "Dapatkan lebih banyak pelanggan",
      textContent1:
        "Posting barang-barang Anda untuk dilihat ribuan pengguna aplikasi Maxim.",
      textContent2: "Lihat semua keuntungan bekerja sama dengan kami.",
      textContentTitle1: "Bebas komisi",
      textContentTitle2: "Pengiriman yang cepat",
      textContentTitle3: "Iklan gratis",
      textSubcontent1:
        "Kami tidak membebankan persentase penjualan — terima semua pendapatan dari penjualan barang dan tetapkan harga seperti pada menu untuk menarik para pelanggan.",
      textSubcontent2:
        "Order Anda akan diantar oleh kurir terdekat dari titik penjemputan. Pengiriman dibayar oleh pelanggan. ",
      textSubcontent3:
        "Kami akan membuat spanduk bersama untuk bisnis Anda dan memposting info tentang perusahaan Anda di aplikasi kami untuk pengemudi dan pelanggan.",
    },
    card3: {
      textTitle: "Terima order di aplikasi yang praktis",
      textContent1:
        "Tambahkan outlet untuk satu merek sebanyak yang Anda butuhkan. ",
      textContent2:
        "Buat kategori apa pun yang Anda inginkan. Misalnya, minuman, sup, hidangan kedua, salad, dll. ",
      textContent3:
        "Unggah foto yang menarik agar lebih menonjol dari bisnis yang lainnya. ",
      textContent4: "Tetapkan diskon untuk menarik lebih banyak pelanggan.",
      textContent5:
        "Analisis statistik pembelian untuk menawarkan barang yang lebih populer.",
    },
    card4: {
      textTitle: "Mulai sekarang!",
      textContent1: "Daftar",
      textContent2: "Unduh aplikasi kami",
      textContent3: "Tambahkan barang-barang Anda",
      textContent4: "Tunggu pelanggan pertama Anda!",
      textButton: "Daftar sekarang",
    },
    card5: {
      textTitle: "Iklan luring gratis",
      textContent1:
        "Jadikan bisnis Anda lebih menonjol. Kami akan merancang tata letak dan membuat spanduk bersama untuk Anda, gratis. Ini akan membantu Anda menarik pelanggan dan memberitahukan kepada mereka tentang layanan pemesanan daring Anda. ",
      textContent2:
        "Memesan spanduk telah tersedia untuk merchant yang sudah terdaftar. Hubungi salah satu kantor kami.",
      textButton: "Menampilkan alamat-alamat kantor",
    },
    card6: {
      textTitle: "Rincian panduan",
      textContent1: "Pelajari bagaimana menggunakan layanan kami.",
      textSubcontent1: "Cara mendaftar",
      textSubcontent2: "Bagaimana cara menambahkan barang-barang",
      textSubcontent3: "Bagaimana cara meproses order",
      textSubcontent4: "(Untuk pelanggan) Bagaimana cara melakukan order",
      textSubcontent5: "(Untuk kurir) Cara mengantarkan pesanan",
    },
  },
  contact: {
    card1: {
      textTitle: "Kantor",
      textContent:
        "Jl. KH. Noer Ali No. 192, Kayuringin Jaya, Kec. Bekasi Sel., Kota Bks, Jawa Barat 17144",
      textPhone: "+ 6221-5092-8720",
    },
  },
  addgoods: {
    card1: {
      textTitle:
        "Bagaimana cara menambahkan barang Anda di aplikasi MaximMerchant",
      textContent1:
        "Pada label ITEM, pilih KATEGORI, lalu ketuk “+” dan buat kategori baru.",
      textContent2: "Buat kategori sebanyak yang Anda butuhkan.",
      textContent3: "Pada label ITEM, pilih MENU dan ketuk “+”.",
      textContent4_0:
        "Isi semua bidang. Apabila Anda menginginkan barang-barang Anda ditampilkan di halaman utama merchant, aktifkan rekomendasi. Di deskripsi, Anda dapat menentukan nama barang.",
      textContent4_1:
        "Pindahkan tanda sakelar untuk mengaktifkan barang. Sekarang, para pelanggan akan melihatnya di aplikasi mereka. Saat ini, Anda tidak dapat menghapus barang Anda, tetapi Anda dapat menonaktifkannya atau menggantinya dengan yang lain.",
      textContent4_2:
        "Ketuk BUKA saat Anda siap untuk mulai menerima order. Setelah Anda selesai bekerja, pastikan untuk mengetuk TUTUP.",
    },
    card2: {
      textTitle: "Siap untuk memulai?",
      textContent:
        "Daftar dan mulailah mempromosikan bisnis Anda bersama kami!",
      textButton: "Pendaftaran",
    },
  },
  merchant: {
    product: {
      labelShowCustomer: "Tampilkan kepada para pelanggan",
      labelName: "Nama produk",
      labelDesc: "Deskripsi",
      labelCat: "Kategori",
      labelPrice: "Harga",
      labelDisc: "Diskon",
      labelDiscAmount: "Jumlah diskon",
      labelRecommend: "Direkomendasikan",
      labelChoosePhoto: "Pilih sebuah foto",
      labelSaveAdd: "Simpan produk",
      labelSaveEdit: "Simpan perubahan",
      labelRequired: "Wajib",
      labelNotRequired: "Tidak wajib",
      LabelCancelEdit: "Batal",
      LabelVariationAttach: "Pilihan terlampir",
    },
    btn: {
      pic: "Pilih sebuah foto",
      submit: "Ajukan",
      close: "Tutup",
      add_more: "Tambah lagi",
      schedule: "Menambah jadwal baru",
      edit: "Ubah",
      delete: "Hapus",
      variationAttach:
        "Ketuk di sini untuk melihat pilihan yang dilampirkan pada barang ini",
    },
    schedule: {
      text: {
        title: "Jam buka",
        add: { title: "Tambah jadwal" },
        edit: { title: "Ubah jadwal" },
        delete: {
          title: "Hapus jadwal",
          alert: "Apakah Anda yakin ingin menghapus data?",
        },
      },
      day: {
        sunday: "Minggu",
        monday: "Senin",
        tuesday: "Selasa",
        wednesday: "Rabu",
        thursday: "Kamis",
        friday: "Jumat",
        saturday: "Sabtu",
      },
      form: {
        day: "Hari",
        open: "Jam buka",
        close: "Jam tutup",
      },
    },
    err: {
      pic_fail: `Tidak dapat mengunggah gambar, mohon menggunakan foto yang lain`,
      empty: `Formulir tidak dapat kosong`,
      no_pic: "Pilih foto untuk diunggah",
      get: "Kesalahan saat mengambil data",
      no_data: "Tidak ada data",
      add: "Eror saat menambahkan data",
      edit: "Eror saat mengubah data",
      delete: "Eror saat menghapus data",
      categoryFetch:
        "Gagal mengambil kategori, silakan coba untuk masuk kembali",
      variationFetch:
        "Gagal mengambil pilihan, silakan coba untuk masuk kembali",
      productFetch: "Gagal mengambil produk, silakan coba untuk masuk kembali",
      connectedFetch:
        "Gagal mengambil lampiran pilihan, silakan coba untuk masuk kembali",
      processImage:
        "Gagal memproses gambar, silakan coba lagi atau silakan coba mengunggah gambar yang lainnya",
    },
  },
  register: {
    err: {
      title: "Eror!",
      no_pic: "Pilih foto untuk diunggah",
      sent: "Eror pada saat mengirimkan data ke server",
      email: "Surel sudah terdaftar",
      empty: `Formulir tidak dapat kosong`,
      no_loc:
        "Pilih lokasi Anda dari peta (ketuk peta dua kali untuk mendapatkan lokasi Anda saat ini atau pindahkan penunjuk ke lokasi Anda)",
      required: "Kolom yang wajib diisi",
      email_not_valid: "Email salah",
      pass_not_strong:
        "Kata sandi tidak cukup kuat. Gunakan minimal 7 karakter dan coba gabungkan huruf besar, huruf kecil, angka, dan karakter khusus (!@#$%^&*_+=-)",
      pass_strong: "Kata sandi kuat",
      pass_perfect: "Kata sandi sempurna",
    },
    btn: {
      close: "Tutup",
      continue: "Lanjutkan",
      back: "Langkah sebelumnya",
      next: "Langkah selanjutnya",
      register: "Daftar",
      photo: "Pilih sebuah foto",
    },
    text: {
      step: {
        first: "Langkah 1 dari 3",
        second: "Langkah 2 dari 3",
        third: "Langkah 3 dari 3",
        done: "Selesai",
      },
      title: {
        merchant_info: "Informasi dari merchant",
        merchant_location: "Lokasi toko",
        merchant_picture: "Gambar toko",
      },
      note: {
        registe_image:
          "Anda dapat mengunggah logo atau gambar produk utama Anda.",
        agreement: "Dengan mengklik Daftar, saya menyetujui untuk",
        tnc: "Syarat dan ketentuan",
        done: "Registrasi berhasil. Untuk mulai bekerja, Anda harus menunggu hingga kami mengonfirmasi akun Anda, yang biasanya memakan waktu beberapa hari. Kami akan menghubungi Anda atau mengirimkan email kepada Anda.",
        doneAndDownload:
          "Sementara itu, Anda dapat bersiap untuk menjual barang Anda dengan mengunduh aplikasi kami dan menambahkan barang tersebut di sana.",
      },
    },
    form: {
      phone: "Nomor telepon",
      name: "Nama toko",
      email: "Surel",
      desc: "Deskripsi",
      password: "Kata sandi",
      referral: "Rujukan (bila Anda memilikinya)",
      country: "Negara",
      city: "Kota",
      district: "Kecamatan",
      address: "Alamat",
    },
    question: {
      any: "Apakah ada pertanyaan?",
      check: "Silakan cek tutorial dari kami.",
      button: "Menampilkan tutorial",
    },
  },
  webView: {
    complete_add_title: "PENAMBAHAN PRODUK BERHASIL",
    complete_add_description:
      "Produk berhasil ditambahkan. Anda dapat menambahkan lebih banyak atau Anda dapat menutup halaman ini.",
    complete_edit_title: "PENYUNTINGAN PRODUK BERHASIL",
    complete_edit_description:
      "Produk telah disunting. Anda dapat menutup halaman ini.",
    imageUploadError: "File gambar terlalu besar! Maksimum 5 MB.",
  },
};
export default En;
