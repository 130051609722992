import axios from "axios"
const URL = 'https://idmarket-api.taximaxim.com/api/v1/merchant'
// const URL = 'http://localhost:8001/api/v1/merchant'
// const URL = 'http://10.0.2.2:8001/api/v1/merchant'

export const fetchItem_Categories = (token) => {
    return async (dispatch) => {
        try {
            // const token = localStorage.getItem('access_token')
            const res = await axios.get(`${URL}/item-category`, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                },
            })
            if (res.data.status) {
                dispatch({
                    type: 'ERRFETCH',
                    payload: 'error fetch data'
                })
            } else {
                dispatch({
                    type: 'FETCHCATEGORY',
                    payload: res.data.result
                })
            }
        }
        catch (err) {
            dispatch({
                type: 'ERRFETCH',
                payload: 'Category failed'
            })
        }
    }
}

export const createItem = async (token, data) => {
    try {
        // const token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9sb2NhbGhvc3Q6ODAwMVwvYXBpXC92MVwvbWVyY2hhbnRcL2xvZ2luIiwiaWF0IjoxNjY1MDI2MTI3LCJleHAiOjE2NjYyMzU3MjcsIm5iZiI6MTY2NTAyNjEyNywianRpIjoiOEtFdjBtWktocnlTaWpmbCIsInN1YiI6MzMzLCJwcnYiOiJhZTY1NjVkYTcyMzJjZDcxZDAxNDkzOTVjNmJiODcxYzgwN2M4MGRiIn0.-Od12NGzG4H2debsHSALvxB4wmmc74bxZ5tfHqW8AKU'
        const bodyFormData = new FormData();
        Object.keys(data).forEach(key => {
            if (key == 'image') {
                bodyFormData.append('image', data.image)
            }
            else {
                bodyFormData.append(key, data[key])
            }
        });
        bodyFormData.append('total', (data.price - data.discount_amount));

        const res = await axios.post(`${URL}/item/create`, bodyFormData, {
            headers: { Authorization: `Bearer ${token}` },
            'Content-Type': `multipart/form-data; boundary=${bodyFormData._boundary}; charset=UTF-8`
        });
        
        if(res.status === 200) return true
        else return false
    }
    catch (err) {

        return false
    }

}

export const editItem = async(token, data, itemId) => {
    try {
        // const token = localStorage.getItem('access_token')
        const bodyFormData = new FormData();
        Object.keys(data).forEach(key => {
            if (key == 'image') {
                bodyFormData.append('image', data.image)
            }
            else {
                bodyFormData.append(key, data[key])
            }
        });
        bodyFormData.append('total', (data.price - data.discount_amount));

        await axios.post(`${URL}/item/update/` + itemId, bodyFormData, {
            headers: { Authorization: `Bearer ${token}` },
            'Content-Type': `multipart/form-data; boundary=${bodyFormData._boundary}; charset=UTF-8`
        });
        
        return true
    }
    catch (err) {
        return false
    }
}

export const RemoveErrProduct = () => {
    return async(dispatch) => {
        try{
            dispatch({
                type: 'REMOVE_ERR_FETCH',
            })
        }
        catch(err) {
            dispatch({
                type: 'REMOVE_ERR_FETCH',
            })
        }
    }
}

export const fetchCatAddon = async(token) => {
    try {
        const res = await axios.get(`${URL}/addon-cat?not_empty=1`, {
            headers: {
                'Authorization': 'Bearer ' + token,
            },
        })
        return res.data
    } catch (error) {
        console.log(error)
        throw error
    }
    
}
export const fetchItemCatWithItem = async(token) => {
    try {
        const res = await axios.get(`${URL}/item-category?item=1`, {
            headers: {
                'Authorization': 'Bearer ' + token,
            },
        })
        return res.data
    } catch (error) {
        console.log(error)
        throw error
    }
    
}

export const fetchConnectedCatAddon = async (token, id, type) => {
    const params = type === 'item' ? `id_merchant_item=${id}` : `id_addon_cat=${id}`
    try {
        const res = await axios.get(`${URL}/addon-cat/connected?${params}`, {
            headers: {
                'Authorization': 'Bearer ' + token,
            },
        })
        return res.data
    } catch (error) {
        console.log(error)
        throw error
    }

}
export const connectAddon = async (token, data) => {
    try {
        const res = await axios.post(`${URL}/addon-cat/connect`, data, {
            headers: {
                'Authorization': 'Bearer ' + token,
            },
        })
        return res.data
    } catch (error) {
        console.log(error)
        throw error
    }

}