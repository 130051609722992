import { Button } from "@mui/material"
import { t } from "../../misc/utils"

const ButtonChoosePhoto = ({loading, ItemImg, handleChoose}) => {
    return(
        <label htmlFor="upload-photo">
          <form encType='multipart/form-data'>
            <input
            disabled={loading}
              src={ItemImg}
              style={{ display: 'none' }}
              id="upload-photo"
              name="IMG"
              type="file"
              accept='.jpg, .jpeg, .png'
              onChange={handleChoose}
            />
          </form>
          <div className={loading ? 'button-yellow-container disabled-button-yellow-container' : 'button-yellow-container' }>
            <span>{t.merchant.product.labelChoosePhoto}</span>
            </div>
        </label>
    )
}

export default ButtonChoosePhoto