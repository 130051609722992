import axios from "axios"
const URL = 'https://idmarket-api.taximaxim.com/api/v1/merchant/schedule-merchant'

export const fetchSchedule = (token) => {
    return async (dispatch) => {
        try {
            const res = await axios.get(`${URL}`, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            })
            if (res.data.status) {
                dispatch({
                    type: 'ERR_FETCH_SCHEDULE',
                    payload: 'error fetch data'
                })
            }
            else {
                dispatch({
                    type: 'FETCHSCHEDULE',
                    payload: res.data.result
                })
            }
        }
        catch (err) {
            dispatch({
                type: 'ERR_FETCH_SCHEDULE',
                payload: 1
            })
        }
    }
}

export const createSchedule = async (token, data) => {
    try {
        const bodyFormData = new FormData()
        Object.keys(data).forEach(key => {
            bodyFormData.append(key, data[key])
        })
        const res = await axios.post(`${URL}/create`, bodyFormData, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
        if (res.status === 200) return true
        else return false
    }
    catch (err) {
        return false
    }

}
export const updateSchedule = async (token, data, id) => {
    try {
        const bodyFormData = new FormData()
        Object.keys(data).forEach(key => {
            bodyFormData.append(key, data[key])
        })
        const res = await axios.post(`${URL}/update/${id}`, bodyFormData, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
        if (res.status === 200) return true
        else return false
    }
    catch (err) {
        return false
    }

}
export const deleteSchedule = async (token, id) => {
    try {
        const res = await axios.delete(`${URL}/delete/${id}`, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
        if (res.status === 200) return true
        else return false
    }
    catch (err) {

        return false
    }

}
export const removeErrSchedule = (setLoading) => {
    return async (dispatch) => {
        try {
            setLoading(false)
            dispatch({
                type: 'REMOVE_ERR_FETCH_SCHEDULE'
            })
        }
        catch (err) {

            
        }
    }
}