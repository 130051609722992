import { Button, Modal } from "react-bootstrap"
import { RemoveErrProduct } from "../../application/action"

const ErrorImageModalProduct = ({errImage, setErrImage, t}) => {
    return(
        <Modal show={errImage} centered onHide={() => setErrImage(false)}>
          <Modal.Body>
            <div>
              {t.merchant.err.processImage}
            </div>
          </Modal.Body>
          <Modal.Footer style={{ border: 0 }}>
            <Button
              variant="secondary"
              onClick={() => setErrImage(false)}
            >
                 {t.merchant.btn.close}
            </Button>
          </Modal.Footer>
        </Modal>
    )
}

export default ErrorImageModalProduct