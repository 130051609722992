import './loading.css'
import bell from '../../assets/component/Outlinebell.svg';

const LoadingError = props => {
    return (
        <div className="loading-wrapper">
            <div className="loading-content-error">
                <div className="loading-content-icon">
                    <img src={bell} />
                </div>                
                {/* <div className="loading-content-text loading-content-text-title">
                    <span>
                        Alert!
                    </span>
                </div> */}
                <div className="loading-content-text">
                    <span className='loading-content-text-center'>
                        Sorry. There's something wrong, please try again later
                    </span>
                </div>
                <div className="loading-content-button" onClick={() => props.setLoadingError(false)}>
                    <button>Close</button>
                </div>
            </div>
        </div>
    )
}

export default LoadingError