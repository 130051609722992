const En = {
  languageModal: {
    textTitle: "Sila pilih bahasa dan negara",
    textChooseCity: "Pilih bandar",
    search: "Cari",
  },
  navbar: {
    textItem1: "TUTORIAL",
    textItem2: "KENALAN",
    textButton: "Daftar sekarang",
    textRegis: "Pendaftaran",
    textRegistration: "Bagaimana cara untuk saya menambah barang",
    textProcess: "Bagaimana cara untuk saya memproses tempahan",
    textOrders: "(Untuk pelanggan) Bagaimana cara untuk saya membuat tempahan",
    textComplete: "Bagaimana cara untuk saya melengkapkan tempahan",
    textSm: "IKUTI KAMI DI MEDIA SOSIAL",
    textSm1:
      "Terima tip dan cadangan mengenai cara untuk menggunakan apl dan perkhidmatan",
  },
  footer: {
    text: "2022 Maxim - hak cipta terpelihara",
    textPrivacy: "Dasar Privasi",
  },
  qna: {
    textTitle: "Soalan lazim",
    textContent1: "Bolehkah saya mendaftar lebih daripada seorang peniaga?",
    textContent2: "Berapakah yuran komisen anda?",
    textContent3: "Siapakah yang membayar peniaga?",
    textContent4: "Bagaimanakah untuk saya membuka kedai dalam apl?",
    textContent5: "Bagaimanakah untuk saya memadam item dalam apl?",
    textContent6: "Bagaimana cara untuk saya mengubah urutan kategori?",
    textSubcontent1:
      "Anda boleh, tetapi anda harus menggunakan alamat e-mel yang berbeza untuk setiap pedagang yang anda daftarkan.",
    textSubcontent2:
      "Pada masa ini, anda boleh menjana wang dengan Perkhidmatan kami tanpa komisen. Anda boleh menentukan harga hidangan seperti dalam menu.",
    textSubcontent3:
      "Pemandu membayar tempahan dengan wang mereka sendiri, dan kemudian menerima bayaran daripada pelanggan.",
    textSubcontent4:
      "Klik Buka jika anda bersedia untuk menerima tempahan dan klik Tutup selepas anda selesai bekerja.",
    textSubcontent5:
      "Anda tidak boleh berbuat demikian buat masa ini. Hanya nyahaktifkan item anda atau gantikan dengan yang lain. ",
    textSubcontent6:
      "Kategori diisih daripada yang paling lama kepada yang terbaharu dan anda tidak boleh menukar urutannya secara manual.",
  },
  guides: {
    register: {
      textStep1:
        "Isikan maklumat anda: masukkan kata laluan yang akan anda gunakan untuk log masuk, serta nombor telefon kenalan anda.",
      textStep2: "Nyatakan alamat kafe atau tempat penghantaran anda.",
      textStep3: "Muat naik logo syarikat anda atau gambar kedai anda.",
      textStep4:
        "Semua selesai! Anda kini boleh meneruskan untuk memuat naik barangan anda.",
    },
    addProduct: {
      textStep1:
        "Untuk bermula, buat kategori produk, contohnya, Makanan Ringan. Anda boleh menambah seberapa banyak kategori yang anda perlukan.",
      textStep2:
        "Klik “+” di sebelah nama kategori untuk menambahkan produk anda. ",
      textStep3_1:
        "Masukkan maklumat tentang produk anda, nyatakan diskaun, jika anda ingin memberikannya. Diskaun dan gambar berkualiti tinggi akan menyerlahkan barangan anda.",
      textStep3_2:
        "Jika anda menandai produk anda sebagai Disyorkan, produk itu akan dipaparkan antara yang pertama di kedai anda.",
    },
    processOrder: {
      textStep1:
        "Klik Buka untuk mula bekerja. Apabila ada tempahan baharu, anda akan menerima pemberitahuan.",
      textStep2_1:
        'Klik "Sahkan tempahan" untuk mula menerimanya atau "Batalkan tempahan" jika, sebagai contoh, produk anda kehabisan stok buat masa ini.',
      textStep2_2:
        "Jika anda tidak mengesahkan dalam masa tiga minit, tempahan tersebut akan dibatalkan secara automatik.",
      textStep3:
        "Kami mengesyorkan agar anda menunggu rakan penghantar sebelum mula menyediakan tempahan.",
    },
    placeOrder: {
      textStep1:
        'Pelanggan memilih kadar Makanan&Barang dan mengetik "Apa yang hendak dibeli"',
      textStep2:
        "Mereka boleh memilih kedai daripada senarai atau mencari produk tertentu.",
      textStep3: "Barang yang anda tandai sebagai Disyorkan didahulukan.",
      textStep4:
        "Pelanggan boleh menentukan bilangan barangan, dan kos akan dikira semula secara automatik.",
      textStep5:
        "Apl ini mempunyai semua ciri untuk kemudahan pelanggan, seperti menempah untuk orang lain atau masa penghantaran yang selesa.",
      textStep6:
        "Pelanggan boleh menjejaki rakan penghantar dengan menempah mereka pada peta dalam talian.",
    },
  },
  home: {
    card1: {
      textTitle: "Jual barang-barang anda dengan Maxim",
      textContent:
        "Dapatkan akses kepada orang ramai yang aktif dan membayar tanpa kos tambahan.",
      textButton: "Daftar sekarang",
    },
    card2: {
      textTitle: "Dapatkan ramai pelanggan",
      textContent1:
        "Siarkan barangan anda untuk dilihat oleh beribu-ribu pengguna apl Maxim.",
      textContent2: "Lihat semua kelebihan bekerjasama dengan kami.",
      textContentTitle1: "Tanpa komisen",
      textContentTitle2: "Penghantaran cepat",
      textContentTitle3: "Pengiklanan percuma",
      textSubcontent1:
        "Kami tidak mengenakan peratusan jualan — menerima semua pendapatan daripada menjual barangan dan menetapkan harga seperti di menu untuk menarik pelanggan.",
      textSubcontent2:
        "Tempahan anda akan dihantar oleh rakan penghantar yang paling hampir dengan tempat pengambilan. Penghantaran ditanggung oleh pelanggan. ",
      textSubcontent3:
        "Kami akan membuat sepanduk bersama untuk perniagaan anda dan menyiarkan maklumat tentang syarikat anda dalam apl kami untuk pemandu dan pelanggan.",
    },
    card3: {
      textTitle: "Terima tempahan dalam apl yang mudah",
      textContent1:
        "Tambah seberapa banyak cawangan yang anda perlukan untuk satu jenama. ",
      textContent2:
        "Cipta mana-mana kategori yang anda mahu. Contohnya, minuman, sup, hidangan kedua, salad, dll. ",
      textContent3:
        "Muat naik gambar yang menarik untuk menonjol daripada perniagaan lain. ",
      textContent4:
        "Tetapkan diskaun untuk menarik perhatian lebih ramai pelanggan.",
      textContent5:
        "Analisis statistik pembelian untuk menawarkan item yang lebih popular.",
    },
    card4: {
      textTitle: "Mula sekarang!",
      textContent1: "Daftar",
      textContent2: "Muat turun aplikasi kami",
      textContent3: "Tambah barang anda",
      textContent4: "Tunggu pelanggan pertama anda!",
      textButton: "Daftar sekarang",
    },
    card5: {
      textTitle: "Pengiklanan percuma luar talian",
      textContent1:
        "Jadikan perniagaan anda menonjol. Kami akan mereka bentuk susun atur dan membuat sepanduk bersama untuk anda, secara percuma. Ia akan membantu anda menarik pelanggan dan memberitahu mereka tentang perkhidmatan tempahan dalam talian anda. ",
      textContent2:
        "Menempah sepanduk adalahtersedia untuk peniaga berdaftar. Hubungi pejabat kami.",
      textButton: "Lihat alamat pejabat",
    },
    card6: {
      textTitle: "Panduan terperinci",
      textContent1: "Ketahui cara menggunakan perkhidmatan kami.",
      textSubcontent1: "Bagaimana untuk mendaftar",
      textSubcontent2: "Bagaimana untuk saya menambah barang",
      textSubcontent3: "Bagaimana cara untuk saya memproses tempahan",
      textSubcontent4:
        "(Untuk pelanggan) Bagaimana cara untuk saya membuat tempahan",
      textSubcontent5:
        "(Untuk rakan penghantar) Bagaimana untuk menghantar tempahan",
    },
  },
  contact: {
    card1: {
      textTitle: "Pejabat",
      textContent:
        "Jl. KH. Noer Ali No. 192, Kayuringin Jaya, Kec. Bekasi Sel., Kota Bks, West Java 17144",
      textPhone: "+ 6221-5092-8720",
    },
  },
  addgoods: {
    card1: {
      textTitle:
        "Bagaimana cara untuk saya menambah barang anda dalam apl MaximMerchant",
      textContent1:
        'Di tab ITEM, pilih KATEGORI, klik "+" dan buat kategori baharu.',
      textContent2: "Buat seberapa banyak kategori yang anda perlukan.",
      textContent3: 'Di tab ITEM, pilih MENU, klik "+".',
      textContent4_0:
        "Isi semua ruang. Jika anda mahu barangan anda dipaparkan pada halaman utama pedagang, aktifkan pengesyoran. Dalam penerangan, anda boleh menentukan nama item.",
      textContent4_1:
        "Togol suis untuk mengaktifkan item. Kini, pelanggan akan melihatnya dalam apl mereka. Pada ketika ini, anda tidak boleh memadamkan item, tetapi anda boleh menyahaktifkannya atau menggantikannya dengan sesuatu yang lain.",
      textContent4_2:
        "Klik BUKA apabila anda bersedia untuk mengambil tempahan. Selepas anda habis bekerja, pastikan anda klik TUTUP.",
    },
    card2: {
      textTitle: "Bersedia untuk memulakan?",
      textContent: "Daftar dan mula mempromosikan perniagaan anda dengan kami!",
      textButton: "Pendaftaran",
    },
  },
  merchant: {
    product: {
      labelShowCustomer: "Tunjuk pada pelanggan",
      labelName: "Nama produk",
      labelDesc: "Penerangan",
      labelCat: "Kategori",
      labelPrice: "Harga",
      labelDisc: "Diskaun",
      labelDiscAmount: "Jumlah Diskaun",
      labelRecommend: "Disyorkan",
      labelChoosePhoto: "Pilih gambar",
      labelSaveAdd: "Simpan barang",
      labelSaveEdit: "Simpan perubahan",
      labelRequired: "Diperlukan",
      labelNotRequired: "Tidak diperlukan",
      LabelCancelEdit: "Batal",
      LabelVariationAttach: "Variasi dilampirkan",
    },
    btn: {
      pic: "Pilih gambar",
      submit: "Hantar",
      close: "Tutup",
      add_more: "Tambah lagi",
      schedule: "Tambah jadual baharu",
      edit: "Ubah",
      delete: "Padam",
      variationAttach:
        "Klik disini untuk melihat variasi yang dilampirkan pada item ini",
    },
    schedule: {
      text: {
        title: "Waktu bekerja",
        add: { title: "Tambah jadual" },
        edit: { title: "Ubah jadual" },
        delete: {
          title: "Padam jadual",
          alert: "Adakah anda pasti mahu memadamkan data?",
        },
      },
      day: {
        sunday: "Ahad",
        monday: "Isnin",
        tuesday: "Selasa",
        wednesday: "Rabu",
        thursday: "Khamis",
        friday: "Jumaat",
        saturday: "Sabtu",
      },
      form: {
        day: "Hari",
        open: "Waktu dibuka",
        close: "Waktu ditutup",
      },
    },
    err: {
      pic_fail: `Tidak dapat memuat naik gambar. Sila guna gambar yang lain`,
      empty: `Borang tidak boleh kosong`,
      no_pic: "Pilih gambar untuk dimuat naik",
      get: "Ralat ketika mendapatkan maklumat",
      no_data: "Tiada maklumat",
      add: "Ralat ketika menambahkan maklumat",
      edit: "Ralat ketika mengubah maklumat",
      delete: "Ralat ketika memadamkan maklumat",
      categoryFetch: "Gagal mendapatkan kategori, sila cuba log masuk semula",
      variationFetch: "Gagal mendapatkan variasi, sila cuba log masuk semula",
      productFetch: "Gagal mendapatkan produk, sila cuba log masuk semula",
      connectedFetch:
        "Gagal mendapatkan variasi yang dilampirkan, sila cuba log masuk semula",
      processImage:
        "Gagal memproses imej, sila cuba lagi atau cuba muat naik imej lain",
    },
  },
  register: {
    err: {
      title: "Ralat!",
      no_pic: "Pilih gambar untuk dimuat naik",
      sent: "Ralat ketika menghantar data ke pelayan",
      email: "E-mel telah didaftarkan",
      empty: `Borang tidak boleh kosong`,
      no_loc:
        "Pilih lokasi anda daripada peta (klik peta dua kali untuk mendapatkan lokasi semasa anda atau alihkan penunjuk ke lokasi anda)",
      required: "Ruang wajib diisi",
      email_not_valid: "Emel tidak sah",
      pass_not_strong:
        "Kata laluan tidak kuat. Gunakan sekurang-kurangnya 7 aksara dan cuba gabungkan huruf besar, huruf kecil, nombor dan aksara khas (!@#$%^&*_+=-)",
      pass_strong: "Kata laluan kuat",
      pass_perfect: "Kata laluan sempurna",
    },
    btn: {
      close: "Tutup",
      continue: "Sambung",
      back: "Langkah sebelumnya",
      next: "Langkah seterusnya",
      register: "Daftar",
      photo: "Pilih gambar",
    },
    text: {
      step: {
        first: "Langkah 1 dari 3",
        second: "Langkah 2 dari 3",
        third: "Langkah 3 dari 3",
        done: "Selesai",
      },
      title: {
        merchant_info: "Maklumat peniaga",
        merchant_location: "Lokasi kedai",
        merchant_picture: "Gambar kedai",
      },
      note: {
        registe_image:
          "Anda boleh memuat naik logo atau gambar produk utama anda.",
        agreement: "Dengan menekan Daftar, saya bersetuju untuk",
        tnc: "Terma dan syarat",
        done: "Pendaftaran berjaya. Untuk memulakan kerja, anda patut menunggu sehingga kami mengesahkan akaun yang mungkin mengambil beberapa hari. Kami akan menghubungi anda atau menghantar e-mel kepada anda.",
        doneAndDownload:
          "Sementara itu, anda boleh bersedia untuk menjual barang anda dengan memuat turun aplikasi kami dan menambahkannya di sana.",
      },
    },
    form: {
      phone: "Nombor telefon",
      name: "Nama kedai",
      email: "E-mel",
      desc: "Penerangan",
      password: "Kata laluan",
      referral: "Rujukan (jika ada)",
      country: "Negara",
      city: "Bandar",
      district: "Daerah",
      address: "Alamat",
    },
    question: {
      any: "Sebarang pertanyaan?",
      check: "Lihat tutorial kami.",
      button: "Lihat tutorial",
    },
  },
  webView: {
    complete_add_title: "TAMBAHAN PRODUK BERJAYA",
    complete_add_description:
      "Produk ditambah. Anda boleh menambah lebih banyak atau anda boleh menutup halaman ini.",
    complete_edit_title: "PENYUNTINGAN PRODUK BERJAYA",
    complete_edit_description:
      "Produk telah disunting. Anda boleh menutup halaman ini.",
    imageUploadError: "File gambar terlalu besar! Maksimum 5 MB.",
  },
};
export default En;
