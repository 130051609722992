const En = {
  languageModal: {
    textTitle: "กรุณาเลือกภาษาและประเทศของคุณ",
    textChooseCity: "เลือกเมือง",
    search: "ค้นหา",
  },
  navbar: {
    textItem1: "การสอน",
    textItem2: "ติดต่อ",
    textButton: "ลงทะเบียนตอนนี้",
    textRegis: "ลงทะเบียน",
    textRegistration: "วิธีเพิ่มสินค้าของคุณ",
    textProcess: "วิธีสั่งออเดอร์",
    textOrders: "(สำหรับลูกค้า) วิธีการสั่งซื้อ",
    textComplete: "วิธีดำเนินการออเดอร์",
    textSm: "ติดตามเราบนโซเชียลมีเดีย",
    textSm1: "รับเคล็ดลับและคำแนะนำเกี่ยวกับวิธีใช้แอพและบริการ",
  },
  footer: {
    text: "2022 Maxim - สงวนลิขสิทธิ์",
    textPrivacy: "นโยบายความเป็นส่วนตัว",
  },
  qna: {
    textTitle: "คำถามที่พบบ่อย",
    textContent1: "ฉันสามารถลงทะเบียนร้านค้ามากกว่าหนึ่งร้านได้หรือไม่?",
    textContent2: "ค่าคอมมิชชั่นเท่าไหร่?",
    textContent3: "ใครเป็นคนจ่ายให้คนขาย?",
    textContent4: "ฉันจะเปิดร้านค้าบนแอพได้อย่างไร?",
    textContent5: "ฉันจะลบรายการของฉันบนแอพได้อย่างไร?",
    textContent6: "ฉันจะเปลี่ยนลำดับของหมวดหมู่ได้อย่างไร?",
    textSubcontent1:
      "คุณสามารถทำได้ แต่คุณต้องใช้อีเมลอื่นสำหรับผู้ขายแต่ละรายที่คุณลงทะเบียน",
    textSubcontent2:
      "ขณะนี้ คุณสามารถสร้างรายได้จากค่าคอมมิชชั่นบริการของเรา สามารถระบุราคาอาหารได้เหมือนในเมนู",
    textSubcontent3:
      "คนขับชำระเงินสำหรับออเดอร์ด้วยเงินตัวเองจากนั้นจึงได้รับเงินจากลูกค้า",
    textSubcontent4:
      "แตะเปิดหากคุณพร้อมที่จะรับออเดอร์และแตะปิดหลังจากที่คุณทำงานเสร็จแล้ว",
    textSubcontent5:
      "คุณไม่สามารถทำได้ในตอนนี้ เพียงปิดใช้งานรายการของคุณหรือแทนที่ด้วยรายการอื่น ",
    textSubcontent6:
      "หมวดหมู่จะเรียงลำดับจากเก่าสุดไปหาใหม่ที่สุด และคุณไม่สามารถเปลี่ยนลำดับด้วยตนเองได้",
  },
  guides: {
    register: {
      textStep1:
        "กรอกข้อมูลของคุณ: ใส่รหัสผ่านที่คุณจะใช้ในการเข้าสู่ระบบ รวมทั้งหมายเลขโทรศัพท์ที่ติดต่อได้",
      textStep2: "ระบุที่อยู่ร้านกาแฟหรือจุดจัดส่งของคุณ",
      textStep3: "อัปโหลดโลโก้ของบริษัทหรือรูปภาพร้านค้าของคุณ",
      textStep4: "เรียบร้อยแล้ว! ตอนนี้คุณสามารถอัปโหลดสินค้าของคุณได้",
    },
    addProduct: {
      textStep1:
        "เริ่มจากสร้างหมวดหมู่สินค้า เช่น อาหารว่าง คุณสามารถเพิ่มหมวดหมู่ได้มากเท่าที่คุณต้องการ",
      textStep2: 'กด "+" ที่อยู่ถัดจากชื่อหมวดหมู่เพื่อเพิ่มสินค้าของคุณ ',
      textStep3_1:
        "ใส่ข้อมูลเกี่ยวกับผลิตภัณฑ์ของคุณ ระบุส่วนลดหากคุณต้องการให้ ส่วนลดและภาพถ่ายคุณภาพสูงจะทำให้สินค้าของคุณโดดเด่น",
      textStep3_2:
        "หากคุณทำเครื่องหมายสินค้าของคุณเป็นสินค้าแนะนำ สินค้านั้นจะแสดงเป็นรายการแรกในร้านค้าของคุณ",
    },
    processOrder: {
      textStep1:
        "กดเปิดเพื่อเริ่มทำงาน คุณจะได้รับการแจ้งเตือนเมื่อมีออเดอร์ใหม่",
      textStep2_1:
        'กด "ยืนยันออเดอร์" เพื่อเริ่มทำงาน หรือกด "ยกเลิกออเดอร์" เช่น หากสินค้าของคุณหมดสต๊อกชั่วคราว',
      textStep2_2:
        "ออเดอร์จะถูกยกเลิกโดยอัตโนมัติหากคุณไม่ยืนยันออเดอร์ภายในสามนาที",
      textStep3: "เราขอแนะนำให้คุณรอผู้จัดส่งก่อนที่จะเริ่มเตรียมออเดอร์",
    },
    placeOrder: {
      textStep1: 'ลูกค้าเลือกเรทราคาอาหารและสินค้า แล้วแตะ "จะซื้ออะไรดี"',
      textStep2: "ลูกค้าสามารถเลือกร้านค้าจากรายการหรือค้นหาเพื่อระบุสินค้า",
      textStep3: "สินค้าที่คุณทำเครื่องหมายว่าแนะนำจะแสดงให้เห็นก่อน",
      textStep4:
        "ลูกค้าสามารถระบุจำนวนสินค้าได้และราคาจะถูกคำนวณใหม่โดยอัตโนมัติ",
      textStep5:
        "แอพมีฟีเจอร์หลากหลายเพื่อความสะดวกของลูกค้า เช่น สั่งซื้อให้บุคคลอื่นหรือเวลาจัดส่งตามสะดวก",
      textStep6: "ลูกค้าสามารถติดตามผู้จัดส่งจากออเดอร์ของตนเองบนแผนที่ออนไลน์",
    },
  },
  home: {
    card1: {
      textTitle: "ขายสินค้าของคุณกับ Maxim",
      textContent:
        "เข้าถึงลูกค้าที่ใช้งานและชำระเงินโดยไม่มีค่าใช้จ่ายเพิ่มเติม",
      textButton: "ลงทะเบียนตอนนี้",
    },
    card2: {
      textTitle: "รับลูกค้ามากขึ้น",
      textContent1: "โพสต์สินค้าของคุณให้ผู้ใช้แอป Maxim หลายพันคนดู",
      textContent2: "ดูข้อดีทั้งหมดของการร่วมมือกับเรา",
      textContentTitle1: "ค่าคอมมิชชั่น",
      textContentTitle2: "ส่งเร็ว",
      textContentTitle3: "โฆษณาฟรี",
      textSubcontent1:
        "เราไม่คิดเปอร์เซ็นต์ของยอดขาย — รับรายได้จากการขายสินค้าทั้งหมดและตั้งราคาเหมือนในเมนูเพื่อดึงดูดลูกค้า",
      textSubcontent2:
        "ออเดอร์ของคุณจะถูกจัดส่งโดยผู้จัดส่งที่ใกล้กับจุดรับสินค้ามากที่สุด ลูกค้าเป็นผู้ชำระค่าจัดส่ง ",
      textSubcontent3:
        "เราจะสร้างแบนเนอร์ร่วมกันสำหรับธุรกิจของคุณและโพสต์ข้อมูลเกี่ยวกับบริษัทของคุณในแอพของเราสำหรับคนขับและลูกค้า",
    },
    card3: {
      textTitle: "รับออเดอร์ง่ายๆด้วยแอพที่สะดวก",
      textContent1: "เพิ่มร้านค้าสำหรับแบรนด์เดียวได้มากเท่าที่คุณต้องการ ",
      textContent2:
        "สร้างหมวดหมู่ที่คุณต้องการ เช่น เครื่องดื่ม, ซุป, อาหาร, สลัด ฯลฯ ",
      textContent3: "อัปโหลดรูปภาพที่น่าสนใจเพื่อให้โดดเด่นกว่าธุรกิจอื่นๆ ",
      textContent4: "จัดส่วนลดเพื่อดึงดูดลูกค้ามากขึ้น",
      textContent5: "วิเคราะห์สถิติการซื้อเพื่อเสนอสินค้ายอดนิยม",
    },
    card4: {
      textTitle: "เริ่มเลย!",
      textContent1: "ลงทะเบียน",
      textContent2: "ดาวน์โหลดแอพพลิเคชั่น",
      textContent3: "เพิ่มสินค้า",
      textContent4: "รอลูกค้ารายแรกของคุณ!",
      textButton: "ลงทะเบียนตอนนี้",
    },
    card5: {
      textTitle: "โฆษณาออฟไลน์ฟรี",
      textContent1:
        "ทำให้ธุรกิจของคุณโดดเด่น เราจะออกแบบเลย์เอาต์และสร้างแบนเนอร์ร่วมกันให้คุณโดยไม่คิดค่าใช้จ่าย ซึ่งจะช่วยให้คุณดึงดูดลูกค้าและบอกพวกเขาเกี่ยวกับบริการสั่งซื้อออนไลน์ของคุณ ",
      textContent2:
        "ร้านค้าที่ลงทะเบียนสามารถซื้อแบนเนอร์ได้ ติดต่อหนึ่งในสำนักงานของเรา",
      textButton: "ดูที่อยู่สำนักงาน",
    },
    card6: {
      textTitle: "คำแนะนำโดยละเอียด",
      textContent1: "เรียนรู้วิธีการใช้บริการของเรา",
      textSubcontent1: "วิธีลงทะเบียน",
      textSubcontent2: "วิธีเพิ่มสินค้า",
      textSubcontent3: "วิธีสั่งออเดอร์",
      textSubcontent4: "(สำหรับลูกค้า) วิธีการสั่งซื้อ",
      textSubcontent5: "(สำหรับเคอรี่) วิธีการส่งออเดอร์",
    },
  },
  contact: {
    card1: {
      textTitle: "สำนักงาน",
      textContent:
        "Jl. KH. Noer Ali No. 192, Kayuringin Jaya, Kec. Bekasi Sel., Kota Bks, West Java 17144",
      textPhone: "+ 6221-5092-8720",
    },
  },
  addgoods: {
    card1: {
      textTitle: "วิธีเพิ่มสินค้าของคุณในแอพ MaximMerchant",
      textContent1: 'เลือกหมวดหมู่ที่แท็บ "รายการ" กด "+" และสร้างหมวดหมู่ใหม่',
      textContent2: "สร้างหมวดหมู่ได้มากเท่าที่คุณต้องการ",
      textContent3: 'เลือกเมนูและกด "+" ที่แท็บ "รายการ"',
      textContent4_0:
        "กรอกข้อมูลในช่องทั้งหมด หากคุณต้องการให้สินค้าของคุณแสดงในหน้าแรกของร้านค้าให้เปิดใช้งานคำแนะนำ คุณสามารถระบุชื่อรายการได้ในคำอธิบาย",
      textContent4_1:
        "สลับสวิตช์เพื่อเปิดใช้งานรายการและลูกค้าจะเห็นในแอป คุณไม่สามารถลบรายการของคุณได้ แต่คุณสามารถปิดใช้งานหรือแทนที่ด้วยรายการอื่นได้",
      textContent4_2:
        "กดเปิดเมื่อคุณพร้อมที่จะเริ่มรับออเดอร์ หลังจากที่คุณทำงานเสร็จแล้วอย่าลืมกดปิด",
    },
    card2: {
      textTitle: "พร้อมที่จะเริ่มหรือยัง?",
      textContent: "ลงทะเบียนและเริ่มโปรโมทธุรกิจของคุณกับเรา!",
      textButton: "ลงทะเบียน",
    },
  },
  merchant: {
    product: {
      labelShowCustomer: "แสดงให้ลูกค้าเห็น",
      labelName: "ชื่อสินค้า",
      labelDesc: "รายละเอียด",
      labelCat: "หมวดหมู่",
      labelPrice: "ราคา",
      labelDisc: "ส่วนลด",
      labelDiscAmount: "จำนวนส่วนลด",
      labelRecommend: "แนะนำ",
      labelChoosePhoto: "เลือกรูป",
      labelSaveAdd: "บันทึกสินค้า",
      labelSaveEdit: "บันทึกการเปลี่ยนแปลง",
      labelRequired: "จำเป็น",
      labelNotRequired: "ไม่จำเป็น",
      LabelCancelEdit: "ยกเลิก",
      LabelVariationAttach: "แนบการเปลี่ยนแปลงแล้ว",
    },
    btn: {
      pic: "เลือกรูป",
      submit: "ส่ง",
      close: "ปิด",
      add_more: "เพิ่ม",
      schedule: "เพิ่มตารางเวลาใหม่",
      edit: "แก้ไข",
      delete: "ลบ",
      variationAttach:
        "คลิกที่นี่เพื่อดูการเปลี่ยนแปลงที่แนบมาพร้อมกับรายการนี้",
    },
    schedule: {
      text: {
        title: "เวลาเปิด",
        add: { title: "เพิ่มตารางเวลา" },
        edit: { title: "แก้ไขตารางเวลา" },
        delete: {
          title: "ลบเพิ่มตารางเวลา",
          alert: "คุณแน่ใจหรือว่าต้องการลบข้อมูล?",
        },
      },
      day: {
        sunday: "วันอาทิตย์",
        monday: "วันจันทร์",
        tuesday: "วันอังคาร",
        wednesday: "วันพุธ",
        thursday: "วันพฤหัสบดี",
        friday: "วันศุกร์",
        saturday: "วันเสาร์",
      },
      form: {
        day: "วัน",
        open: "เวลาเปิด",
        close: "เวลาปิด",
      },
    },
    err: {
      pic_fail: `ไม่สามารถอัพโหลดรูปภาพได้ กรุณาใช้ภาพอื่น`,
      empty: `ต้องใส่ข้อมูลในแบบฟอร์ม`,
      no_pic: "เลือกรูปภาพที่จะอัปโหลด",
      get: "เกิดข้อผิดพลาดขณะรับข้อมูล",
      no_data: "ไม่มีข้อมูล",
      add: "เกิดข้อผิดพลาดขณะเพิ่มข้อมูล",
      edit: "เกิดข้อผิดพลาดขณะแก้ไขข้อมูล",
      delete: "เกิดข้อผิดพลาดขณะลบข้อมูล",
      categoryFetch: "ดึงข้อมูลหมวดหมู่ไม่สำเร็จ กรุณาลองล็อกอินอีกครั้ง",
      variationFetch:
        "ดึงข้อมูลการเปลี่ยนแปลงไม่สำเร็จ กรุณาลองล็อกอินอีกครั้ง",
      productFetch: "ดึงข้อมูลผลิตภัณฑ์ไม่สำเร็จ กรุณาลองล็อกอินอีกครั้ง",
      connectedFetch:
        "ดึงข้อมูลการเปลี่ยนแปลงที่แนบไว้ไม่สำเร็จ กรุณาลองล็อกอินอีกครั้ง",
      processImage:
        "อัพโหลดรูปภาพไม่สำเร็จ กรุณาลองอีกครั้งหรือลองอัพโหลดรูปอื่น",
    },
  },
  register: {
    err: {
      title: "เกิดข้อผิดพลาด!",
      no_pic: "เลือกรูปภาพที่จะอัปโหลด",
      sent: "เกิดข้อผิดพลาดขณะส่งข้อมูลไปยังเซิร์ฟเวอร์",
      email: "อีเมลนี้ลงทะเบียนแล้ว",
      empty: `ต้องใส่ข้อมูลในแบบฟอร์ม`,
      no_loc:
        "เลือกตำแหน่งของคุณจากแผนที่ (คลิกแผนที่สองครั้งเพื่อรับตำแหน่งปัจจุบันของคุณหรือย้ายหมุดไปยังตำแหน่งของคุณ)",
      required: "ต้องกรอก",
      email_not_valid: "อีเมลไม่ถูกต้อง",
      pass_not_strong:
        "รหัสผ่านไม่ปลอดภัย ใช้ตัวอักษรอย่างน้อย 7 ตัวและใช้ตัวพิมพ์ใหญ่, ตัวพิมพ์เล็ก, ตัวเลข, และอักขระพิเศษ (!@#$%^&*_+=-)",
      pass_strong: "รหัสผ่านปลอดภัย",
      pass_perfect: "รหัสผ่านดีเยี่ยม",
    },
    btn: {
      close: "ปิด",
      continue: "ต่อไป",
      back: "ขั้นตอนก่อนหน้า",
      next: "ขั้นตอนต่อไป",
      register: "ลงทะเบียน",
      photo: "เลือกรูป",
    },
    text: {
      step: {
        first: "ขั้นตอนที่ 1 จาก 3",
        second: "ขั้นตอนที่ 2 จาก 3",
        third: "ขั้นตอนที่ 3 จาก 3",
        done: "เสร็จสิ้น",
      },
      title: {
        merchant_info: "ข้อมูลร้านค้า",
        merchant_location: "ที่อยู่ร้านค้า",
        merchant_picture: "รูปถ่ายร้านค้า",
      },
      note: {
        registe_image:
          "คุณสามารถอัปโหลดโลโก้หรือรูปภาพของผลิตภัณฑ์หลักของคุณได้",
        agreement: "โดยการคลิกลงทะเบียนข้าพเจ้ายอมรับ",
        tnc: "ข้อกำหนดและเงื่อนไข",
        done: "ลงทะเบียนสำเร็จ หากต้องการเริ่มงานคุณควรรอจนกว่าเราจะยืนยันบัญชีของคุณซึ่งโดยปกติจะใช้เวลาหลายวัน เราจะโทรหาคุณหรือส่งอีเมลถึงคุณ",
        doneAndDownload:
          "ในระหว่างนี้คุณสามารถเตรียมขายสินค้าของคุณได้โดยการดาวน์โหลดแอปของเราและเพิ่มสินค้า",
      },
    },
    form: {
      phone: "เบอร์โทรศัพท์",
      name: "ชื่อร้าน",
      email: "อีเมล",
      desc: "รายละเอียด",
      password: "รหัสผ่าน",
      referral: "ผู้แนะนำ (ถ้าคุณมี)",
      country: "ประเทศ",
      city: "เมือง",
      district: "เขต",
      address: "ที่อยู่",
    },
    question: {
      any: "มีคำถามอื่นๆหรือไม่?",
      check: "ดูวิดีโอสอนของเรา",
      button: "ดูวิดีโอสอน",
    },
  },
  webView: {
    complete_add_title: "เพิ่มสินค้าสำเร็จ",
    complete_add_description:
      "เพิ่มสินค้าแล้ว คุณสามารถเพิ่มเพิ่มเติมหรือคุณสามารถปิดหน้านี้ได้",
    complete_edit_title: "แก้ไขสินค้าสำเร็จ",
    complete_edit_description: "แก้ไขสินค้าแล้ว คุณสามารถปิดหน้านี้",
    imageUploadError: "ไฟล์รูปภาพใหญ่เกินไป! สูงสุด 5 MB.",
  },
};
export default En;
