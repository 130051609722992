import { React, useState } from 'react'
import { useMediaQuery } from 'react-responsive'

import { BigDesktop } from '../component/home/homePage/bigDesktop';
import { DesktopScreen } from '../component/home/homePage/smallDesktop'
import { MobileScreen } from '../component/home/homePage/mobileDesktop'

import { TopNavHome, TopNavMain } from '../index'

const HomePage = () => {
    const [toggle, setToggle] = useState(window.matchMedia("max-width: 639px").matches);
    const Desktop = useMediaQuery({ query: '(max-width:1235px)' })    
    const mobile1 = useMediaQuery({ query: '(max-width:743px)' })    

    return (
        <>
        <TopNavMain />
        {Desktop && mobile1 == false ? <DesktopScreen /> : Desktop && mobile1 == true ? <MobileScreen /> : <BigDesktop />}
        </>
    )
}

export default HomePage