import React from 'react'

import { t } from '../../../misc/utils'
import { Autocomplete, IconButton, TextField } from "@mui/material"
import MaterialUIButton from '@mui/material/Button'
import { Container, Card, Row, Button, Col, ProgressBar } from "react-bootstrap"
import { MapContainer, Marker, TileLayer, useMapEvents } from 'react-leaflet'
import L from "leaflet";
import "leaflet/dist/leaflet.css";

import { useMediaQuery } from 'react-responsive'
import './style.css'
// import { PositionMerchant } from '../../../../application/action'

import Android from '../../../assets/image/Badge_GooglePlay.svg'
import courierIMG from '../../../assets/image/img_courier.jpg'
// import imageplus from '../../../../assets/image/imageplus.png'
// import vector from '../../../../assets/image/Vector.png'
import PhoneInput from 'react-phone-input-2'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import Cropper  from 'react-easy-crop'

import ReactLoading from 'react-loading'
import { height, minWidth, width } from '@mui/system'

import ReactGA from 'react-ga4'

function LocationMarker({ dispatch, useSelector, PositionMerchant }) {
    // const dispatch = useDispatch()
    const [position, setPosition] = React.useState(null)
    const markerRef = React.useRef(null)
    const { lat, lon } = useSelector((state) => {
        return {
            lat: state.Register.lat,
            lon: state.Register.lon
        }
    })
    const defaultMarker = new L.icon({
        iconUrl: "https://unpkg.com/leaflet@1.4.0/dist/images/marker-icon.png",
        iconSize: [25, 41],
        iconAnchor: [13, 0]
    });
    const map = useMapEvents({
        dblclick() {
            map.locate()
        },
        locationfound(e) {
            setPosition(e.latlng)
            dispatch(PositionMerchant(e.latlng))
            map.flyTo(e.latlng, map.getZoom())
        },
    })
    React.useEffect(() => {
        map.flyTo({ lat: lat, lng: lon }, map.getZoom())
    }, [lat, lon])
    const eventHandlers = React.useMemo(
        () => ({
            dragend() {
                const marker = markerRef.current
                if (marker != null) {
                    setPosition(marker.getLatLng())
                    dispatch(PositionMerchant(marker.getLatLng()))
                }
            },
        }),
        [],
    )

    return position === null ? (
        <Marker
            draggable={true}
            eventHandlers={eventHandlers}
            position={{ lat: lat, lon: lon }}
            ref={markerRef}
            icon={defaultMarker}
        >
        </Marker>) : (
        <Marker
            draggable={true}
            eventHandlers={eventHandlers}
            position={{ lat: lat, lon: lon }}
            ref={markerRef}
            icon={defaultMarker}
        >
        </Marker>
    )
}

export const StepOne = ({ itemForm, handleItemform, invalidEmail, bar, handleSubmit }) => {
    const tablets = useMediaQuery(
        { maxWidth: 1235 },
        { minWidth: 744 }
    )
    const mobile = useMediaQuery(
        { maxWidth: 743 },
        { minWidth: 272 }
    )
    const [vis, setVis] = React.useState(false)
    console.log(bar);
    return (
        <>

            <div
                style={
                    mobile ? { width: 320, margin: 'auto', padding: 10 }
                        :
                        tablets ? { border: '1px solid #888888', borderRadius: 16, width: 680, padding: '56px 64px', margin: 'auto' }
                            :
                            { border: '1px solid #888888', borderRadius: 16, width: 1200, margin: 'auto', padding: '72px 56px' }}>
                <div>
                    <div style={{ marginLeft: 5 }}>
                        <p className='register-step'>{t.register.text.step.first}</p>
                        <h1 className='register-title'>{t.register.text.title.merchant_info}</h1>
                    </div>
                    <div style={tablets ? { display: 'grid', gridTemplateColumns: '100%' } : { display: 'grid', gridTemplateColumns: '50% 50%' }}>
                        {tablets ?
                            <>
                                <div>
                                    <PhoneInput
                                        inputProps={{}}
                                        value={itemForm.phone?.toString() || ''}
                                        onChange={(e) => handleItemform('phone', e)}
                                        placeholder={t.register.form.phone}
                                        autoFormat={false}
                                        country='id'
                                        containerStyle={mobile ? { marginTop: 10, marginLeft: 10, height: 55 } : { marginTop: 10, marginLeft: 10, height: 55, marginBottom: 15 }}
                                        inputClass={itemForm.phone?.toString().length <= 3 ? 'invalid-number' : ''}
                                        inputStyle={itemForm.phone?.toString().length <= 3 ? { height: mobile ? 54 : 64, width: mobile ? 272 : 408, marginRight: 50, borderColor: 'red' } : { height: mobile ? 54 : 64, width: mobile ? 272 : 408, marginRight: 50 }}
                                        countryCodeEditable={false}
                                        enableSearch={true}
                                        specialLabel={t.register.form.phone}

                                    />
                                    {itemForm?.phone?.toString().length <= 3 && <p className='text-error-register'>{t.register.err.required}</p>}
                                </div>
                                <div>
                                    <TextField
                                        className={mobile ? 'input-style-register-mobile size-font-16' : 'input-style-register size-font-20'}
                                        value={itemForm.email || ''}
                                        onChange={(e) => handleItemform('email', e.target.value)}
                                        style={{ marginLeft: 10, marginTop: 10, width: mobile ? 272 : 408, }}
                                        label={t.register.form.email}
                                        error={invalidEmail || itemForm.email?.length === 0}
                                        sx={itemForm.name?.length === 0 ? { borderColor: '#E31E24', color: '#262626' } : {}}
                                        inputProps={{ style: { height: mobile ? 27 : 32, borderColor: '#888888' } }}
                                        required={itemForm.email?.length === 0} />
                                    {itemForm.email?.length === 0 ? <p style={{ marginTop: 5 }} className='text-error-register'>{t.register.err.required}</p> : invalidEmail && <p style={{ marginTop: 5 }} className='text-error-register'>{t.register.err.email_not_valid}</p>}
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <TextField
                                        className={mobile ? 'input-style-register-mobile size-font-16' : 'input-style-register size-font-20'}
                                        InputProps={{
                                            endAdornment: vis ?
                                                <IconButton onClick={() => setVis(false)}>
                                                    <VisibilityOff />
                                                </IconButton>
                                                :
                                                <IconButton onClick={() => setVis(true)}>
                                                    <Visibility />
                                                </IconButton>
                                        }}
                                        type={vis ? 'text' : 'password'}
                                        value={itemForm.password || ''}
                                        onChange={(e) => handleItemform('password', e.target.value)}
                                        style={{ marginLeft: 10, marginTop: 15, width: mobile ? 272 : 408, }}
                                        label={t.register.form.password}
                                        sx={itemForm.name?.length === 0 ? { borderColor: '#E31E24', color: '#262626' } : {}}
                                        error={itemForm.password?.length === 0}
                                        required={itemForm.password?.length === 0}
                                        inputProps={{ style: { height: mobile ? 27 : 32, borderColor: '#888888' } }}
                                    />
                                    {itemForm.password ?
                                        <>
                                            {/* <ProgressBar
                                        style={{ width: mobile ? 272 : 408, marginLeft: 10, marginTop: 10, height: 15 }}
                                        striped
                                        animated
                                        variant={bar.percent < 40 ? 'danger' : bar.percent < 100 ? 'warning' : 'success'}
                                        now={bar.percent} /> */}
                                            <p style={{ marginLeft: 10, marginTop: 10, color: bar.percent < 70 ? '#E31E24' : bar.percent < 100 ? '#FFDD21' : '#5FD068', width: mobile ? 272 : 408, }} className='text-error-register'>{bar.percent < 70 ? t.register.err.pass_not_strong : bar.percent < 100 ? t.register.err.pass_strong : t.register.err.pass_perfect}</p>
                                        </>
                                        :
                                        itemForm.password?.length === 0 &&
                                        <>
                                            {/* <ProgressBar
                                        style={{ width: mobile ? 272 : 408, marginLeft: 10, marginTop: 10, height: 15 }}
                                        striped
                                        animated
                                        variant={bar.percent < 40 ? 'danger' : bar.percent < 100 ? 'warning' : 'success'}
                                        now={bar.percent} /> */}
                                            <p style={{ marginLeft: 10, marginTop: 10, }} className='text-error-register'>{t.register.err.required}</p>
                                        </>
                                    }
                                </div>
                                <div>
                                    <TextField
                                        className={mobile ? 'input-style-register-mobile size-font-16' : 'input-style-register size-font-20'}
                                        value={itemForm.name || ''}
                                        onChange={(e) => handleItemform('name', e.target.value)}
                                        style={{ marginLeft: 10, marginTop: 10, width: mobile ? 272 : 408, }}
                                        label={t.register.form.name}
                                        error={itemForm.name?.length === 0}
                                        inputProps={{ style: { height: mobile ? 27 : 32 } }}
                                        sx={itemForm.name?.length === 0 ? { borderColor: '#E31E24', color: '#262626' } : {}}
                                        required={itemForm.name?.length === 0}
                                    />
                                    {itemForm.name?.length === 0 && <p style={{ marginTop: 5 }} className='text-error-register'>{t.register.err.required}</p>}

                                </div>
                                {/* <div>
                                    <TextField
                                        className={mobile ? 'input-style-register-mobile size-font-16' : 'input-style-register size-font-20'}
                                        value={itemForm.desc || ''}
                                        onChange={(e) => handleItemform('desc', e.target.value)}
                                        style={{ marginLeft: 10, marginTop: 10, height: mobile ? 54 : 64, width: mobile ? 272 : 408, }}
                                        sx={itemForm.name?.length === 0 ? { borderColor: '#E31E24', color: '#262626' } : {}}
                                        label={t.register.form.desc}
                                        error={itemForm.desc?.length === 0}
                                        required={itemForm.desc?.length === 0}
                                        inputProps={{ style: { height: mobile ? 27 : 32, borderColor: '#888888' } }}
                                    />
                                    {itemForm.desc?.length === 0 && <p style={{ marginTop: 5 }} className='text-error-register'>{t.register.err.required}</p>}
                                </div> */}

                                <TextField
                                    value={itemForm.ref || ''}
                                    className={mobile ? 'input-style-register-mobile size-font-16' : 'input-style-register size-font-20'}
                                    onChange={(e) => handleItemform('ref', e.target.value)}
                                    style={{ marginLeft: 10, marginTop: 15, width: mobile ? 272 : 408, }}
                                    label={t.register.form.referral}
                                    inputProps={{ style: { height: mobile ? 27 : 32, borderColor: '#888888' } }}
                                    sx={itemForm.name?.length === 0 ? { borderColor: '#E31E24', color: '#262626' } : {}}
                                />
                                <br />
                                <button
                                    className={mobile ? 'input-style-register-mobile size-font-16' : 'input-style-register size-font-20'}
                                    onClick={() => handleSubmit(1)}
                                    disabled={(itemForm.name == '' || itemForm.name == null) || (itemForm.email == '' || invalidEmail || itemForm.email == null) || (itemForm.phone == '' || itemForm.phone == null) || (itemForm.password == '' || itemForm.password == null || bar.percent < 70) }
                                    style={{ marginLeft: 10, marginTop: 10, backgroundColor: '#FFDD21', borderRadius: 8, borderColor: 'transparent', height: mobile ? 54 : 58, width: mobile ? 272 : 408, }}
                                >{t.register.btn.continue}</button>
                            </> :
                            <>
                                <div>
                                    <PhoneInput
                                        inputProps={{}}
                                        value={itemForm.phone?.toString() || ''}
                                        onChange={(e) => handleItemform('phone', e)}
                                        placeholder={t.register.form.phone}
                                        autoFormat={false}
                                        country='id'
                                        containerStyle={mobile ? { marginTop: 10, marginLeft: 10, height: 55 } : { marginTop: 10, marginLeft: 10, height: 55, marginBottom: 15 }}
                                        inputClass={itemForm.phone?.toString().length <= 3 ? 'invalid-number' : ''}
                                        inputStyle={itemForm.phone?.toString().length <= 3 ? { height: mobile ? 54 : 64, width: mobile ? 272 : 408, marginRight: 50, borderColor: 'red' } : { height: mobile ? 54 : 64, width: mobile ? 272 : 408, marginRight: 50 }}
                                        countryCodeEditable={false}
                                        enableSearch={true}
                                        specialLabel={t.register.form.phone}

                                    />
                                    {itemForm?.phone?.toString().length <= 3 && <p className='text-error-register'>{t.register.err.required}</p>}
                                </div>
                                <div>
                                    <TextField
                                        className={mobile ? 'input-style-register-mobile size-font-16' : 'input-style-register size-font-20'}
                                        value={itemForm.name || ''}
                                        onChange={(e) => handleItemform('name', e.target.value)}
                                        style={{ marginLeft: 10, marginTop: 10, width: mobile ? 272 : 408, }}
                                        label={t.register.form.name}
                                        error={itemForm.name?.length === 0}
                                        inputProps={{ style: { height: mobile ? 27 : 32 } }}
                                        sx={itemForm.name?.length === 0 ? { borderColor: '#E31E24', color: '#262626' } : {}}
                                        required={itemForm.name?.length === 0}
                                    />
                                    {itemForm.name?.length === 0 && <p style={{ marginTop: 5 }} className='text-error-register'>{t.register.err.required}</p>}

                                </div>
                                <div>
                                    <TextField
                                        className={mobile ? 'input-style-register-mobile size-font-16' : 'input-style-register size-font-20'}
                                        value={itemForm.email || ''}
                                        onChange={(e) => handleItemform('email', e.target.value)}
                                        style={{ marginLeft: 10, marginTop: 10, width: mobile ? 272 : 408, }}
                                        label={t.register.form.email}
                                        error={invalidEmail || itemForm.email?.length === 0}
                                        sx={itemForm.name?.length === 0 ? { borderColor: '#E31E24', color: '#262626' } : {}}
                                        inputProps={{ style: { height: mobile ? 27 : 32, borderColor: '#888888' } }}
                                        required={itemForm.email?.length === 0} />
                                    {itemForm.email?.length === 0 ? <p style={{ marginTop: 5 }} className='text-error-register'>{t.register.err.required}</p> : invalidEmail && <p style={{ marginTop: 5 }} className='text-error-register'>{t.register.err.email_not_valid}</p>}
                                </div>
                                {/* <div>
                                    <TextField
                                        className={mobile ? 'input-style-register-mobile size-font-16' : 'input-style-register size-font-20'}
                                        value={itemForm.desc || ''}
                                        onChange={(e) => handleItemform('desc', e.target.value)}
                                        style={{ marginLeft: 10, marginTop: 10, height: mobile ? 54 : 64, width: mobile ? 272 : 408, }}
                                        sx={itemForm.name?.length === 0 ? { borderColor: '#E31E24', color: '#262626' } : {}}
                                        label={t.register.form.desc}
                                        error={itemForm.desc?.length === 0}
                                        required={itemForm.desc?.length === 0}
                                        inputProps={{ style: { height: mobile ? 27 : 32, borderColor: '#888888' } }}
                                    />
                                    {itemForm.desc?.length === 0 && <p style={{ marginTop: 5 }} className='text-error-register'>{t.register.err.required}</p>}
                                </div> */}

                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <TextField
                                        className={mobile ? 'input-style-register-mobile size-font-16' : 'input-style-register size-font-20'}
                                        InputProps={{
                                            endAdornment: vis ?
                                                <IconButton onClick={() => setVis(false)}>
                                                    <VisibilityOff />
                                                </IconButton>
                                                :
                                                <IconButton onClick={() => setVis(true)}>
                                                    <Visibility />
                                                </IconButton>
                                        }}
                                        type={vis ? 'text' : 'password'}
                                        value={itemForm.password || ''}
                                        onChange={(e) => handleItemform('password', e.target.value)}
                                        style={{ marginLeft: 10, marginTop: 15, width: mobile ? 272 : 408, }}
                                        label={t.register.form.password}
                                        sx={itemForm.name?.length === 0 ? { borderColor: '#E31E24', color: '#262626' } : {}}
                                        error={itemForm.password?.length === 0}
                                        required={itemForm.password?.length === 0}
                                        inputProps={{ style: { height: mobile ? 27 : 32, borderColor: '#888888' } }}
                                    />
                                    {itemForm.password ?
                                        <>
                                            {/* <ProgressBar
                                        style={{ width: mobile ? 272 : 408, marginLeft: 10, marginTop: 10, height: 15 }}
                                        striped
                                        animated
                                        variant={bar.percent < 40 ? 'danger' : bar.percent < 100 ? 'warning' : 'success'}
                                        now={bar.percent} /> */}
                                            <p style={{ marginLeft: 10, marginTop: 10,  width: mobile ? 272 : 408, color: bar.percent < 70 ? '#E31E24' : bar.percent < 100 ? '#FFDD21' : '#5FD068' }} className='text-error-register'>{bar.percent < 70 ? t.register.err.pass_not_strong : bar.percent < 100 ? t.register.err.pass_strong : t.register.err.pass_perfect}</p>
                                        </>
                                        :
                                        itemForm.password?.length === 0 &&
                                        <>
                                            {/* <ProgressBar
                                        style={{ width: mobile ? 272 : 408, marginLeft: 10, marginTop: 10, height: 15 }}
                                        striped
                                        animated
                                        variant={bar.percent < 40 ? 'danger' : bar.percent < 100 ? 'warning' : 'success'}
                                        now={bar.percent} /> */}
                                            <p style={{ marginLeft: 10, marginTop: 10, }} className='text-error-register'>{t.register.err.required}</p>
                                        </>
                                    }
                                </div>
                                <TextField
                                    value={itemForm.ref || ''}
                                    className={mobile ? 'input-style-register-mobile size-font-16' : 'input-style-register size-font-20'}
                                    onChange={(e) => handleItemform('ref', e.target.value)}
                                    style={{ marginLeft: 10, marginTop: 15, width: mobile ? 272 : 408, }}
                                    label={t.register.form.referral}
                                    inputProps={{ style: { height: mobile ? 27 : 32, borderColor: '#888888' } }}
                                    sx={itemForm.name?.length === 0 ? { borderColor: '#E31E24', color: '#262626' } : {}}
                                />
                                {/* <br /> */}
                                <button
                                    className={mobile ? 'input-style-register-mobile size-font-16' : 'input-style-register size-font-20'}
                                    onClick={() => handleSubmit(1)}
                                    disabled={(itemForm.name == '' || itemForm.name == null) || (itemForm.email == '' || invalidEmail || itemForm.email == null) || (itemForm.phone == '' || itemForm.phone == null) || (itemForm.password == '' || itemForm.password == null || bar.percent < 70)}
                                    style={{ marginLeft: 10, marginTop: 10, backgroundColor: '#FFDD21', borderRadius: 8, borderColor: 'transparent', height: mobile ? 54 : 64, width: mobile ? 272 : 408, }}
                                >{t.register.btn.continue}</button>
                            </>
                        }
                    </div>

                </div>

            </div>
        </>
    )
}

export const StepTwo = ({ cities, selectedCity, district, setSelectedCity, setSelectedDistrict, dispatch, useSelector, lat, lon, PositionMerchant, handleSubmit, itemForm, handleItemform, selectedDistrict, countries, setSelectedCountry, selectedCountry }) => {
    const tablets = useMediaQuery(
        { maxWidth: 1235 },
        { minWidth: 744 }
    )
    const mobile = useMediaQuery(
        { maxWidth: 743 },
        { minWidth: 272 }
    )
    return (
        <>
            {/* <Container> */}
            <div style={
                mobile ? { width: 320, margin: 'auto', padding: 10 }
                    :
                    tablets ? { border: '1px solid #888888', borderRadius: 16, width: 680, padding: '56px 64px', margin: 'auto' }
                        :
                        { border: '1px solid #888888', borderRadius: 16, width: 1200, margin: 'auto', padding: '72px 56px' }}>
                <div>
                    <div style={{ marginLeft: 5 }}>
                        <p className='register-step'>{t.register.text.step.second}</p>
                        <h1 className='register-title'>{t.register.text.title.merchant_location}</h1>
                    </div>
                    <div style={tablets ? { display: 'grid', gridTemplateColumns: '100%' } : { display: 'grid', gridTemplateColumns: '50% 50%' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                            <Autocomplete
                                disableClearable={true}
                                disablePortal
                                options={countries?.result?.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))}
                                getOptionLabel={(options) => options ? options.name : ''}
                                onChange={(e, newValue) => {
                                    setSelectedCountry(newValue ? newValue.id : null)
                                }}
                                renderInput={(params) => <TextField {...params}
                                    id="outlined-basic"

                                    style={{ marginLeft: 10, marginTop: 10, height: mobile ? 54 : 64, width: mobile ? 272 : 408, }}
                                    className={mobile ? 'input-style-register-mobile size-font-16' : 'input-style-register size-font-20'}
                                    label={t.register.form.country} />}
                            />
                            <Autocomplete
                                disableClearable={true}
                                disabled={selectedCountry === null}
                                disablePortal
                                options={cities.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))}
                                getOptionLabel={(options) => options ? options.name : ''}
                                onChange={(e, newValue) => setSelectedCity(newValue ? newValue.id : null)}
                                renderInput={(params) => <TextField {...params} id="outlined-basic"
                                    style={{ marginLeft: 10, marginTop: 10, height: mobile ? 54 : 64, width: mobile ? 272 : 408, }}
                                    className={mobile ? 'input-style-register-mobile size-font-16' : 'input-style-register size-font-20'}
                                    label={t.register.form.city} />}
                            />
                            <Autocomplete
                                disableClearable={true}
                                disabled={selectedCity === null}
                                disablePortal
                                options={district.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))}
                                getOptionLabel={(options) => options ? options.name : ''}
                                onChange={(e, newValue) => {
                                    setSelectedDistrict(newValue ? newValue.id : null)
                                    dispatch(PositionMerchant({
                                        lat: newValue.lat,
                                        lng: newValue.lon
                                    }))
                                }}
                                renderInput={(params) => <TextField {...params} id="outlined-basic"
                                    style={{ marginLeft: 10, marginTop: 10, height: mobile ? 54 : 64, width: mobile ? 272 : 408, }}
                                    className={mobile ? 'input-style-register-mobile size-font-16' : 'input-style-register size-font-20'}
                                    label={t.register.form.district} />}
                            />
                            <TextField
                                label={t.register.form.address}
                                value={itemForm.Address || ''}
                                required={itemForm.Address?.length === 0}
                                error={itemForm.Address?.length === 0}
                                onChange={(e) => handleItemform('Address', e.target.value)}
                                style={{ marginLeft: 10, marginTop: 10, height: mobile ? 54 : 64, width: mobile ? 272 : 408, }}
                                className={mobile ? 'input-style-register-mobile size-font-16' : 'input-style-register size-font-20'}

                            />

                        </div>
                        <div id="map" style={mobile ? { marginTop: 20, marginLeft: 10, width: 272 } : tablets ? { marginTop: 20, marginLeft: 10, width: 408 } : { margin: 20, marginTop: 10, marginLeft: -30 }}>
                            {/* <div className="map-container"> */}
                            <MapContainer
                                style={{ width: "100%", height: mobile ? 186.07 : 301 }}
                                center={{ lat: lat, lon: lon }}
                                zoom={20}
                                scrollWheelZoom={true}
                            >
                                <TileLayer
                                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                />
                                <LocationMarker PositionMerchant={PositionMerchant} useSelector={useSelector} dispatch={dispatch} />
                            </MapContainer>
                            {/* <p>*{t.note.map}</p> */}
                            {/* </div> */}
                            <div style={mobile ? { marginTop: 30, display: 'grid', gridTemplateColumns: '100%' } : { marginTop: 30, display: 'grid', gridTemplateColumns: '50% 50%' }}>
                                <button
                                    className={tablets ? 'input-style-register-mobile size-font-16' : 'input-style-register size-font-20'}
                                    style={{ marginRight: mobile ? 0 : 5, backgroundColor: '#FFFFFF', borderRadius: 8, borderColor: 'transparent', border: ' 1px solid #000000', height: 54 }} onClick={() => handleSubmit('back')}>{t.register.btn.back}</button>
                                <button
                                    className={tablets ? 'input-style-register-mobile size-font-16' : 'input-style-register size-font-20'}
                                    style={{ marginLeft: mobile ? 0 : 5, backgroundColor: '#FFDD21', borderRadius: 8, borderColor: 'transparent', height: 54, marginTop: mobile ? 10 : 0 }} disabled={!selectedCity || !selectedDistrict || !itemForm.Address || !selectedCountry} onClick={() => handleSubmit(2)} >{t.register.btn.next}</button>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
            {/* </Container> */}
        </>
    )
}

export const StepThree = ({ handleSubmit, imageShow, imageplus, handleChoose, setCropper, loadHeic, cropImage }) => {
    const tablets = useMediaQuery(
        { maxWidth: 1235 },
        { minWidth: 744 }
    )
    const mobile = useMediaQuery(
        { maxWidth: 743 },
        { minWidth: 272 }
    )
console.log(mobile);
    return (
        <div style={mobile || tablets ? { display: 'grid', gridTemplateColumns: '100%' } : { display: 'grid', gridTemplateColumns: '50% 50%', width: 1200, margin: 'auto', }}>
            {/* <Container> */}
            <div style={
                mobile ? { width: 320, margin: 'auto', padding: 10 }
                    :
                    tablets ? { border: 'solid 1px black', padding: '56px, 64px, 56px, 64px', borderRadius: 20, width: 680, margin: 'auto' }
                        :
                        { border: 'solid 1px black', padding: 50, borderRadius: 20, width: 576, }}>
                <div className="card-store-picture" style={mobile ? {} : tablets ? { width: '80%' } : {}}>
                    <div className='store-picture-text'>
                        <p className='register-step'>{t.register.text.step.third}</p>
                        <h1 className='register-title'>{t.register.text.title.merchant_picture}</h1>
                    </div>
                    <div>
                        <div style={mobile ? { display: 'grid', gridTemplateColumns: '100%' } : { display: 'grid', gridTemplateColumns: '50% 50%', }}>
                            <div className={imageShow === imageplus ? mobile ? 'card-foto' : tablets ? 'card-foto-tablets' : 'desktop-card-foto' : 'card-foto-choosed'}>
                                {imageShow === imageplus ?
                                    <img src={imageplus} alt="" /> : loadHeic ?
                                        <ReactLoading type='spin' color='red' height={70} width={70} /> :
                                        <div style={{  position: 'relative',
                                        width: '100%',
                                        height: 200, 
                                        background: '#333',
                                }}>
                                            <Cropper
                                            image={imageShow}
                                            crop={cropImage.crop}
                                            rotation={cropImage.rotation}
                                            zoom={cropImage.zoom}
                                            aspect={1 / 1}
                                            onCropChange={cropImage.setCrop}
                                            // onRotationChange={cropImage.setRotation}
                                            onCropComplete={cropImage.onCropComplete}
                                            onZoomChange={cropImage.setZoom}
                                          />
                                        </div>
                                        // <Cropper
                                        //     zoomTo={0.1}
                                        //     initialAspectRatio={1}
                                        //     preview=".img-preview"
                                        //     src={imageShow}
                                        //     viewMode={2}
                                        //     cropBoxResizable={false}
                                        //     responsive={true}
                                        //     autoCropArea={1}
                                        //     disabled={true}
                                        //     center={true}
                                        //     dragMode='move'
                                        //     checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                                        //     onInitialized={(instance) => {
                                        //         setCropper(instance);
                                        //     }}
                                        //     guides={true}
                                        // />

                                }
                            </div>
                            <div style={{ marginLeft: mobile ? 0 : tablets ? 30 : 10 }}>
                                <p style={{ textAlign: mobile ? 'center' : 'unset' }}>{t.register.text.note.registe_image}</p>
                                <div style={mobile ? { display: 'flex', justifyContent: 'center', alignItems: 'center' } : {}}>
                                    <label >
                                        <form encType='multipart/form-data' >
                                            <input
                                                style={{ display: 'none' }}
                                                id="upload-photo"
                                                name="IMG"
                                                type="file"
                                                accept='.jpg, .jpeg, .png, .heic'
                                                onChange={(e) => handleChoose(e)}
                                            />
                                            <MaterialUIButton component='span' style={{textTransform:'none', color:'#262626', height: 46, width: tablets ? 212 : 196, borderRadius: 4, border: '1px solid #000000', backgroundColor: 'transparent', alignItems: 'center' }}>
                                                {t.register.btn.photo}
                                            </MaterialUIButton>
                                        </form>
                                    </label>
                                </div>

                            </div>
                        </div>
                    </div>
                    <p className='register-text-picture'>{t.register.text.note.register_image}</p>

                    <div style={mobile ? { marginTop: 30, display: 'grid', gridTemplateColumns: '100%', marginBottom: 30 } : { marginTop: 30, display: 'grid', gridTemplateColumns: '50% 50%', marginBottom: 30 }}>
                        <button
                            className={tablets ? 'input-style-register-mobile size-font-16' : 'input-style-register size-font-20'}
                            style={{ marginRight: mobile ? 0 : 5, backgroundColor: '#FFFFFF', borderRadius: 8, borderColor: 'transparent', border: ' 1px solid #000000', height: mobile ? 48 : 54, }} onClick={() => handleSubmit('back')}>{t.register.btn.back}</button>
                        <button
                            className={tablets ? 'input-style-register-mobile size-font-16' : 'input-style-register size-font-20'}
                            style={{ marginLeft: mobile ? 0 : 5, marginTop: mobile ? 10 : 0, backgroundColor: '#FFDD21', borderRadius: 8, borderColor: 'transparent', height: mobile ? 48 : 54, }} disabled={loadHeic || imageShow === imageplus} onClick={() => handleSubmit(3)} >{t.register.btn.register}</button>
                    </div>

                    <p className='register-text-picture' align="justify">{t.register.text.note.agreement} <a href='merchant-agreement'>{t.register.text.note.tnc}</a></p>

                </div>
            </div>
            {/* </Container> */}
        </div>
    )
}

export const StepFour = () => {
    const tablets = useMediaQuery(
        { maxWidth: 1235 },
        { minWidth: 744 }
    )
    const mobile = useMediaQuery(
        { maxWidth: 743 },
        { minWidth: 272 }
    )

    const handleAppStore = (type) => {
        if (type === Android) {
            window.open('https://maxim-merchant.onelink.me/YyIq?pid=test&c=test&af_click_lookback=7d', '_blank')
        }
    }

    React.useEffect(() => {
        ReactGA.event({
            category: "User Complete Register",
            action: "User Complete Register",
            label: "User Complete Register", // optional
            value: 1, // optional, must be a number
            nonInteraction: true, // optional, true/false
          });
    })


    return (

        <div className='container-default'>
            {mobile ?
                <div style={{ display: 'grid', gridTemplateColumns: '100%', marginTop: 20, margin: 'auto' }}>
                    <div >
                        <img src={courierIMG} style={mobile ? { width: 272, height: 272 } : tablets ? { width: 328, height: 328 } : { width: 576, height: 576 }} />
                    </div>
                    <div style={{ marginTop: 16 }} >
                        <h1 className={`${tablets ? 'register-done-title-tablets ' : 'register-done-title-desktop'} register-done-color `}>{t.register.text.step.done}</h1>
                        <p className={`${tablets ? 'register-done-tablets ' : 'register-done-desktop'} register-done-color `}>{t.register.text.note.done}</p>
                        <p className={`${tablets ? 'register-done-tablets ' : 'register-done-desktop'} register-done-color `}>{t.register.text.note.doneAndDownload}</p>
                        <img src={Android} onClick={() => handleAppStore(Android)} />
                    </div>
                </div>
                :
                <div style={{ display: 'grid', gridTemplateColumns: '50% 50%' }}>
                    <div style={{ marginRight: 10, paddingTop: tablets ? 0 : 85, paddingBottom: 56 }} >
                        <h1 className={`${tablets ? 'register-done-title-tablets ' : 'register-done-title-desktop'} register-done-color `}>{t.register.text.step.done}</h1>
                        <p className={`${tablets ? 'register-done-tablets ' : 'register-done-desktop'} register-done-color `}>{t.register.text.note.done}</p>
                        <p className={`${tablets ? 'register-done-tablets ' : 'register-done-desktop'} register-done-color `}>{t.register.text.note.doneAndDownload}</p>
                        <img src={Android} onClick={() => handleAppStore(Android)} />
                    </div>
                    <div style={{ marginLeft: 10 }} >
                        <img src={courierIMG} style={mobile ? { width: 272, height: 272 } : tablets ? { width: 328, height: 328 } : { width: 576, height: 576 }} />
                    </div>
                </div>

            }
        </div>


    )
}