import Compressor from "compressorjs";
import { En, Es, Id, Ms, Ph, Pt, Th, Zh } from "../application/language";
import { PvcEn, PvcId } from "./privacypolicy";
import { AgreementEn, AgreementId } from "./agreement";
const translate = localStorage.getItem('language')


export const convertUTCToLocalTime = dateString => {
  let date = new Date(dateString);
  return (
    (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) +
    '/' +
    (date.getMonth() < 9 ? '0' + parseInt(parseInt(date.getMonth()) + 1) : date.getMonth() + 1) +
    '/' +
    date.getFullYear() +
    ' ' +
    (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) +
    ':' +
    (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) +
    ':' +
    (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds())
  )
};

export const process_image = async (index, data, typeProcess) => {
  try {
    const response = await Promise.all(index.map(async (item) => {

      const compress = await new Promise((resolve, reject) => {
        new Compressor(data[item], {
          quality: 0.6,
          convertSize: 500000,
          success: async (result) => {
            resolve(result)
          }
        })
      })

      switch (typeProcess) {
        case "IMG_PROCESSING":
          return compress
        case "CREATE_URL":
          return URL.createObjectURL(data[item])
        default:
          break;
      }


    }))
    return response

  }
  catch (err) {

  }
}

export const process_image_crop = async (index, data, typeProcess) => {
  try {
    const response = await Promise.all(index.map(async (item) => {

      const compress = await new Promise((resolve, reject) => {
        new Compressor(data, {
          quality: 0.6,
          convertSize: 500000,
          success: async (result) => {
            resolve(result)
          }
        })
      })

      switch (typeProcess) {
        case "IMG_PROCESSING":
          return compress
        case "CREATE_URL":
          return URL.createObjectURL(data)
        default:
          break;
      }


    }))
    return response

  }
  catch (err) {

  }
}

export const roundingTime = (time) => {
  time = time.split(':')
  if (time[1] < '30') {
    time[1] = '00'
  }
  else if (time[1] < '60') {
    time[1] = '30'
  }
  time = time.join(':')
  return time
}


export const t = translate === 'es' ? Es : translate === 'id' ? Id : translate === 'my' ? Ms : translate === 'ph' ? Ph : translate === 'pt' ? Pt : translate === 'th' ? Th : translate === 'ch' ? Zh : En
export const t_pvc = translate === 'es' ? PvcEn : translate === 'id' ? PvcId : translate === 'my' ? PvcEn : translate === 'ph' ? PvcEn : translate === 'pt' ? PvcEn : translate === 'th' ? PvcEn : translate === 'ch' ? PvcEn : PvcEn
export const t_agree = translate === 'es' ? AgreementEn : translate === 'id' ? AgreementId : translate === 'my' ? AgreementEn : translate === 'ph' ? AgreementEn : translate === 'pt' ? AgreementEn : translate === 'th' ? AgreementEn : translate === 'ch' ? AgreementEn : AgreementEn

export function getBrowserLocales(options = {}) {
  const defaultOptions = {
    languageCodeOnly: false,
  };
  const opt = {
    ...defaultOptions,
    ...options,
  };
  const browserLocales =
    navigator.languages === undefined
      ? [navigator.language]
      : navigator.languages;
  if (!browserLocales) {
    return undefined;
  }
  return browserLocales.map(locale => {
    const trimmedLocale = locale.trim();
    return opt.languageCodeOnly
      ? trimmedLocale.split(/-|_/)[0]
      : trimmedLocale;
  });
}

export function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

export const toUpper = (string) => {
  return string
    .toLowerCase()
    .split(' ')
    .map((word) => {
      if (word) { // add a check to skip empty string
        return word[0].toUpperCase() + word.substr(1);
      }
      return ''; // return empty string if word is empty
    })
    .join(' ')
}