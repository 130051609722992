const En = {
    languageModal: {
        textTitle: 'Please select your language and country',
        textChooseCity: 'Select a city',
        search: 'Search'
    },
    navbar: {
        textItem1: 'TUTORIALS',
        textItem2: 'CONTACTS',
        textButton: 'Register now',
        textRegis: 'Registration',
        textRegistration: 'How to add your goods',
        textProcess: 'How to process orders',
        textOrders: '(For clients) How to place orders',
        textComplete: 'How to complete orders',
        textSm: 'FOLLOW US ON SOCIAL MEDIA',
        textSm1: 'Get tips and recommendations on how to use the app and the service',
    },
    footer: {
        text: '2022 Maxim - all rights reserved',
        textPrivacy: 'Privacy Policy'
    },
    qna: {
        textTitle: 'FAQ',
        textContent1: 'Can I register more than one merchant?',
        textContent2: 'What’s your commission fee?',
        textContent3: 'Who pays the merchant?',
        textContent4: 'How do I open my outlet in the app?',
        textContent5: 'How do I delete my items in the app?',
        textContent6: 'How do I change the sequence of categories?',
        textSubcontent1: 'You can, but you should use a different email address for each merchant you register.',
        textSubcontent2: 'Currently, you can make money with our Service commission-free. You can specify the price of dishes like in a menu.',
        textSubcontent3: 'The driver pays for the order with their own money, and then receives payment from the client.',
        textSubcontent4: 'Tap Open if you are ready to take orders and tap Close after you are done working.',
        textSubcontent5: 'You can’t do that for now. Just deactivate your item or replace it with another one. ',
        textSubcontent6: 'Categories are sorted from oldest to newest ones, and you can`t change their sequence manually.',
    },
    guides: {
        register: {
            textStep1: 'Fill in your information: enter a password you will use to log in, as well as your contact phone number.',
            textStep2: 'Specify the address of your cafe or delivery point.',
            textStep3: 'Upload the logo of your company or a picture of your store.',
            textStep4: 'All done! You can now proceed to uploading your goods.'
        },
        addProduct: {
            textStep1: 'To start with, create a product category, for example, Snacks. You can add as many categories as you need.',
            textStep2: 'Tap “+” next to the category name to add your products. ',
            textStep3_1: 'Enter the information about your product, specify a discount, if you wish to provide any. A discount and a high quality photo will make your goods stand out.',
            textStep3_2: 'If you mark your product as Recommended, it will be displayed among the first ones in your store.',
        },
        processOrder: {
            textStep1: 'Tap Open to start working. When there is a new order, you will receive a notification.',
            textStep2_1: 'Tap “Confirm order” to start working on it, or “Cancel order” if, for example, your product is out of stock for the moment.',
            textStep2_2: 'If you don’t confirm an order within three minutes, it will be canceled automatically.',
            textStep3: 'We recommend that you wait for the courier before starting to prepare the order.',
        },
        placeOrder: {
            textStep1: 'The client selects the Foods&Goods rate and taps "What to buy"',
            textStep2: 'They can select the stores from the list or search for a specific product.',
            textStep3: 'The goods you marked as Recommended come first.',
            textStep4: 'The client can specify the number of goods, and the cost will be recalculated automatically.',
            textStep5: 'The app has all the features for a client’s convenience, such as ordering for a different person or comfortable delivery time.',
            textStep6: 'The client can track the courier with their order on the online map.'
        }
    },
    home: {
        card1: {
            textTitle: 'Sell your goods with Maxim',
            textContent: 'Get access to an active, paying audience at no extra cost.',
            textButton: 'Register now',
        },
        card2: {
            textTitle: 'Get more clients',
            textContent1: 'Post your goods for thousands of the Maxim app users to view.',
            textContent2: 'See all the advantages of cooperating with us.',
            textContentTitle1: 'Commission-free',
            textContentTitle2: 'Fast delivery',
            textContentTitle3: 'Free advertising',
            textSubcontent1: 'We don`t charge a percentage of sales — receive all the income from selling the goods and set prices like on a menu to attract customers.',
            textSubcontent2: 'Your order will be delivered by the courier closest to the pick-up point. The delivery is paid for by the customer. ',
            textSubcontent3: 'We will make a joint banner for your business and post the info about your company in our apps for drivers and clients.',
        },
        card3: {
            textTitle: 'Take orders in a handy app',
            textContent1: 'Add as many outlets for a single brand as you need. ',
            textContent2: 'Create any category you want. For example, drinks, soups, second courses, salads, etc. ',
            textContent3: 'Upload attractive photos to stand out from other businesses. ',
            textContent4: 'Set a discount to attract more customers.',
            textContent5: 'Analyze purchase statistics to offer more popular items.',
        },
        card4: {
            textTitle: 'Start now!',
            textContent1: 'Register',
            textContent2: 'Download our app',
            textContent3: 'Add your goods',
            textContent4: 'Wait for your first customers!',
            textButton: 'Register now',
        },
        card5: {
            textTitle: 'Free offline advertising',
            textContent1: 'Make your business stand out. We will design a layout and make a joint banner for you, free of charge. It will help you attract clients and tell them about your online ordering service. ',
            textContent2: 'Ordering a banner is available to registered merchants. Contact one of our offices.',
            textButton: 'View office addresses'
        },
        card6: {
            textTitle: 'Detailed guides',
            textContent1: 'Learn how to use our service.',
            textSubcontent1: 'How to register',
            textSubcontent2: 'How to add goods',
            textSubcontent3: 'How to process orders',
            textSubcontent4: '(For clients) How to place orders',
            textSubcontent5: '(For couriers) How to deliver orders'
        }
    },
    contact: {
        card1: {
            textTitle: 'Office',
            textContent: 'Jl. KH. Noer Ali No. 192, Kayuringin Jaya, Kec. Bekasi Sel., Kota Bks, West Java 17144',
            textPhone: '+ 6221-5092-8720',
        },
    },
    addgoods: {
        card1: {
            textTitle: 'How to add your goods in the MaximMerchant app',
            textContent1: 'On the ITEM tab, select CATEGORY, tap “+” and create a new category.',
            textContent2: 'Create as many categories as you need.',
            textContent3: 'On the ITEM tab, select MENU and tap “+”.',
            textContent4_0: 'Fill in all the fields. If you want your goods to be displayed on the merchant’s home page, activate the recommendation. In the description, you can specify the item’s name.',
            textContent4_1: 'Toggle the switch to activate the item. Now, the clients will see it in their app. Currently, you can’t delete your items, but you can deactivate them or replace them with something else.',
            textContent4_2: 'Tap OPEN when you are ready to start taking orders. After you are done working, make sure to tap CLOSE.',
        },
        card2: {
            textTitle: 'Ready to begin?',
            textContent: 'Register and begin promoting your business with us!',
            textButton: 'Registration'
        },
    },
    merchant: {
        product: {
            labelShowCustomer: "Show to the customers",
            labelName: "Product name",
            labelDesc: 'Description',
            labelCat: "Category",
            labelPrice: 'Price',
            labelDisc: "Discount",
            labelDiscAmount: "Discount amount",
            labelRecommend: "Recommended",
            labelChoosePhoto: 'Select a photo',
            labelSaveAdd: 'Save the product',
            labelSaveEdit: 'Save changes',
            labelRequired: 'Required',
            labelNotRequired: 'Not required',
            LabelCancelEdit: 'Cancel',
            LabelVariationAttach: 'Variation attached'
        },
        btn: {
            pic: 'Select a photo',
            submit: 'Submit',
            close: 'Close',
            add_more: 'Add more',
            schedule: 'Add new schedule',
            edit: 'Edit',
            delete: 'Delete',
            variationAttach: 'Click here to view the variation attached to this item'
        },
        schedule: {
            text: {
                title: 'Opening hours',
                add: {
                    title: 'Add schedule'
                },
                edit: {
                    title: 'Edit schedule'
                },
                delete: {
                    title: 'Delete schedule',
                    alert: 'Are you sure you want to delete the data?'
                }
            },
            day: {
                sunday: 'Sunday',
                monday: 'Monday',
                tuesday: 'Tuesday',
                wednesday: 'Wednesday',
                thursday: 'Thursday',
                friday: 'Friday',
                saturday: 'Saturday'
            },
            form: {
                day: 'Day',
                open: 'Time Open',
                close: 'Time Close'
            }
        },
        err: {
            pic_fail: `Can't upload image, please use a different photo`,
            empty: `Form can't be empty`,
            no_pic: 'Select a photo to upload',
            get: 'Error while getting data',
            no_data: 'No data',
            add: 'Error while adding data',
            edit: 'Error while editing data',
            delete: 'Error while deleting data',
            categoryFetch: "Failed to get the category, please try to log in again",
            variationFetch: "Failed to get the variation, please try to log in again",
            productFetch: "Failed to get the product, please try to log in again",
            connectedFetch: "Failed to get the attached variation, please try to log in again",
            processImage: 'Failed to process the image, please try again or try uploading a different image'
        }
    },
    register: {
        err: {
            title: 'Error!',
            no_pic: 'Select a photo to upload',
            sent: 'Error while sending data to server',
            email: 'Email already registered',
            empty: `Form can't be empty`,
            no_loc: 'Pick your location from the map (click the map twice to get your current location or move the pointer to your location)',
            required: 'Required field',
            email_not_valid: 'Invalid email',
            pass_not_strong: 'Password is not strong enough. Use minimum 7 characters and try to combine uppercase, lowercase, numbers, and special characters (!@#$%^&*_+=-)',
            pass_strong: 'Strong password',
            pass_perfect: 'Perfect password'
        },
        btn: {
            close: 'Close',
            continue: 'Continue',
            back: 'Previous step',
            next: 'Next step',
            register: 'Register',
            photo: 'Select a photo'
        },
        text: {
            step: {
                first: 'Step 1 of 3',
                second: 'Step 2 of 3',
                third: 'Step 3 of 3',
                done: 'Done'
            },
            title: {
                merchant_info: 'Merchant information',
                merchant_location: 'Store location',
                merchant_picture: 'Store picture',
            },
            note: {
                registe_image: 'You can upload your logo or the picture of your main product.',
                agreement: 'By clicking Register, I agree to',
                tnc: 'Terms and conditions',
                done: 'Registration successful. To start working, you should wait until we confirm your account, which usually takes several days. We will call you or send you an email.',
                doneAndDownload: 'In the meantime, you can prepare to sell your goods by downloading our app and adding them there.'
            }
        },
        form: {
            phone: 'Phone number',
            name: 'Store name',
            email: 'Email',
            desc: 'Description',
            password: 'Password',
            referral: 'Referral (if you have it)',
            country: 'Country',
            city: 'City',
            district: 'District',
            address: 'Address'
        },
        question: {
            any: "Any questions?",
            check: "Check out our tutorials.",
            button: "View tutorials"
        },        
    },
    webView: {
        complete_add_title: "ADD PRODUCT SUCCESS",
        complete_add_description: "Product added. You can add more or you can close this page",
        complete_edit_title: "EDIT PRODUCT SUCCESS",
        complete_edit_description: "Product edited. You can close this page",
        imageUploadError: "file image is too large! max 5mb"
    },
}

export default En