import { Button, Modal } from "react-bootstrap"
import { RemoveErrProduct } from "../../application/action"

const ErrorFetchModalProduct = ({FetchErr, dispatch, t}) => {
    return(
        <Modal show={FetchErr} centered onHide={() => dispatch(RemoveErrProduct())}>
          <Modal.Body>
            <div>
              {FetchErr === "Category failed" ? t.merchant.err.categoryFetch
                : FetchErr === "Product failed" ? t.merchant.err.productFetch 
                : FetchErr === "Variation failed" ? t.merchant.err.connectedFetch 
                : FetchErr === "Category variation failed" ? t.merchant.err.variationFetch
                : ""}
            </div>
          </Modal.Body>
          <Modal.Footer style={{ border: 0 }}>
            <Button
              variant="secondary"
              onClick={() => dispatch(RemoveErrProduct())}
            >
                 {t.merchant.btn.close}
            </Button>
          </Modal.Footer>
        </Modal>
    )
}

export default ErrorFetchModalProduct