// Component
import { Navbar, Container, Nav, NavDropdown, Row, Col } from "react-bootstrap"
import { useMediaQuery } from "react-responsive"
import logomaxim from '../../../assets/image/logo.png'
import React from "react"
import { ModalChooser, ModalInitial } from "../chooser/modalInitial"
import { t } from '../../../misc/utils'
import instagram from '../../../assets/component/instagram.png'
import { useDispatch } from "react-redux"
import { getCity } from "../../../application/action"
import './style.css'


const TopNavLanguage = ({
    handleModalChooser,
    country,
    city,
    lang,
    initialModal,
    setInitialModal,
    modalType,
    setModalType,
    modalChooser,
    setModalChooser,
}) => {
    const [langModal, setLangModal] = React.useState(false)
    const listLanguage = [
        { id: 1, type: 'en', name: 'English' },
        { id: 1, type: 'es', name: 'Español' },
        { id: 1, type: 'id', name: 'Bahasa Indonesia' },
        { id: 1, type: 'ms', name: 'Bahasa Melayu' },
        { id: 1, type: 'ph', name: 'Filipino' },
        { id: 1, type: 'pt', name: 'Português (Brasileiro)' },
        { id: 1, type: 'th', name: 'ไทย' },
        { id: 1, type: 'zh', name: '中文' },
    ]
    const mobile = useMediaQuery({
        query: '(max-width:1235px)'
    })
    const smallDesktop = useMediaQuery({
        query: '(max-width:1235px)'
    })
    const handleChooserLanguage = (item) => {
        if(lang === item.type) {
            setLangModal(false)
        }
        else {
            localStorage.setItem('language', item.type)
            window.location.reload()
        }
    }
    return (
        <>
            {mobile || smallDesktop ?
                <></>
                :
                <Navbar bg="" expand="lg" className="navbar-gray">
                    <div className="container-default-navbar">
                        <div className="ms-auto text-right">
                            <a href='#' alt='' className="translation" onClick={() => handleModalChooser('country')}>{country ? country.name : 'Select Country'}</a> &nbsp;|&nbsp;
                            <a href='#' alt='' className="translation" onClick={() => handleModalChooser('city')}>{city ? city.name : 'Select City'}</a> &nbsp;|&nbsp;
                            <a href='#' alt='' className="translation" onClick={() => setLangModal(prev => !prev)}>{lang?.toUpperCase()}</a>
                            <div className={`transition-dropdown-navbar language-${langModal ? 'open' : 'close'}`}>
                                <div>
                                    <div style={{ display: 'flex', flexDirection: 'column', paddingTop:10 }}>
                                        {listLanguage.map((item, index) => {
                                            return (
                                                <>
                                                    <span key={index} className={lang === item.type ? "card-language-choosed-navbar" : "card-language-navbar"} onClick={() => handleChooserLanguage(item)}>{item.name}</span>
                                                </>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Navbar>
            }
        </>
    )
}

export default TopNavLanguage