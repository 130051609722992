const privacy = {
    // title
    title: {
        privacy: 'Kebijakan Privasi MAXIM MERCHANT',
    },

    // subtitle
    subTitle: {
        privacy1: 'Kebijakan Privasi ini menjelaskan bagaimana, LAYANAN “MAXIM” (selanjutnya disebut “LAYANAN”, “MAXIM” “kami”, “milik kami” atau “kami”) mengumpulkan, menggunakan, memproses, mengungkapkan, dan melindungi Data Pribadi dan Data Bisnis yang dikumpulkan melalui penggunaan Aplikasi dan Situs Web Maxim Merchant (selanjutnya disebut sebagai Aplikasi dan Situs Web), dan produk atau fitur kami di Aplikasi. Kebijakan Privasi ini berlaku untuk setiap Pengguna Situs Web atau Aplikasi kami yang berarti termasuk Mitra Pedagang (selanjutnya disebut sebagai “Pengguna”, “Anda”, “milik” atau “milik Anda”), Pelanggan, dan Pengemudi.',
        privacy2: 'Kebijakan ini merupakan bagian yang tidak dapat dicabut dari Perjanjian Pedagang yang dapat diakses di Situs Web atau Aplikasi kami. Saat menggunakan layanan melalui Situs Web atau Aplikasi, Pengguna menyetujui Kebijakan ini, termasuk pemrosesan Data Pribadi dan Data Bisnis Pengguna oleh kami dalam hal hukum yang berlaku mewajibkan persetujuan tersebut.',
    },

    // note
    note: {
        map: 'Untuk tujuan Kebijakan Privasi ini, istilah yang tercantum di bawah ini akan diartikan sebagai berikut:',
    },

    // button
    btn: {
        Back: 'Back',
        Acc: 'Agree',
    },

    pvc: {
        part1: "I. Ketentuan Umum",
        part2: "II. Pengumpulan Data Pribadi dan Data Bisnis",
        part3: "III. Penggunaan Data Pribadi dan Data Bisnis",
        part4: "IV. Pengungkapan Data Pribadi dan Data Bisnis",
        part5: "V. Perlindungan Data Pribadi dan Bisnis",
        part6: "VI. Penambahan dan Penghapusan Data Pribadi dan Bisnis",
        part7: "VII. Informasi Geografis",
        part8: "VIII. Yurisdiksi dan Transfer Lintas Batas",
        part9: "IX. Kukis",
        part10: "X. Informasi Tentang Perangkat Pengguna",
        part11: "XI. Data Tentang Operator Telekomunikasi",
        part12: "XII. Permintaan Riwayat",
        part13: "XIII. Pengakuan, Persetujuan dan Penarikannya",
        part14: "XIV. Hak Pengguna",
        part15: "XV. Perubahan Atau Pembaruan Kebijakan ini",
        part16: "XVI. Kontak",
    },

    pvc_part1: {
        bag1: 'Untuk tujuan Kebijakan Privasi ini, istilah yang tercantum di bawah ini akan diartikan sebagai berikut:',
        bag2: {
            privacy_content: [
                'Data Pribadi dan Data Bisnis: Setiap informasi yang secara langsung dan tidak langsung terkait dengan Anda sebagai Pengguna Situs Web atau Aplikasi kami, termasuk namun tidak terbatas pada nama Merchant, nomor telepon (akun Whatsapp aktif), bank dan rincian kartu kredit (jika berlaku), alamat email, deskripsi dan kategori Merchant, lokasi geografis, gambar Anda, barang dan harganya, serta deskripsi dan gambar barang.',
                'Mitra Pedagang (merchant): Pribadi atau badan, yang telah menyatakan persetujuannya pada perjanjian dan telah menerima hak untuk menggunakan Aplikasi Pedagang, dan oleh karena itu dapat menikmati hak yang diberikan dan memenuhi tanggung jawab dalam konteks penyediaan makanan dan minuman dan bahan makanan untuk memenuhi kebutuhan Klien melalui pesanan mereka dari Aplikasi Seluler.',
                'Mitra Pengemudi: Pribadi yang secara mandiri dan atas kemauannya sendiri setuju untuk menyediakan jasa angkutan dengan sepeda motor.',
                'Pelanggan: Pribadi yang telah melakukan permintaan melalui Aplikasi Pelanggan atau situs web kami, dan telah memberikan Data Pribadinya untuk tujuan ini.',
                'Layanan: Informasi yang diberikan kepada Pelanggan melalui Aplikasi atau Situs Web kami, agar Permintaan Pelanggan dapat diterima, diproses, dan dikirimkan ke Mitra (baik Mitra Pengemudi maupun Pedagang) dan pelaksanaan Permintaan tersebut dikomunikasikan kembali ke Pelanggan. Subjek dan metode penyediaan layanan akan diidentifikasi sesuai dengan penawaran pelanggan di situs web kami.',
                'Pemrosesan Data Pribadi dan Data Bisnis: setiap aktivitas atau kegiatan yang dilakukan pada Data Pribadi dan Data Bisnis, dengan atau tanpa otomatisasi, termasuk pengumpulan, pencatatan, sistematisasi, penyimpanan, pengiriman, pengorganisasian (pembaruan, koreksi), abstraksi, penggunaan, mengomunikasikan (mendistribusikan, menyediakan, mengakses), membuat tidak pribadi, memblokir, menghapus, dan menghilangkan Data Pribadi dan Data Bisnis.',
                'Memblokir Data Pribadi dan Data Bisnis: penghentian sementara dalam pemrosesan Data Pribadi dan Data Bisnis (tidak termasuk kasus ketika pemrosesan diperlukan untuk mengidentifikasi Data Pribadi dan Data Bisnis)',
                'Menghilangkan Data Pribadi dan Data Bisnis: aktivitas yang mengakibatkan Data Pribadi dan Data Bisnis yang ada tidak dapat dipulihkan dan mengakibatkan penghapusan penyimpanan Data Pribadi dan Data Bisnis.'
            ],

        }
    },

    pvc_part2: {

        bag1: 'Data Pribadi dan Data Bisnis Pengguna disimpan dalam penyimpanan elektronik dan diproses oleh sistem otomatis untuk Pemrosesan Data Pribadi dan Data Bisnis',
        bag2: {
            privacy_content: [
                'Kami mengumpulkan Data Pribadi dan Data Bisnis Pengguna ketika Pengguna secara sukarela memberikannya kepada kami. Misalnya, Pengguna dapat memberikan Data Pribadi dan Data Bisnisnya kepada kami ketika Pengguna:',
                'Data Pribadi dan Data Bisnis dapat terkumpul melalui operasi Layanan secara normal, data tersebut mencakup, misalnya:',
                'Saat kami mengumpulkan Data Pribadi dan Data Bisnis dari sumber lain, kami memastikan bahwa data yang di serah terimakan kepada kami sesuai dengan hukum yang berlaku. Sumber-sumber tersebut di atas antara lain',
                'Saat Pengguna mendaftar dan membuat akun di situs web kami, kami mengumpulkan dan memproses Data Pribadi dan Data Bisnis Mitra berikut:',
                'Secara khusus, kami dapat mengumpulkan dan memproses data perangkat Pengguna (lokasi GPS, nomor IMEI) untuk tujuan analisis dan administrasi',
                'Data Pribadi dan Data Bisnis Pengguna akan kami hapus, dengan contoh berikut:',
                'Penghapusan Data Pribadi dan Data Bisnis akan dilakukan sedemikian rupa sehingga tidak dapat dipulihkan kembali.',
                'Jika Data Pribadi dan Data Bisnis adalah anak di bawah umur (yaitu individu di bawah usia 18 tahun) yang diungkapkan kepada kami, Anda dengan ini menyatakan persetujuan Anda untuk pemrosesan Data Pribadi dan Data Bisnis anak di bawah umur sebagai orang tua atau hukum wali sah anak di bawah umur (atau persetujuan yang diperlukan dari orang tua atau wali sah anak di bawah umur) yang menerima dan setuju untuk terikat oleh Kebijakan ini dan bertanggung jawab atas tindakannya. Jika Anda masih di bawah umur dan bermaksud untuk memberikan Data Pribadi dan Data Bisnis Anda kepada kami, dengan ini Anda mengonfirmasi dan mengakui bahwa Anda telah memperoleh persetujuan orang tua atau wali sah Anda untuk terikat oleh Kebijakan ini',
            ],
            privacy_sub_content: {
                1: [
                    '(1) mengisi profil pengguna atau formulir pendaftaran',
                    '(2) memberikan informasi untuk menilai kelayakan Pengguna untuk menyediakan Layanan sebagai Mitra Pedagang',
                    '(3) berinteraksi dengan laman media sosial kami.',
                    '(4) berpartisipasi dalam pertandingan atau acara yang diselenggarakan oleh kami; dan',
                    '(5) memberikan informasi demografis dalam survei.'
                ],
                2: [
                    '(1) Lokasi',
                    '(2) informasi transaksi dalam Aplikasi (termasuk metode pembayaran)',
                    '(3) pesan obrolan saat menggunakan fitur komunikasi dalam Aplikasi kami',
                    '(4) umpan balik, peringkat, dan pujian',
                    '(5) informasi perangkat pengguna',
                    '(6) setiap Data Pribadi dan Data Bisnis yang di tangkap melalui interaksi Pengguna dengan kami',
                ],
                3: [
                    '(1) Program rujukan',
                    '(2) Mitra bisnis kami',
                    '(3) Data yang tersedia untuk umum',
                    '(3) Sumber data pemerintah',
                ],
                4: [
                    '(1) Nama Pedagang',
                    '(2) Nomor telepon yang terdaftar yang juga memiliki akun Whatsapp aktif',
                    '(3) Email',
                    '(4) Foto toko Pengguna',
                    '(5) Informasi lengkap tentang lokasi toko Pengguna',
                    '(6) Deskripsi Pedagang',
                ],
                5: [],
                6: [
                    '(1) Tiga tahun setelah selesai memberikan layanan',
                    '(2) Jika Pengguna menarik kembali persetujuannya untuk Memproses Data Pribadi dan Data Bisnisnya.',
                ],
                7: [],
                8: [],
            }
        }

    },

    pvc_part3: {
        bag1: 'Tujuan utama dari Kebijakan Privasi antara Pengguna dan kami ini adalah untuk Pemrosesan Data Pribadi dan Data Bisnis Pengguna, yang tercantum di bawah ini.',
        bag2: {
            privacy_content: [
                'Secara umum, Data Pribadi dan Data Bisnis Pengguna akan digunakan untuk menyediakan, mempersonalisasi, memelihara, dan meningkatkan Layanan kami. Ini termasuk menggunakan Data Pribadi dan Data Bisnis untuk:',
                'Secara Khusus, kami dapat menggunakan Data Pribadi dan Data Bisnis Pengguna untuk tujuan berikut:',
            ],
            privacy_sub_content1: [
                '(1) memberi Anda pesanan',
                '(2) melibatkan Mitra Pengemudi dalam menyediakan Layanan',
                '(3) membuat, mengelola, dan memperbarui akun Pengguna',
                '(4) memeriksa identitas Pengguna',
                '(5) proses pembayaran',
                '(6) menawarkan, mendapatkan, menyediakan, atau memfasilitasi asuransi untuk pesanan (jika berlaku)',
                '(7) melacak proses pesanan',
                '(8) aktifkan fitur yang mempersonalisasi Aplikasi Anda, seperti daftar item favorit menurut Pelanggan',
                '(9) melakukan operasi internal yang diperlukan untuk menyediakan Layanan kami, termasuk pemecahan masalah bug perangkat lunak dan masalah operasional, melakukan analisis data, pengujian dan penelitian, pemantauan dan analisis tren penggunaan dan aktivitas; dan',
                '(10) melindungi keamanan atau integritas Layanan dan setiap fasilitas atau peralatan yang digunakan untuk menyediakan Layanan.',
            ],
            privacy_sub_content2: [
                '(a) mengidentifikasi Pengguna dan untuk mendaftarkan, mengelola, memverifikasi, menonaktifkan, atau mengelola akun Pengguna di Aplikasi',
                '(b) memfasilitasi atau mengaktifkan verifikasi apa pun yang kami anggap perlu sebelum kami mendaftarkan Pengguna.',
                '(c) memungkinkan Pengguna memberikan layanannya kepada Pelanggan;',
                '(d) memproses, memfasilitasi, dan melengkapi pembayaran hak Pengguna atas layanan yang telah disediakan',
                '(e) menyampaikan dan mengirimkan Informasi Pelanggan terkait dengan ketentuan layanan, termasuk untuk menyampaikan Permintaan Pelanggan kepada Pengguna dan untuk memfasilitasi penerimaan Pengguna atas Permintaan tersebut.',
                '(f) memberi pemberitahunan dan pembaruan pada Aplikasi atau perubahan peraturan kami sesuai dengan layanan yang akan disediakan kepada Pengguna',
                '(g) memberikan laporan kepada Pengguna sehubungan dengan layanannya yang telah disediakan',
                '(h) memproses dan menanggapi umpan balik dari Pengguna atas layanan yang telah Pengguna berikan',
                '(i) memantau aktivitas Pengguna, perilaku, dan data demografis termasuk tren dan penggunaan berbagai layanan yang tersedia di Aplikasi',
                '(j) mengembangkan, menguji, meningkatkan, mempersonalisasi, memelihara, dan meningkatkan Aplikasi, situs web, dan Layanan kami untuk memuaskan Pengguna;',
                '(k) untuk memastikan keselamatan dan keamanan Layanan kami dan semua pengguna. Ini termasuk:',
                '(l) menyelesaikan masalah dukungan pelanggan. Kita dapat;',
                '(m) menyelidiki dan menyelesaikan klaim aau perselisihan, sebagaimana diizinkan dan diwajibkan oleh hukum yang berlaku',
                '(n) Melakukan pengujian, penelitian, analisis dan pengembangan produk. Hal ini memungkinkan kami untuk memahami dan menganalisis kebutuhan dan preferensi Pengguna, melindungi Data Pribadi dan Data Bisnis Anda, memperbaiki dan meningkatkan keselamatan dan keamanan Layanan kami, mengembangkan fitur, produk, dan layanan baru, serta memfasilitasi solusi asuransi dan keuangan.',
            ],
            privacy_sub_sub_content: {
                1: [],
                2: [],
                3: [],
                4: [],
                5: [],
                6: [],
                7: [],
                8: [],
                9: [],
                10: [],
                11: [
                    'i.	Menyaring pengemudi dan mitra pengiriman sebelum mereka menggunakan Layanan kami',
                    'ii. Memastikan identitas saat masuk ke Aplikasi atau Situs Web',
                    'iii. Menggunakan perangkat, lokasi, profil, penggunaan, dan Data Pribadi dan Data Bisnis lainnya untuk mencegah, mendeteksi, dan memerangi penipuan atau aktivitas yang tidak aman',
                    'iv. Memantau kepatuhan terhadap Aturan Kerja, kebijakan kami; dan',
                    'v.	Mendeteksi, mencegah dan mengambil tindakan yang diperlukan atas potensi kegiatan kriminal yang sedang berlangsung.',
                    'vi. Berbagi lokasi Mitra Pengemudi dan Pelanggan saat Tombol Darurat diaktifkan',
                ],
                12: [
                    'i.	Menyelidiki dan mengatasi masalah',
                    'ii. Memantau dan meningkatkan respons dukungan pelanggan kami',
                    'iii. Menanggapi pertanyaan, komentar, dan memberikan umpan balik; dan',
                    'iv. Memberitahukan Anda tentang langkah-langkah yang diambil untuk menyelesaikan masalah dukungan pelanggan.',
                ],
                13: [],
                14: [],
            }
        },
        bag3: {
            privacy_content: [
                'Kami juga dapat menggunakan Data Pribadi dan Data Bisnis Anda ketika kami diminta, disarankan, direkomendasikan, diharapkan, atau diminta untuk melakukannya oleh penasihat hukum kami atau otoritas hukum, peraturan, pemerintah, otoritas lokal atau asing lainnya. Misalnya, kami dapat menggunakan Data Pribadi dan Data Bisnis Anda untuk:',
                'Kami dapat menggunakan Data Pribadi dan Data Bisnis Anda untuk tujuan periklanan dan produk, layanan, acara, atau promosi mitra bisnis. Misalnya:',
                'Kami akan memberitahukan Anda tentang iklan tersebut melalui postingan, layanan pesan singkat, pemberitahuan dalam aplikasi, layanan pesan online serta pemberitahuan push secara manual dan melalui email. Jika Anda ingin berhenti berlangganan untuk pemasaran dan promosi, Anda dapat mengklik tautan berhenti berlangganan di email atau pesan yang dikirimkan. Atau, Anda juga dapat memperbarui preferensi Anda di pengaturan Aplikasi kami. Penyediaan Data Pribadi dan Data Bisnis Anda kepada kami adalah atas dasar sukarela. Namun, jika Anda tidak memberikan Data Pribadi dan Data Bisnis Anda atau jika Data Pribadi dan Data Bisnis Anda tidak lengkap saat diberikan kepada kami, akan memengaruhi kemampuan kami untuk memenuhi Tujuan yang disebutkan di atas dan untuk Anda menikmati manfaat Layanan kami.',
                'Kami dapat menggunakan Data Pribadi dan Data Bisnis Pengguna tanpa persetujuan, dengan mengandalkan Kepentingan Sah atau pengecualian lain yang diberikan oleh undang-undang privasi data yang berlaku, untuk tujuan seperti pencegahan penipuan.'
            ],

            privacy_sub_content: {
                1: [
                    '(1) Mematuhi perintah pengadilan atau persyaratan hukum, pemerintah, atau peraturan lainnya',
                    '(2) Menegakkan Aturan Kerja kami atau perjanjian lainnya; dan',
                    '(3) melindungi hak atau properti kami jika terjadi klaim atau perselisihan.',
                ],
                2: [
                    '(1) Mengirimi Anda peringatan, buletin, pembaruan, surat, materi promosi, hak istimewa, salam perayaan; dan',
                    '(2) Memberikan undangan, dan mengelola partisipasi Anda dalam acara atau aktivitas kami.',
                ],
                3: [

                ],
                4: [

                ],
            }
        }

    },

    pvc_part4: {
        bag1: 'Kami perlu membagikan Data Pribadi dan Bisnis dengan para pihak untuk sejumlah tujuan. Pihak-pihak tersebut antara lain:',
        bag2: {
            privacy_content: [
                'Pengguna Aplikasi atau Situs Web lainnya. Misalnya, kepada Pengguna, kami dapat membagikan nama Pelanggan dan lokasi pengiriman barang yang dibeli, serta nama, lokasi, dan nomor plat kendaraan Mitra Pengemudi. Kami dapat membagikan Data Pribadi dan Bisnis Anda dengan Pelanggan Anda dan Mitra Pengemudi yang mengambil pesanan, termasuk nama dan foto Anda, lokasi Anda, daftar barang dengan gambar dan deskripsi, harga barang, dan peringkat Anda.',
                'Pihak ketiga yang berhubungan dengan pemesanan. Misalnya, kami dapat membagikan lokasi Anda dengan pihak ketiga ketika Pelanggan atau Mitra Pengemudi mengaktifkan Tombol Darurat.',
                'Anak perusahaan dan afiliasi. Kami membagikan Data Pribadi dan Bisnis dengan anak perusahaan kami, perusahaan terkait, entitas yang dikendalikan bersama, dan afiliasi.',
                'Mitra bisnis PT TPI, Kami dapat memberikan Data Pribadi dan Bisnis kepada mitra bisnis kami (secara mandiri atau atas permintaan Anda). termasuk:',
            ],
            privacy_sub_content: {
                1: [],
                2: [],
                3: [],
                4: [
                    '(1) Pemroses dan fasilitator pembayaran',
                    '(2) Layanan pemeriksaan latar belakang dan anti pencucian uang',
                    '(3) Penyedia penyimpanan cloud',
                    '(4) Mitra pemasaran dan penyedia platform pemasaran',
                    '(5) Penyedia analitik data',
                    '(6) Penyedia asuransi dan keuangan',
                    '(7) Mitra yang terintegrasi dengan Aplikasi kami atau sebaliknya, dan',
                    '(8) Mitra yang bekerja sama dengan kami untuk memberikan promosi, kompetisi, atau layanan khusus lainnya.',
                ],
            }
        }
    },

    pvc_part5: {
        bag1: '',
        bag2: {
            privacy_content: [
                'Kami akan melakukan semua upaya secara hukum untuk melindungi dan mengamankan Data Pribadi dan Bisnis Pengguna terhadap pemrosesan Data oleh orang yang tidak berwenang dan terhadap pemrosesan yang melanggar hukum atau risiko serupa lainnya.',
                'Kami menerapkan langkah-langkah berikut untuk mencegah akses yang tidak berwenang ke Data Pribadi dan Bisnis Pengguna:',
                'Transmisi informasi melalui Internet tidak sepenuhnya aman, meskipun kami akan melakukan yang terbaik untuk melindungi Data Pribadi dan Bisnis Pengguna, kami tidak dapat menjamin keamanan Data Pribadi dan Bisnis Pengguna yang dikirimkan melalui platform online mana pun. Dengan demikian, setiap transmisi tetap menjadi risiko Pengguna sendiri.',
            ],
            privacy_sub_content: {
                1: [],
                2: [
                    '(a) Identifikasi ancaman keamanan dalam sistem saat memproses Data Pribadi dan Bisnis',
                    '(b) Menerapkan sistem keamanan untuk tempat yang berisi sistem informasi untuk mencegah orang yang tidak berwenang memasuki tempat tersebut.',
                    '(c) Menyediakan tempat penyimpanan Data Pribadi dan Bisnis',
                    '(d) Mengonfirmasi daftar orang yang memiliki akses ke Data Pribadi dan Bisnis berdasarkan pekerjaan mereka.',
                    '(e) Menggunakan sarana yang diperlukan untuk melindungi Data Pribadi dan Bisnis dari akses yang tidak memiliki wewenang.',
                    '(f) Evaluasi langkah-langkah yang digunakan.',
                    '(g) Mendeteksi akses yang tidak sah ke Data Pribadi dan Bisnis.',
                    '(h) Memulihkan data yang telah dihapus atau rusak karena akses yang tidak sah (jika pemulihan tersebut memungkinkan).',
                    '(i) Menjalankan regulasi yang ketat atas akses ke Data Pribadi dan Bisnis dalam sistem setelah diproses.',
                    '(j) Mengontrol langkah-langkah yang digunakan untuk mengamankan Data Pribadi dan Bisnis serta tingkat keamanan sistem informasi.',
                ],
                3: [],
            }
        }
    },

    pvc_part6: {
        bag1: '',
        bag2: {
            privacy_content: [
                'Pengguna dapat meminta kami untuk melengkapi, memblokir, atau menghilangkan Data Pribadi dan Bisnis, jika tidak lengkap, tidak tepat, diperoleh dengan cara ilegal atau tidak diperlukan untuk tujuan proses data. Selain itu, Pengguna dapat menarik kembali persetujuannya untuk memproses data tersebut. Hal ini dilakukan melalui pengiriman permintaan tertulis kepada kami dengan surat dengan konfirmasi pengiriman atau dengan menyerahkan secara pribadi permintaan tersebut di lingkungan PT TPI. Alamat kantor kami disediakan di situs web. Permintaan tersebut harus berisi sebagai berikut: Nomor dokumen identitas utama Pengguna atau wakilnya, informasi mengenai tanggal penerbitan dokumen tersebut dan organisasi penerbitnya, alamat tempat tinggal Pengguna, informasi yang mengkonfirmasikan Pengguna dan nomor identifikasi Pengguna, atau informasi yang menegaskan dengan cara apa pun pemrosesan Data Pribadi dan Bisnis Pengguna, permintaan untuk melengkapi, memblokir atau menghilangkan Data Pribadi dan Bisnis Pengguna atau pemberitahuan untuk mencabut perjanjian untuk memproses data tersebut, tanda tangan Pengguna atau wakilnya. PT TPI wajib memberikan jawaban dalam waktu 30 hari sejak diterimanya permintaan atau pemberitahuan tersebut.',
                'Kami akan menyimpan Data Pribadi dan Bisnis Anda selama diperlukan atau diizinkan dengan konsisten untuk jangka waktu 5 (lima) tahun sesuai dengan hukum yang berlaku.',
            ],
        }
    },

    pvc_part7: {
        bag1: 'Untuk memberikan layanan kepada Pelanggan, kami dapat membagikan informasi lokasi geografis Pengguna kepada Pelanggan dan Mitra Pengemudi.',
        bag2: {
            privacy_content: [],
        }
    },

    pvc_part8: {
        bag1: 'Data Pribadi dan Bisnis Anda dapat disimpan dan diproses di negara, negara bagian, dan kota mana pun di mana kami memiliki fasilitas atau di mana kami melibatkan penyedia layanan. Dengan menggunakan Aplikasi, Anda telah memberikan persetujuan Anda untuk mentransfer informasi ke negara-negara di luar negara tempat tinggal Anda, yang dapat memiliki aturan perlindungan data yang berbeda dari Indonesia.',
        bag2: {
            privacy_content: [],
        }
    },

    pvc_part9: {
        bag1: 'Tujuan utama dari Kebijakan Privasi antara Pengguna dan kami ini adalah untuk Pemrosesan Data Pribadi dan Data Bisnis Pengguna, yang tercantum di bawah ini.',
        bag2: {
            privacy_content: [
                'File cookie adalah file teks, berisi sejumlah kecil data yang dikirim ke browser Anda dan disimpan di komputer, ponsel, dan perangkat lain apa pun yang digunakan pengguna untuk mengakses Internet. Cookie mengirimkan beberapa informasi ke situs pada setiap kunjungan ke situs. Misalnya, mereka memungkinkan Anda untuk menyimpan pengaturan pencarian yang aman, menampilkan iklan yang bermanfaat, menghitung jumlah kunjungan per halaman, mendaftar ke layanan kami, dan melindungi informasi pengguna. Cookie dapat berupa file cookie dan dapat disimpan di komputer hingga dihapus oleh pengguna, atau mungkin bersifat sementara (yang disebut cookie periodik), di mana cookie tetap berada di komputer hingga browser ditutup. Selain itu, file cookie dibagi menjadi file utama (yang dihasilkan langsung melalui kunjungan situs) dan sub-file (dibuat oleh situs lain).',
                'Kami menggunakan file cookie berikut:',
                'Memblokir atau menghapus cookie, dan membatasi aktivitasnya dapat dilakukan melalui pengaturan browser Pelanggan.'
            ],
            privacy_sub_content: [
                '(1) File cookie penting. Cookie ini diperlukan untuk mengirimkan dan menggunakan layanan yang Diminta di situs web atau Aplikasi. Cookie ini digunakan saat mendaftarkan Pengguna dan memasuki sistem. Tanpa cookie ini, Layanan yang diminta oleh Pengguna tidak akan tersedia. Cookie ini adalah file utama dan dapat bersifat sementara atau persisten. Tanpa mereka, situs web tidak akan berfungsi dengan baik.',
                '(2) Cookie eksploitasi. Cookie ini akan mengumpulkan data tentang statistik penggunaan situs web. Mereka tidak mengumpulkan Data Pribadi dan Bisnis Pengguna. Semua data yang dikumpulkan oleh mereka bersifat statistik dan tidak dapat diidentifikasi. Tujuan menggunakannya adalah untuk:',
                '(3) Cookie operasional. Ini digunakan untuk menyimpan informasi yang diberikan oleh Pengguna dalam memori. (Misalnya, Nama Pengguna, Bahasa, dan Lokasi) File-file ini menggunakan informasi anonim dan tidak melacak aktivitas Pengguna di situs lain. Tujuan menggunakannya adalah untuk:',
                '(4) Cookie iklan. Cookie ini digunakan untuk mengevaluasi kinerja biro iklan. Cookie ini digunakan untuk mengelola konten iklan di situs. Cookie ini ditempatkan di situs oleh pihak ketiga, misalnya oleh pihak ketiga dan agen mereka. Mereka bisa persisten atau sementara. Ini terkait dengan iklan di situs yang disediakan oleh pihak ketiga.',
            ],
            privacy_sub_sub_content: {
                1: [],
                2: [
                    '(a) mendapatkan statistik penggunaan situs.',
                    '(b) Mengevaluasi efektivitas perusahaan periklanan.',
                    'Cookie ini dapat bersifat persisten dan sementara dan dapat berupa file cookie utama atau sekunder.',
                ],
                3: [
                    '(a) Mengingat jika layanan tertentu telah diberikan kepada Pelanggan.',
                    '(b) Meningkatkan kualitas kerjasama dengan situs web, dengan menyimpan prioritas Pengguna',
                    'Cookie ini dapat bersifat persisten dan sementara dan dapat berupa file cookie utama atau sekunder.',
                ],
                4: [],
                5: [],
                6: [],
                7: [],
                8: [],
                9: [],
                10: [],
            }
        },
    },

    pvc_part10: {
        bag1: 'Kami menerima informasi tentang perangkat Pengguna, aplikasi yang diinstal di dalamnya dan akses internet melalui aplikasi seluler. Ini termasuk informasi tentang model perangkat, sistem operasi, informasi browser, alamat IP, dan karakteristik perangkat. Informasi ini tidak termasuk Data Pribadi dan Bisnis.',
        bag2: {
            privacy_content: [],
        }
    },

    pvc_part11: {
        bag1: '',
        bag2: {
            privacy_content: [
                'Kami memperoleh data tentang operator telekomunikasi yang menyediakan Layanan kepada Pengguna melalui Aplikasi atau Situs Web.',
                'Data tersebut tidak termasuk Data Pribadi dan Bisnis Pengguna.',
                'Tujuan memperoleh informasi tersebut adalah untuk secara otomatis mengisi informasi tentang negara tempat tinggal Pengguna dan memilih bahasa antarmuka Pengguna di bagian pengaturan Aplikasi.',
            ],
        }
    },

    pvc_part12: {
        bag1: '',
        bag2: {
            privacy_content: [
                'Kami menyimpan riwayat pesanan Pengguna dan riwayat Permintaan Pelanggan. Riwayat ini mencakup waktu dimulainya Permintaan, alamat tujuan kedatangan kendaraan, alamat tujuan dan rute yang ditempuh, tarif yang berlaku, metode pembayaran, dan informasi lain yang diberikan oleh Pelanggan dan Pengguna.',
                'Tujuan pengumpulan informasi tersebut adalah untuk meningkatkan kualitas layanan melalui pengisian otomatis parameter permintaan dengan informasi yang diberikan sebelumnya untuk mempersingkat waktu permintaan',
            ],
        }
    },

    pvc_part13: {
        bag1: '',
        bag2: {
            privacy_content: [
                'Dengan mengakses dan menerima Kebijakan Privasi ini, Pengguna menyatakan telah membaca, memahami, dan menyetujui semua ketentuan Kebijakan Privasi ini. Secara khusus, Pengguna memberi kami persetujuannya untuk mengumpulkan, menggunakan, membagikan, mengungkapkan, menyimpan, mentransfer, atau melakukan segala jenis pemrosesan Data Pribadi dan Bisnis Pengguna lainnya sesuai dengan Kebijakan Privasi ini.',
                'Jika Pengguna memberikan Data Pribadi dan Bisnis kepada kami yang berkaitan dengan pihak lain, Pengguna telah menyatakan dan menjamin segala bentuk persetujuan dari individu untuk pemrosesan Data Pribadi dan Bisnis pihak lain oleh kami.',
                'Pengguna dapat menarik persetujuannya untuk semua pemrosesan Data Pribadi dan Bisnis Pengguna, dengan mengirimkan pemberitahuan tertulis yang wajar kepada kami. Akibatnya, Pengguna tidak dapat menggunakan Aplikasi bersama dengan Layanan. Setelah Pengguna memilih untuk menarik persetujuannya, kami mungkin dapat terus memproses Data Pribadi dan Bisnis Pengguna sejauh yang diperlukan atau diizinkan oleh hukum dan peraturan yang berlaku.',
            ],
        }
    },

    pvc_part14: {
        bag1: '',
        bag2: {
            privacy_content: [
                'Tanpa mengesampingkan ketentuan mengenai hak Pengguna menurut peraturan perundang-undangan yang berlaku, Pengguna berhak untuk:',
                'Tanpa mengesampingkan ketentuan mengenai Perlindungan Data Pribadi dan Bisnis menurut peraturan perundang-undangan yang berlaku, kami berjanji untuk',
            ],
            privacy_sub_content: {
                1: [
                    '(1) Meminta koreksi dan penghapusan Data Pribadi dan Bisnis Anda (dalam beberapa kasus)',
                    '(2) Kerahasiaan Data Pribadi dan Bisnis',
                    '(3) Mengharuskan kami untuk melengkapi, memblokir, atau menghilangkan data, jika tidak lengkap, tidak tepat, diperoleh dengan cara ilegal atau tidak perlu untuk tujuan pemrosesan sesuai ketentuan, dan juga melakukan aktivitas yang diperlukan oleh hukum untuk melindungi haknya.',
                ],
                2: [
                    '(1) Melindungi Data Pribadi dan Bisnis dari penyalahgunaan/tindakan penyalahgunaan',
                    '(2) Menerapkan langkah-langkah untuk mencegah akses tidak sah ke Data Pribadi dan Bisnis Pengguna.',
                    '(3) menjaga kerahasiaan Data Pribadi dan Bisnis yang diperoleh, dikumpulkan, diproses, dan dianalisis',
                ],
            }
        }
    },

    pvc_part15: {
        bag1: 'Kami berhak untuk mengubah, memperbarui, atau mengubah ketentuan Kebijakan ini setiap saat dengan menempatkan Kebijakan yang diperbarui di Situs Web. Kebijakan Privasi ini dapat diubah dan diperbarui dari waktu ke waktu tanpa pemberitahuan sebelumnya. Kami menyarankan kepada Pengguna untuk membaca dengan seksama dan memeriksa halaman kebijakan privasi ini secara berkala untuk mengetahui setiap perubahannya. Dengan mengakses dan menggunakan aplikasi ini, Pengguna menyetujui setiap perubahan dalam Kebijakan Privasi ini.',
        bag2: {
            privacy_content: [],
        }
    },

    pvc_part16: {
        bag1: 'Jika Anda memiliki pertanyaan atau keluhan apa pun terkait dengan Data Pribadi dan Bisnis Anda, ingin mengajukan permintaan untuk mengakses atau memperbaiki Data Pribadi dan Bisnis Anda, atau ingin menarik persetujuan Anda sehubungan dengan Data Pribadi dan Bisnis Anda. Data Bisnis sedang diproses oleh kami, Anda dapat menghubungi kami melalui email atau telepon menggunakan detail kontak berikut:',
        bag2: {
            privacy_content: [
                'Situs web: https://merchant-id.taximaxim.com/',
                'Email: idmarketplace@taximaxim.com'
            ],
        }
    },
    // text
    // text: {
    //     privacy_policy: [
    //         'I. Ketentuan Umum',
    //         'II. Pengumpulan Data Pribadi dan Data Bisnis: ',
    //         'III. The Personal and/or Business Data Usage: ',
    //         'IV. Personal and/or Business Data Disclosure: ',
    //         'V.	Personal and/or Business Data Production: ',
    //         'VI. Supplementing and Elimination Of Personal and/or Business Data: ',
    //         'VII. Geographical Location Information: ',
    //         'VIII. Jurisdiction and Cross Border Transfer: ',
    //         'IX. Cookies: ',
    //         'X. Infomation On Users Device: ',
    //         'XI. Data About Telecommunication Operator: ',
    //         'XII. Request Records: ',
    //         'XIII. The Acknowledgment, The Consent and Its Withdrawal: ',
    //         'XIV. The Users Rights: ',
    //         'XV. The Change Or Updates Of This Policy: ',
    //         'XVI. Contacs: '
    //     ],
    //     privacy_content: {
    //         1: [
    //             'Untuk tujuan Kebijakan Privasi ini, istilah yang tercantum di bawah ini akan diartikan sebagai berikut:',
    //             '1. Data Pribadi dan Data Bisnis: Setiap informasi yang secara langsung dan tidak langsung terkait dengan Anda sebagai Pengguna Situs Web atau Aplikasi kami, termasuk namun tidak terbatas pada nama Merchant, nomor telepon (akun Whatsapp aktif), bank dan rincian kartu kredit (jika berlaku), alamat email, deskripsi dan kategori Merchant, lokasi geografis, gambar Anda, barang dan harganya, serta deskripsi dan gambar barang.',
    //             '2.	Mitra Pedagang (merchant): Pribadi atau badan, yang telah menyatakan persetujuannya pada perjanjian dan telah menerima hak untuk menggunakan Aplikasi Pedagang, dan oleh karena itu dapat menikmati hak yang diberikan dan memenuhi tanggung jawab dalam konteks penyediaan makanan dan minuman dan bahan makanan untuk memenuhi kebutuhan Klien melalui pesanan mereka dari Aplikasi Seluler. ',
    //             '3.	Mitra Pengemudi: Pribadi yang secara mandiri dan atas kemauannya sendiri setuju untuk menyediakan jasa angkutan dengan sepeda motor.',
    //             '4.	Pelanggan: Pribadi yang telah melakukan permintaan melalui Aplikasi Pelanggan atau situs web kami, dan telah memberikan Data Pribadinya untuk tujuan ini.',
    //             '5.	Layanan: Informasi yang diberikan kepada Pelanggan melalui Aplikasi atau Situs Web kami, agar Permintaan Pelanggan dapat diterima, diproses, dan dikirimkan ke Mitra (baik Mitra Pengemudi maupun Pedagang) dan pelaksanaan Permintaan tersebut dikomunikasikan kembali ke Pelanggan. Subjek dan metode penyediaan layanan akan diidentifikasi sesuai dengan penawaran pelanggan di situs web kami.',
    //             '6.	Pemrosesan Data Pribadi dan Data Bisnis: setiap aktivitas atau kegiatan yang dilakukan pada Data Pribadi dan Data Bisnis, dengan atau tanpa otomatisasi, termasuk pengumpulan, pencatatan, sistematisasi, penyimpanan, pengiriman, pengorganisasian (pembaruan, koreksi), abstraksi, penggunaan, mengomunikasikan (mendistribusikan, menyediakan, mengakses), membuat tidak pribadi, memblokir, menghapus, dan menghilangkan Data Pribadi dan Data Bisnis.',
    //             '7.	Memblokir Data Pribadi dan Data Bisnis: penghentian sementara dalam pemrosesan Data Pribadi dan Data Bisnis (tidak termasuk kasus ketika pemrosesan diperlukan untuk mengidentifikasi Data Pribadi dan Data Bisnis)',
    //             '8.	Menghilangkan Data Pribadi dan Data Bisnis: aktivitas yang mengakibatkan Data Pribadi dan Data Bisnis yang ada tidak dapat dipulihkan dan mengakibatkan penghapusan penyimpanan Data Pribadi dan Data Bisnis.'
    //         ],
    //         2: [
    //             'Data Pribadi dan Data Bisnis Pengguna disimpan dalam penyimpanan elektronik dan diproses oleh sistem otomatis untuk Pemrosesan Data Pribadi dan Data Bisnis',
    //             '1. Kami mengumpulkan Data Pribadi dan Data Bisnis Pengguna ketika Pengguna secara sukarela memberikannya kepada kami. Misalnya, Pengguna dapat memberikan Data Pribadi dan Data Bisnisnya kepada kami ketika Pengguna:',
    //             '(1) mengisi profil pengguna atau formulir pendaftaran',
    //             '(2) memberikan informasi untuk menilai kelayakan Pengguna untuk menyediakan Layanan sebagai Mitra Pedagang',
    //             '(3) berinteraksi dengan laman media sosial kami.',
    //             '(4) berpartisipasi dalam pertandingan atau acara yang diselenggarakan oleh kami; dan',
    //             '(5) memberikan informasi demografis dalam survei.',
    //             '2. Data Pribadi dan Data Bisnis dapat terkumpul melalui operasi Layanan secara normal, data tersebut mencakup, misalnya:',
    //             '(1) Lokasi',
    //             '(2) informasi transaksi dalam Aplikasi (termasuk metode pembayaran)',
    //             '(3) pesan obrolan saat menggunakan fitur komunikasi dalam Aplikasi kami',
    //             '(4) umpan balik, peringkat, dan pujian',
    //             '(5) informasi perangkat pengguna',
    //             '(6) setiap Data Pribadi dan Data Bisnis yang di tangkap melalui interaksi Pengguna dengan kami',
    //             '3. Saat kami mengumpulkan Data Pribadi dan Data Bisnis dari sumber lain, kami memastikan bahwa data yang di serah terimakan kepada kami sesuai dengan hukum yang berlaku. Sumber-sumber tersebut di atas antara lain',
    //             '(1) Program rujukan',
    //             '(2) Mitra bisnis kami',
    //             '(3) Data yang tersedia untuk umum',
    //             '(3) Sumber data pemerintah',
    //             '4. Saat Pengguna mendaftar dan membuat akun di situs web kami, kami mengumpulkan dan memproses Data Pribadi dan Data Bisnis Mitra berikut:',
    //             '(1) Nama Pedagang',
    //             '(2) Nomor telepon yang terdaftar yang juga memiliki akun Whatsapp aktif',
    //             '(3) Email',
    //             '(4) Foto toko Pengguna',
    //             '(5) Informasi lengkap tentang lokasi toko Pengguna',
    //             '(6) Deskripsi Pedagang',
    //             '5. Secara khusus, kami dapat mengumpulkan dan memproses data perangkat Pengguna (lokasi GPS, nomor IMEI) untuk tujuan analisis dan administrasi',
    //             '6. Data Pribadi dan Data Bisnis Pengguna akan kami hapus, dengan contoh berikut:',
    //             '(1) Tiga tahun setelah selesai memberikan layanan',
    //             '(2) Jika Pengguna menarik kembali persetujuannya untuk Memproses Data Pribadi dan Data Bisnisnya.',
    //             '7. Penghapusan Data Pribadi dan Data Bisnis akan dilakukan sedemikian rupa sehingga tidak dapat dipulihkan kembali.',
    //             '8. Jika Data Pribadi dan Data Bisnis adalah anak di bawah umur (yaitu individu di bawah usia 18 tahun) yang diungkapkan kepada kami, Anda dengan ini menyatakan persetujuan Anda untuk pemrosesan Data Pribadi dan Data Bisnis anak di bawah umur sebagai orang tua atau hukum wali sah anak di bawah umur (atau persetujuan yang diperlukan dari orang tua atau wali sah anak di bawah umur) yang menerima dan setuju untuk terikat oleh Kebijakan ini dan bertanggung jawab atas tindakannya. Jika Anda masih di bawah umur dan bermaksud untuk memberikan Data Pribadi dan Data Bisnis Anda kepada kami, dengan ini Anda mengonfirmasi dan mengakui bahwa Anda telah memperoleh persetujuan orang tua atau wali sah Anda untuk terikat oleh Kebijakan ini',
    //         ],
    //         3: [
    //             'Tujuan utama dari Kebijakan Privasi antara Pengguna dan kami ini adalah untuk Pemrosesan Data Pribadi dan Data Bisnis Pengguna, yang tercantum di bawah ini.',
    //             '1. Secara umum, Data Pribadi dan Data Bisnis Pengguna akan digunakan untuk menyediakan, mempersonalisasi, memelihara, dan meningkatkan Layanan kami. Ini termasuk menggunakan Data Pribadi dan Data Bisnis untuk:',
    //             ' (1) memberi Anda pesanan',
    //             ' (2) melibatkan Mitra Pengemudi dalam menyediakan Layanan',
    //             ' (3) membuat, mengelola, dan memperbarui akun Pengguna',
    //             ' (4) memeriksa identitas Pengguna',
    //             ' (5) proses pembayaran',
    //             ' (6) menawarkan, mendapatkan, menyediakan, atau memfasilitasi asuransi untuk pesanan (jika berlaku)',
    //             ' (7) melacak proses pesanan',
    //             ' (8) aktifkan fitur yang mempersonalisasi Aplikasi Anda, seperti daftar item favorit menurut Pelanggan',
    //             ' (9) melakukan operasi internal yang diperlukan untuk menyediakan Layanan kami, termasuk pemecahan masalah bug perangkat lunak dan masalah operasional, melakukan analisis data, pengujian dan penelitian, pemantauan dan analisis tren penggunaan dan aktivitas; dan',
    //             ' (10) melindungi keamanan atau integritas Layanan dan setiap fasilitas atau peralatan yang digunakan untuk menyediakan Layanan.',
    //             '2. Secara Khusus, kami dapat menggunakan Data Pribadi dan Data Bisnis Pengguna untuk tujuan berikut:',
    //             '(a) mengidentifikasi Pengguna dan untuk mendaftarkan, mengelola, memverifikasi, menonaktifkan, atau mengelola akun Pengguna di Aplikasi',
    //             '(b) memfasilitasi atau mengaktifkan verifikasi apa pun yang kami anggap perlu sebelum kami mendaftarkan Pengguna.',
    //             '(c) memungkinkan Pengguna memberikan layanannya kepada Pelanggan',
    //             '(d) memproses, memfasilitasi, dan melengkapi pembayaran hak Pengguna atas layanan yang telah disediakan',
    //             '(e) menyampaikan dan mengirimkan Informasi Pelanggan terkait dengan ketentuan layanan, termasuk untuk menyampaikan Permintaan Pelanggan kepada Pengguna dan untuk memfasilitasi penerimaan Pengguna atas Permintaan tersebut.',
    //             '(f) memberi pemberitahunan dan pembaruan pada Aplikasi atau perubahan peraturan kami sesuai dengan layanan yang akan disediakan kepada Pengguna',
    //             '(g) memberikan laporan kepada Pengguna sehubungan dengan layanannya yang telah disediakan',
    //             '(h) memproses dan menanggapi umpan balik dari Pengguna atas layanan yang telah Pengguna berikan',
    //             '(i) memantau aktivitas Pengguna, perilaku, dan data demografis termasuk tren dan penggunaan berbagai layanan yang tersedia di Aplikasi',
    //             '(j) mengembangkan, menguji, meningkatkan, mempersonalisasi, memelihara, dan meningkatkan Aplikasi, situs web, dan Layanan kami untuk memuaskan Pengguna',
    //             '(k) untuk memastikan keselamatan dan keamanan Layanan kami dan semua pengguna. Ini termasuk:',
    //             'i.	Menyaring pengemudi dan mitra pengiriman sebelum mereka menggunakan Layanan kami',
    //             'ii. Memastikan identitas saat masuk ke Aplikasi atau Situs Web',
    //             'iii. Menggunakan perangkat, lokasi, profil, penggunaan, dan Data Pribadi dan Data Bisnis lainnya untuk mencegah, mendeteksi, dan memerangi penipuan atau aktivitas yang tidak aman',
    //             'iv. Memantau kepatuhan terhadap Aturan Kerja, kebijakan kami; dan',
    //             'v.	Mendeteksi, mencegah dan mengambil tindakan yang diperlukan atas potensi kegiatan kriminal yang sedang berlangsung.',
    //             'vi. Berbagi lokasi Mitra Pengemudi dan Pelanggan saat Tombol Darurat diaktifkan',
    //             '(l) menyelesaikan masalah dukungan pelanggan. Kita dapat:',
    //             'i.	Menyelidiki dan mengatasi masalah',
    //             'ii. Memantau dan meningkatkan respons dukungan pelanggan kami',
    //             'iii. Menanggapi pertanyaan, komentar, dan memberikan umpan balik; dan',
    //             'iv. Memberitahukan Anda tentang langkah-langkah yang diambil untuk menyelesaikan masalah dukungan pelanggan.',
    //             '(m) menyelidiki dan menyelesaikan klaim atau perselisihan, sebagaimana diizinkan dan diwajibkan oleh hukum yang berlaku.',
    //             '(n) Melakukan pengujian, penelitian, analisis dan pengembangan produk. Hal ini memungkinkan kami untuk memahami dan menganalisis kebutuhan dan preferensi Pengguna, melindungi Data Pribadi dan Data Bisnis Anda, memperbaiki dan meningkatkan keselamatan dan keamanan Layanan kami, mengembangkan fitur, produk, dan layanan baru, serta memfasilitasi solusi asuransi dan keuangan.',
    //             '3. Kami juga dapat menggunakan Data Pribadi dan Data Bisnis Anda ketika kami diminta, disarankan, direkomendasikan, diharapkan, atau diminta untuk melakukannya oleh penasihat hukum kami atau otoritas hukum, peraturan, pemerintah, otoritas lokal atau asing lainnya. Misalnya, kami dapat menggunakan Data Pribadi dan Data Bisnis Anda untuk:',
    //             '(1) Mematuhi perintah pengadilan atau persyaratan hukum, pemerintah, atau peraturan lainnya',
    //             '(2) Menegakkan Aturan Kerja kami atau perjanjian lainnya; dan',
    //             '(3) melindungi hak atau properti kami jika terjadi klaim atau perselisihan.',
    //             '4. Kami dapat menggunakan Data Pribadi dan Data Bisnis Anda untuk tujuan periklanan dan produk, layanan, acara, atau promosi mitra bisnis. Misalnya:',
    //             '(1) Mengirimi Anda peringatan, buletin, pembaruan, surat, materi promosi, hak istimewa, salam perayaan; dan',
    //             '(2) Memberikan undangan, dan mengelola partisipasi Anda dalam acara atau aktivitas kami.',
    //             '5. Kami akan memberitahukan Anda tentang iklan tersebut melalui postingan, layanan pesan singkat, pemberitahuan dalam aplikasi, layanan pesan online serta pemberitahuan push secara manual dan melalui email. Jika Anda ingin berhenti berlangganan untuk pemasaran dan promosi, Anda dapat mengklik tautan berhenti berlangganan di email atau pesan yang dikirimkan. Atau, Anda juga dapat memperbarui preferensi Anda di pengaturan Aplikasi kami. Penyediaan Data Pribadi dan Data Bisnis Anda kepada kami adalah atas dasar sukarela. Namun, jika Anda tidak memberikan Data Pribadi dan Data Bisnis Anda atau jika Data Pribadi dan Data Bisnis Anda tidak lengkap saat diberikan kepada kami, akan memengaruhi kemampuan kami untuk memenuhi Tujuan yang disebutkan di atas dan untuk Anda menikmati manfaat Layanan kami.',
    //             '6. Kami dapat menggunakan Data Pribadi dan Data Bisnis Pengguna tanpa persetujuan, dengan mengandalkan Kepentingan Sah atau pengecualian lain yang diberikan oleh undang-undang privasi data yang berlaku, untuk tujuan seperti pencegahan penipuan.',
    //         ],
    //         4: [
    //             'Kami perlu membagikan Data Pribadi dan Bisnis dengan para pihak untuk sejumlah tujuan. Pihak-pihak tersebut antara lain:',
    //             '1. Pengguna Aplikasi atau Situs Web lainnya. Misalnya, kepada Pengguna, kami dapat membagikan nama Pelanggan dan lokasi pengiriman barang yang dibeli, serta nama, lokasi, dan nomor plat kendaraan Mitra Pengemudi. Kami dapat membagikan Data Pribadi dan Bisnis Anda dengan Pelanggan Anda dan Mitra Pengemudi yang mengambil pesanan, termasuk nama dan foto Anda, lokasi Anda, daftar barang dengan gambar dan deskripsi, harga barang, dan peringkat Anda.',
    //             '2. Pihak ketiga yang berhubungan dengan pemesanan. Misalnya, kami dapat membagikan lokasi Anda dengan pihak ketiga ketika Pelanggan atau Mitra Pengemudi mengaktifkan Tombol Darurat.',
    //             '3. Anak perusahaan dan afiliasi. Kami membagikan Data Pribadi dan Bisnis dengan anak perusahaan kami, perusahaan terkait, entitas yang dikendalikan bersama, dan afiliasi.',
    //             '4. Mitra bisnis PT TPI, Kami dapat memberikan Data Pribadi dan Bisnis kepada mitra bisnis kami (secara mandiri atau atas permintaan Anda). termasuk:',
    //             '(1) Pemroses dan fasilitator pembayaran',
    //             '(2) Layanan pemeriksaan latar belakang dan anti pencucian uang',
    //             '(3) Penyedia penyimpanan cloud',
    //             '(4) Mitra pemasaran dan penyedia platform pemasaran',
    //             '(5) Penyedia analitik data',
    //             '(6) Penyedia asuransi dan keuangan',
    //             '(7) Mitra yang terintegrasi dengan Aplikasi kami atau sebaliknya, dan',
    //             '(8) Mitra yang bekerja sama dengan kami untuk memberikan promosi, kompetisi, atau layanan khusus lainnya.',

    //         ],
    //         5: [
    //             '1. Kami akan melakukan semua upaya secara hukum untuk melindungi dan mengamankan Data Pribadi dan Bisnis Pengguna terhadap pemrosesan Data oleh orang yang tidak berwenang dan terhadap pemrosesan yang melanggar hukum atau risiko serupa lainnya.',
    //             '2. Kami menerapkan langkah-langkah berikut untuk mencegah akses yang tidak berwenang ke Data Pribadi dan Bisnis Pengguna:',
    //             '(a) Identifikasi ancaman keamanan dalam sistem saat memproses Data Pribadi dan Bisnis',
    //             '(b) Menerapkan sistem keamanan untuk tempat yang berisi sistem informasi untuk mencegah orang yang tidak berwenang memasuki tempat tersebut.',
    //             '(c) Menyediakan tempat penyimpanan Data Pribadi dan Bisnis',
    //             '(d) Mengonfirmasi daftar orang yang memiliki akses ke Data Pribadi dan Bisnis berdasarkan pekerjaan mereka.',
    //             '(e) Menggunakan sarana yang diperlukan untuk melindungi Data Pribadi dan Bisnis dari akses yang tidak memiliki wewenang.',
    //             '(f) Evaluasi langkah-langkah yang digunakan.',
    //             '(g) Mendeteksi akses yang tidak sah ke Data Pribadi dan Bisnis.',
    //             '(h) Memulihkan data yang telah dihapus atau rusak karena akses yang tidak sah (jika pemulihan tersebut memungkinkan).',
    //             '(i) Menjalankan regulasi yang ketat atas akses ke Data Pribadi dan Bisnis dalam sistem setelah diproses.',
    //             '(j) Mengontrol langkah-langkah yang digunakan untuk mengamankan Data Pribadi dan Bisnis serta tingkat keamanan sistem informasi.',
    //             '3. Transmisi informasi melalui Internet tidak sepenuhnya aman, meskipun kami akan melakukan yang terbaik untuk melindungi Data Pribadi dan Bisnis Pengguna, kami tidak dapat menjamin keamanan Data Pribadi dan Bisnis Pengguna yang dikirimkan melalui platform online mana pun. Dengan demikian, setiap transmisi tetap menjadi risiko Pengguna sendiri.',
    //         ],
    //         6: [
    //             'Pengguna dapat meminta kami untuk melengkapi, memblokir, atau menghilangkan Data Pribadi dan Bisnis, jika tidak lengkap, tidak tepat, diperoleh dengan cara ilegal atau tidak diperlukan untuk tujuan proses data. Selain itu, Pengguna dapat menarik kembali persetujuannya untuk memproses data tersebut. Hal ini dilakukan melalui pengiriman permintaan tertulis kepada kami dengan surat dengan konfirmasi pengiriman atau dengan menyerahkan secara pribadi permintaan tersebut di lingkungan PT TPI. Alamat kantor kami disediakan di situs web. Permintaan tersebut harus berisi sebagai berikut: Nomor dokumen identitas utama Pengguna atau wakilnya, informasi mengenai tanggal penerbitan dokumen tersebut dan organisasi penerbitnya, alamat tempat tinggal Pengguna, informasi yang mengkonfirmasikan Pengguna dan nomor identifikasi Pengguna, atau informasi yang menegaskan dengan cara apa pun pemrosesan Data Pribadi dan Bisnis Pengguna, permintaan untuk melengkapi, memblokir atau menghilangkan Data Pribadi dan Bisnis Pengguna atau pemberitahuan untuk mencabut perjanjian untuk memproses data tersebut, tanda tangan Pengguna atau wakilnya. PT TPI wajib memberikan jawaban dalam waktu 30 hari sejak diterimanya permintaan atau pemberitahuan tersebut.',
    //             'Kami akan menyimpan Data Pribadi dan Bisnis Anda selama diperlukan atau diizinkan dengan konsisten untuk jangka waktu 5 (lima) tahun sesuai dengan hukum yang berlaku.',
    //         ],
    //         7: [
    //             'Untuk memberikan layanan kepada Pelanggan, kami dapat membagikan informasi lokasi geografis Pengguna kepada Pelanggan dan Mitra Pengemudi.',
    //         ],
    //         8: [
    //             'Data Pribadi dan Bisnis Anda dapat disimpan dan diproses di negara, negara bagian, dan kota mana pun di mana kami memiliki fasilitas atau di mana kami melibatkan penyedia layanan. Dengan menggunakan Aplikasi, Anda telah memberikan persetujuan Anda untuk mentransfer informasi ke negara-negara di luar negara tempat tinggal Anda, yang dapat memiliki aturan perlindungan data yang berbeda dari Indonesia.',
    //         ],
    //         9: [
    //             'File cookie adalah file teks, berisi sejumlah kecil data yang dikirim ke browser Anda dan disimpan di komputer, ponsel, dan perangkat lain apa pun yang digunakan pengguna untuk mengakses Internet. Cookie mengirimkan beberapa informasi ke situs pada setiap kunjungan ke situs. Misalnya, mereka memungkinkan Anda untuk menyimpan pengaturan pencarian yang aman, menampilkan iklan yang bermanfaat, menghitung jumlah kunjungan per halaman, mendaftar ke layanan kami, dan melindungi informasi pengguna. Cookie dapat berupa file cookie dan dapat disimpan di komputer hingga dihapus oleh pengguna, atau mungkin bersifat sementara (yang disebut cookie periodik), di mana cookie tetap berada di komputer hingga browser ditutup. Selain itu, file cookie dibagi menjadi file utama (yang dihasilkan langsung melalui kunjungan situs) dan sub-file (dibuat oleh situs lain).',
    //             '2. Kami menggunakan file cookie berikut:',
    //             '(1) File cookie penting. Cookie ini diperlukan untuk mengirimkan dan menggunakan layanan yang Diminta di situs web atau Aplikasi. Cookie ini digunakan saat mendaftarkan Pengguna dan memasuki sistem. Tanpa cookie ini, Layanan yang diminta oleh Pengguna tidak akan tersedia. Cookie ini adalah file utama dan dapat bersifat sementara atau persisten. Tanpa mereka, situs web tidak akan berfungsi dengan baik.',
    //             '(2) Cookie eksploitasi. Cookie ini akan mengumpulkan data tentang statistik penggunaan situs web. Mereka tidak mengumpulkan Data Pribadi dan Bisnis Pengguna. Semua data yang dikumpulkan oleh mereka bersifat statistik dan tidak dapat diidentifikasi. Tujuan menggunakannya adalah untuk:',
    //             '(a) mendapatkan statistik penggunaan situs.',
    //             '(b) Mengevaluasi efektivitas perusahaan periklanan.',
    //             'Cookie ini dapat bersifat persisten dan sementara dan dapat berupa file cookie utama atau sekunder.',
    //             '(3) Cookie operasional. Ini digunakan untuk menyimpan informasi yang diberikan oleh Pengguna dalam memori. (Misalnya, Nama Pengguna, Bahasa, dan Lokasi) File-file ini menggunakan informasi anonim dan tidak melacak aktivitas Pengguna di situs lain. Tujuan menggunakannya adalah untuk:',
    //             '(a) Mengingat jika layanan tertentu telah diberikan kepada Pelanggan.',
    //             '(b) Meningkatkan kualitas kerjasama dengan situs web, dengan menyimpan prioritas Pengguna',
    //             'Cookie ini dapat bersifat persisten dan sementara dan dapat berupa file cookie utama atau sekunder',
    //             '(4) Cookie iklan. Cookie ini digunakan untuk mengevaluasi kinerja biro iklan. Cookie ini digunakan untuk mengelola konten iklan di situs. Cookie ini ditempatkan di situs oleh pihak ketiga, misalnya oleh pihak ketiga dan agen mereka. Mereka bisa persisten atau sementara. Ini terkait dengan iklan di situs yang disediakan oleh pihak ketiga.',
    //             '3. Memblokir atau menghapus cookie, dan membatasi aktivitasnya dapat dilakukan melalui pengaturan browser Pelanggan.',
    //         ],
    //         10: [
    //             'Kami menerima informasi tentang perangkat Pengguna, aplikasi yang diinstal di dalamnya dan akses internet melalui aplikasi seluler. Ini termasuk informasi tentang model perangkat, sistem operasi, informasi browser, alamat IP, dan karakteristik perangkat. Informasi ini tidak termasuk Data Pribadi dan Bisnis.',
    //         ],
    //         11: [
    //             '1. Kami memperoleh data tentang operator telekomunikasi yang menyediakan Layanan kepada Pengguna melalui Aplikasi atau Situs Web.',
    //             '2. Data tersebut tidak termasuk Data Pribadi dan Bisnis Pengguna.',
    //             '3. Tujuan memperoleh informasi tersebut adalah untuk secara otomatis mengisi informasi tentang negara tempat tinggal Pengguna dan memilih bahasa antarmuka Pengguna di bagian pengaturan Aplikasi.',
    //         ],
    //         12: [
    //             '1. Kami menyimpan riwayat pesanan Pengguna dan riwayat Permintaan Pelanggan. Riwayat ini mencakup waktu dimulainya Permintaan, alamat tujuan kedatangan kendaraan, alamat tujuan dan rute yang ditempuh, tarif yang berlaku, metode pembayaran, dan informasi lain yang diberikan oleh Pelanggan dan Pengguna.',
    //             '2. Tujuan pengumpulan informasi tersebut adalah untuk meningkatkan kualitas layanan melalui pengisian otomatis parameter permintaan dengan informasi yang diberikan sebelumnya untuk mempersingkat waktu permintaan',
    //         ],
    //         13: [
    //             '1. Dengan mengakses dan menerima Kebijakan Privasi ini, Pengguna menyatakan telah membaca, memahami, dan menyetujui semua ketentuan Kebijakan Privasi ini. Secara khusus, Pengguna memberi kami persetujuannya untuk mengumpulkan, menggunakan, membagikan, mengungkapkan, menyimpan, mentransfer, atau melakukan segala jenis pemrosesan Data Pribadi dan Bisnis Pengguna lainnya sesuai dengan Kebijakan Privasi ini.',
    //             '2. Jika Pengguna memberikan Data Pribadi dan Bisnis kepada kami yang berkaitan dengan pihak lain, Pengguna telah menyatakan dan menjamin segala bentuk persetujuan dari individu untuk pemrosesan Data Pribadi dan Bisnis pihak lain oleh kami.',
    //             '3. Pengguna dapat menarik persetujuannya untuk semua pemrosesan Data Pribadi dan Bisnis Pengguna, dengan mengirimkan pemberitahuan tertulis yang wajar kepada kami. Akibatnya, Pengguna tidak dapat menggunakan Aplikasi bersama dengan Layanan. Setelah Pengguna memilih untuk menarik persetujuannya, kami mungkin dapat terus memproses Data Pribadi dan Bisnis Pengguna sejauh yang diperlukan atau diizinkan oleh hukum dan peraturan yang berlaku.',
    //         ],
    //         14: [
    //             '1. Tanpa mengesampingkan ketentuan mengenai hak Pengguna menurut peraturan perundang-undangan yang berlaku, Pengguna berhak untuk:',
    //             '(1) Meminta koreksi dan penghapusan Data Pribadi dan Bisnis Anda (dalam beberapa kasus)',
    //             '(2) Kerahasiaan Data Pribadi dan Bisnis',
    //             '(3) Mengharuskan kami untuk melengkapi, memblokir, atau menghilangkan data, jika tidak lengkap, tidak tepat, diperoleh dengan cara ilegal atau tidak perlu untuk tujuan pemrosesan sesuai ketentuan, dan juga melakukan aktivitas yang diperlukan oleh hukum untuk melindungi haknya.',
    //             '2. Tanpa mengesampingkan ketentuan mengenai Perlindungan Data Pribadi dan Bisnis menurut peraturan perundang-undangan yang berlaku, kami berjanji untuk',
    //             '(1) Melindungi Data Pribadi dan Bisnis dari penyalahgunaan/tindakan penyalahgunaan',
    //             '(2) Menerapkan langkah-langkah untuk mencegah akses tidak sah ke Data Pribadi dan Bisnis Pengguna.',
    //             '(3) menjaga kerahasiaan Data Pribadi dan Bisnis yang diperoleh, dikumpulkan, diproses, dan dianalisis',
    //         ],
    //         15: [
    //             'Kami berhak untuk mengubah, memperbarui, atau mengubah ketentuan Kebijakan ini setiap saat dengan menempatkan Kebijakan yang diperbarui di Situs Web. Kebijakan Privasi ini dapat diubah dan diperbarui dari waktu ke waktu tanpa pemberitahuan sebelumnya. Kami menyarankan kepada Pengguna untuk membaca dengan seksama dan memeriksa halaman kebijakan privasi ini secara berkala untuk mengetahui setiap perubahannya. Dengan mengakses dan menggunakan aplikasi ini, Pengguna menyetujui setiap perubahan dalam Kebijakan Privasi ini.',
    //         ],
    //         16: [
    //             'jika Anda memiliki pertanyaan atau keluhan apa pun terkait dengan Data Pribadi dan Bisnis Anda, ingin mengajukan permintaan untuk mengakses atau memperbaiki Data Pribadi dan Bisnis Anda, atau ingin menarik persetujuan Anda sehubungan dengan Data Pribadi dan Bisnis Anda. Data Bisnis sedang diproses oleh kami, Anda dapat menghubungi kami melalui email atau telepon menggunakan detail kontak berikut:',
    //             'Situs web: https://merchant-id.taximaxim.com/',
    //             'Email: idmarketplace@taximaxim.com',
    //             'The original of this Policy is written in the English language. In the event of any conflict between the English and other language versions, the English version shall prevail.'
    //         ],
    //     },
    // },
}

export default privacy