const En = {
  languageModal: {
    textTitle: "Por favor, seleccione su idioma y país",
    textChooseCity: "Elegir ciudad",
    search: "Búsqueda",
  },
  navbar: {
    textItem1: "TUTORIALES",
    textItem2: "CONTACTOS",
    textButton: "Registrarse ahora",
    textRegis: "Registro",
    textRegistration: "Cómo añadir sus productos",
    textProcess: "Cómo procesar pedidos",
    textOrders: "(Para clientes) Cómo hacer pedidos",
    textComplete: "Cómo completar pedidos",
    textSm: "SÍGANOS EN LAS REDES SOCIALES",
    textSm1:
      "Obtenga consejos y recomendaciones sobre cómo utilizar la aplicación y el servicio",
  },
  footer: {
    text: "Maxim 2022 - todos los derechos reservados",
    textPrivacy: "Política de Privacidad",
  },
  qna: {
    textTitle: "Preguntas más frecuentes",
    textContent1: "¿Puedo registrar más de un comerciante?",
    textContent2: "¿Cuál es su comisión?",
    textContent3: "¿Quién paga al comerciante?",
    textContent4: "¿Cómo abro mi punto de venta en la aplicación?",
    textContent5: "¿Cómo elimino mis productos en la aplicación?",
    textContent6: "¿Cómo puedo cambiar la secuencia de las categorías?",
    textSubcontent1:
      "Puede hacerlo, pero para cada comerciante que registre debe utilizar una dirección de correo electrónico diferente .",
    textSubcontent2:
      "Actualmente, nuestro Servicio es libre de comisiones. Puede establecer el precio de los platos como en el menú.",
    textSubcontent3:
      "El conductor paga por el pedido con su propio dinero, luego recibe ese dinero del cliente.",
    textSubcontent4:
      "Haga clic en Abrir si está listo para aceptar pedidos y haga clic en Cerrar cuando termine de trabajar.",
    textSubcontent5:
      "De momento no puede hacerlo. Simplemente desactive su producto o reemplácelo por otro. ",
    textSubcontent6:
      "Las categorías se clasifican de la más antigua a la más reciente, lamentablemente, usted no puede cambiar su secuencia por su propia cuenta.",
  },
  guides: {
    register: {
      textStep1:
        "Complete sus datos: introduzca la contraseña que utilizará para iniciar sesión, así como su número de contacto.",
      textStep2: "Indique la dirección de su café o punto de entrega.",
      textStep3: "Cargue el logotipo de su empresa o la foto de su tienda.",
      textStep4:
        "¡Todo está listo! Ahora puede proceder a cargar sus productos.",
    },
    addProduct: {
      textStep1:
        "Para empezar, crea la categoría del producto, por ejemplo, Snacks. Puede añadir tantas categorías cuantas necesite.",
      textStep2:
        "Haga clic en “+” junto al nombre de la categoría para añadir sus productos. ",
      textStep3_1:
        "Introduzca la información sobre su producto, establezca un descuento, si es que desea ofrecer uno. Un descuento y una foto de sus productos de alta calidad los destacarán de entre otros.",
      textStep3_2:
        "Si marca su producto como Recomendado, se visualizará entre los primeros en su tienda.",
    },
    processOrder: {
      textStep1:
        "Haga clic en Abrir para comenzar a trabajar. Cuando haya un nuevo pedido, recibirá una notificación.",
      textStep2_1:
        'Haga clic en "Confirmar el pedido" para ejecutarlo, o "Cancelar el pedido" si, por ejemplo, temporariamente su producto está agotado.',
      textStep2_2:
        "Si no confirma un pedido en tres minutos, aquel se cancelará automáticamente.",
      textStep3:
        "Le recomendamos que espere al mensajero antes de empezar a preparar el pedido.",
    },
    placeOrder: {
      textStep1:
        'El cliente selecciona la tasa de Foods&Goods y toca "Qué comprar"',
      textStep2:
        "Pueden seleccionar las tiendas de la lista o buscar un producto específico.",
      textStep3:
        "Los productos marcados como Recomendados son los primeros en aparecer.",
      textStep4:
        "El cliente puede especificar la cantidad de productos que desea comprar, el costo será recalculado automáticamente.",
      textStep5:
        "La app tiene todas las funciones para la comodidad del cliente, tales como hacer un pedido para otra persona o elegir un horario de entrega cómodo.",
      textStep6:
        "El cliente puede seguir la ruta del mensajero que lleva su pedido en un mapa en tiempo real.",
    },
  },
  home: {
    card1: {
      textTitle: "Venda sus productos con Maxim",
      textContent:
        "Acceda a una audiencia activa con poder adquisitivo sin costos adicionales.",
      textButton: "Registrarse ahora",
    },
    card2: {
      textTitle: "Obtener más clientes",
      textContent1:
        "Publique sus productos para que los vean miles de usuarios de la aplicación Maxim.",
      textContent2:
        "Conozca más sobre todas las ventajas de cooperación con nosotros.",
      textContentTitle1: "Sin comisión",
      textContentTitle2: "Entrega rápida",
      textContentTitle3: "Publicidad gratuita",
      textSubcontent1:
        "No cobramos el porcentaje de las ventas: recibimos todos los ingresos de la venta de los productos y fijamos los precios como en el menú para atraer a los clientes.",
      textSubcontent2:
        "Su pedido será entregado por el mensajero más cercano al punto de recogida. El cliente paga la entrega. ",
      textSubcontent3:
        "Haremos un banner conjunto para su negocio y publicaremos la información sobre su empresa en nuestras aplicaciones para conductores y clientes.",
    },
    card3: {
      textTitle: "Pedidos desde una aplicación útil",
      textContent1:
        "Añada tantos puntos de venta de una misma marca cuantos necesite. ",
      textContent2:
        "Establezca todas las categorías que desee. Por ejemplo, bebidas, sopas, segundo plato, ensaladas, etc. ",
      textContent3:
        "Suba buenas fotos para destacarse entre las demás empresas. ",
      textContent4: "Ofrezca un descuento para atraer más clientes.",
      textContent5:
        "Analice la estadística de compras para ofrecer los productos más populares.",
    },
    card4: {
      textTitle: "¡Comience ahora!",
      textContent1: "Registrarse",
      textContent2: "Descargue nuestra app",
      textContent3: "Añada sus productos",
      textContent4: "¡Espere por su primer cliente!",
      textButton: "Registrarse ahora",
    },
    card5: {
      textTitle: "Publicidad offline gratuita",
      textContent1:
        "Destaque su negocio de entre otros. Le haremos un diseño y un banner conjunto de forma gratuita. Esto le ayudará a atraer clientes y a informarles sobre su servicio de pedidos en línea. ",
      textContent2:
        "Los comerciantes registrados pueden solicitar un banner. Póngase en contacto con una de nuestras oficinas.",
      textButton: "Ver direcciones de las oficinas",
    },
    card6: {
      textTitle: "Guías detalladas",
      textContent1: "Aprenda a utilizar nuestro servicio.",
      textSubcontent1: "Cómo registrarse",
      textSubcontent2: "Cómo añadir productos",
      textSubcontent3: "Cómo procesar pedidos",
      textSubcontent4: "(Para clientes) Cómo hacer pedidos",
      textSubcontent5: "(Para mensajeros) Cómo entregar pedidos",
    },
  },
  contact: {
    card1: {
      textTitle: "Oficina",
      textContent:
        "Jl. KH. Noer Ali No. 192, Kayuringin Jaya, Kec. Bekasi Sel., Kota Bks, West Java 17144",
      textPhone: "+ 6221-5092-8720",
    },
  },
  addgoods: {
    card1: {
      textTitle: "Cómo añadir sus productos en la aplicación MaximMerchant",
      textContent1:
        "En la pestaña PRODUCTO, seleccione CATEGORÍA, haga clic en “+” y crea una nueva categoría.",
      textContent2: "Crea tantas categorías cuantas necesite.",
      textContent3:
        'En la pestaña PRODUCTO, seleccione MENÚ y haga clic en "+".',
      textContent4_0:
        "Complete todos los campos. Si desea que sus productos se muestren en la página de inicio del comerciante, active la recomendación. En la descripción puede especificar el nombre del producto.",
      textContent4_1:
        "Pulse en el interruptor para activar el producto. Ahora, los clientes lo verán en su aplicación. Actualmente, no puede eliminar sus productos, pero puede desactivarlos o sustituirlos por otros.",
      textContent4_2:
        "Haga clic en ABRIR cuando esté listo para empezar a aceptar pedidos. Después de haber terminado de trabajar, asegúrese de hacer clic en CERRAR.",
    },
    card2: {
      textTitle: "¿Listo para comenzar?",
      textContent:
        "¡Regístrese y comience a promocionar su negocio con nosotros!",
      textButton: "Registrarse",
    },
  },
  merchant: {
    product: {
      labelShowCustomer: "Mostrar a los clientes",
      labelName: "Nombre del producto",
      labelDesc: "Descripción",
      labelCat: "Categoría",
      labelPrice: "Precio",
      labelDisc: "Descuento",
      labelDiscAmount: "Importe del descuento",
      labelRecommend: "Recomendado",
      labelChoosePhoto: "Seleccionar foto",
      labelSaveAdd: "Guardar el producto",
      labelSaveEdit: "Guardar cambios",
      labelRequired: "Obligatorio",
      labelNotRequired: "No es necesario",
      LabelCancelEdit: "Cancelar",
      LabelVariationAttach: "Variante adjunta",
    },
    btn: {
      pic: "Seleccionar foto",
      submit: "Enviar",
      close: "Cerrar",
      add_more: "Añadir más",
      schedule: "Añadir horario nuevo",
      edit: "Editar",
      delete: "Eliminar",
      variationAttach:
        "Haga clic aquí para ver la variante adjunta a este artículo",
    },
    schedule: {
      text: {
        title: "Horarios de apertura",
        add: { title: "Añadir horario" },
        edit: { title: "Editar horarios" },
        delete: {
          title: "Eliminar horario",
          alert: "¿Está seguro de que desea eliminar los datos?",
        },
      },
      day: {
        sunday: "Domingo",
        monday: "Lunes",
        tuesday: "Martes",
        wednesday: "Miércoles",
        thursday: "Jueves",
        friday: "Viernes",
        saturday: "Sábado",
      },
      form: {
        day: "Día",
        open: "Horarios de apertura",
        close: "Horarios de cierre",
      },
    },
    err: {
      pic_fail: `No se puede subir la imagen, por favor, utilice otra foto`,
      empty: `El formulario no debe contener espacios en blanco`,
      no_pic: "Seleccione una foto para subir",
      get: "Error al obtener datos",
      no_data: "No hay datos",
      add: "Error al añadir datos",
      edit: "Error al editar datos",
      delete: "Error al eliminar datos",
      categoryFetch:
        "No se ha podido obtener la categoría, por favor, intente iniciar sesión de nuevo",
      variationFetch:
        "No se ha podido obtener la variante, por favor, intente iniciar sesión de nuevo",
      productFetch:
        "No se ha podido obtener el producto, por favor, intente iniciar sesión de nuevo",
      connectedFetch:
        "No se ha podido obtener la variante adjunta, por favor, intente iniciar sesión de nuevo",
      processImage:
        "No se ha podido precesar la imagen, por favor, intente subir una imagen nueva",
    },
  },
  register: {
    err: {
      title: "¡Error!",
      no_pic: "Seleccione una foto para subir",
      sent: "Error al enviar datos al servidor",
      email: "Correo electrónico ya registrado",
      empty: `El formulario no debe contener espacios en blanco`,
      no_loc:
        "Elija su ubicación en el mapa (pulse el mapa dos veces para obtener su ubicación actual o mueva el puntero hasta su ubicación)",
      required: "Campo obligatorio",
      email_not_valid: "Correo inválido",
      pass_not_strong:
        "La contraseña no es lo suficientemente segura. Debe constar de al menos 7 caracteres alfanuméricos y alternar entre mayúsculas, minúsculas y caracteres especiales (!@#$%&*+=-)",
      pass_strong: "Contraseña segura",
      pass_perfect: "Contraseña perfecta",
    },
    btn: {
      close: "Cerrar",
      continue: "Continuar",
      back: "Paso anterior",
      next: "Siguiente paso",
      register: "Registrarse",
      photo: "Seleccionar foto",
    },
    text: {
      step: {
        first: "Paso 1 de 3",
        second: "Paso 2 de 3",
        third: "Paso 3 de 3",
        done: "Listo",
      },
      title: {
        merchant_info: "Información del comerciante",
        merchant_location: "Ubicación de la tienda",
        merchant_picture: "Imagen de la tienda",
      },
      note: {
        registe_image:
          "Puede subir su logotipo o la imagen de su producto principal.",
        agreement: "Haciendo clic en Registrarse, acepto",
        tnc: "Términos y condiciones",
        done: "Registro exitoso. Por favor, espere a que confirmemos su cuenta para empezar a trabajar, esto puede tomar un par de días. Le llamaremos o le enviaremos un correo electrónico en confirmación.",
        doneAndDownload:
          "Mientras tanto, puede empezar a preparar sus productos para la venta descargando nuestra aplicación y añadiéndolos allí.",
      },
    },
    form: {
      phone: "Número de teléfono",
      name: "Nombre de la tienda",
      email: "Correo electrónico",
      desc: "Descripción",
      password: "Contraseña",
      referral: "Referencia (si la tiene)",
      country: "País",
      city: "Ciudad",
      district: "Distrito",
      address: "Dirección",
    },
    question: {
      any: "¿Tiene preguntas?",
      check: "Vea nuestros tutoriales.",
      button: "Ver tutoriales",
    },
  },
  webView: {
    complete_add_title: "¡PRODUCTO AGREGADO CON ÉXITO!",
    complete_add_description:
      "Producto agregado. Puedes agregar más o puedes cerrar esta página.",
    complete_edit_title: "¡EDICIÓN DE PRODUCTO EXITOSA!",
    complete_edit_description: "Producto editado. Puedes cerrar esta página.",
    imageUploadError: "¡El archivo de imagen es demasiado grande! Máximo 5 MB.",
  },
};
export default En;
