import { Navbar, Container, Nav, NavDropdown, Row, Col } from "react-bootstrap"
import { useMediaQuery } from "react-responsive"
import logomaxim from '../../../assets/image/logo.png'
import React from "react"
import { ModalChooser, ModalInitial } from "../chooser/modalInitial"
import { t } from '../../../misc/utils'
import { useDispatch } from "react-redux"
import { getCity } from "../../../application/action"
import { TopNavLanguage, TopNavHome } from "../.."

const TopNavMain = () => {
    const mobile = useMediaQuery({
        query: '(max-width:1235px)'
    })
    const smallDesktop = useMediaQuery({
        query: '(max-width:1235px)'
    })

    const { city, country, lang } = {
        city: JSON.parse(localStorage.getItem('city')),
        country: JSON.parse(localStorage.getItem('country')),
        lang: localStorage.getItem('language')
    }

    const [initialModal, setInitialModal] = React.useState(false)
    const [modalChooser, setModalChooser] = React.useState(false)
    const [modalType, setModalType] = React.useState(null)
    const dispatch = useDispatch()

    React.useEffect(() => {
        if (!city || !country) {
            setInitialModal(true)
        }
    }, [])

    const handleModalChooser = (type) => {
        switch (type) {
            case 'city':
                setInitialModal(true)
                break;
            case 'country' : 
            localStorage.removeItem('country')
            localStorage.removeItem('city')
            setInitialModal(true)
                break
            default:
                break;
        }
        // setModalChooser(!modalChooser)
        // setModalType(type)
    }
    return (
        <>
            {
                mobile || smallDesktop ?
                    <>
                        <TopNavHome
                            handleModalChooser={handleModalChooser}
                            country={country}
                            city={city}
                            lang={lang}
                            initialModal={initialModal}
                            setInitialModal={setInitialModal}
                            modalType={modalType}
                            setModalType={setModalType}
                            modalChooser={modalChooser}
                            setModalChooser={setModalChooser}
                        />
                    </>
                    :
                    <>
                        <TopNavLanguage
                            handleModalChooser={handleModalChooser}
                            country={country}
                            city={city}
                            lang={lang}
                            initialModal={initialModal}
                            setInitialModal={setInitialModal}
                            modalType={modalType}
                            setModalType={setModalType}
                            modalChooser={modalChooser}
                            setModalChooser={setModalChooser}
                        />
                        <TopNavHome
                            handleModalChooser={handleModalChooser}
                            country={country}
                            city={city}
                            lang={lang}
                            initialModal={initialModal}
                            setInitialModal={setInitialModal}
                            modalType={modalType}
                            setModalType={setModalType}
                            modalChooser={modalChooser}
                            setModalChooser={setModalChooser}
                        />
                    </>
            }
        </>
    )
}

export default TopNavMain