import { Col, Container, Row, Card } from "react-bootstrap"
import { t } from '../../../misc/utils'

import { useMediaQuery } from 'react-responsive'

const AnyQuestion = () => {
    const Desktop = useMediaQuery({ query: '(max-width:1199px)' })
    const mobile1 = useMediaQuery({ query: '(max-width:743px)' })
    return (
        <>
            {mobile1 == true ?
                <div className='card-7-register'>
                    <Card.Body className='background-pink card-7'>
                        <div className="container-default">
                            <Row>
                                <Col xs={12}>
                                    <div className='left-side-7'>
                                        <b>{t.register.question.any}</b>
                                        <p className='text'>
                                            {t.register.question.check}
                                        </p>
                                    </div>
                                </Col>
                                <Col xs={12}>
                                    <div className='left-side'>
                                        <a alt='' href='/guides' className='btn btn-warning-up'>{t.register.question.button}</a>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Card.Body>
                </div>
                :
                <div className='card-7-register'>
                    <Card.Body className='background-pink card-7'>
                        <div className="container-default">
                            <Row>
                                <Col xs={6}>
                                    <div className='left-side-7'>
                                        <b>{t.register.question.any}</b>
                                        <p className='text'>
                                            {t.register.question.check}
                                        </p>
                                    </div>
                                </Col>
                                <Col xs={6}>
                                    <div className='right-side-7'>
                                        <a alt='' href='/guides' className='btn btn-warning-up'>{t.register.question.button}</a>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Card.Body>
                </div>

            }
        </>
    )
}

export default AnyQuestion