const En = {
  languageModal: {
    textTitle: "Piliin ang iyong wika at bansa",
    textChooseCity: "Pumili ng lungsod",
    search: "Maghanap",
  },
  navbar: {
    textItem1: "MGA TUTORIAL",
    textItem2: "MGA CONTACT",
    textButton: "Magrehistro na",
    textRegis: "Pagrerehistro",
    textRegistration: "Paano idagdag ang iyong mga ibinebenta?",
    textProcess: "Paano magproseso ng mga order",
    textOrders: "(Para sa mga kliyente) Paano maglagay ng mga order",
    textComplete: "Paano kumumpleto ng mga order",
    textSm: "I-FOLLOW KAMI SA SOCIAL MEDIA",
    textSm1:
      "Makakuha ng mga tip at rekomendasyon sa kung paano gamitin ang app at ang service",
  },
  footer: {
    text: "2022 Maxim - nakalaan ang lahat ng karapatan",
    textPrivacy: "Patakaran sa Privacy",
  },
  qna: {
    textTitle: "FAQ",
    textContent1: "Puwede ba akong magrehistro ng higit sa isang merchant?",
    textContent2: "Magkano ang iyong bayad sa komisyon?",
    textContent3: "Sino ang magbabayad sa merchant?",
    textContent4: "Paano ko bubuksan ang aking outlet sa app?",
    textContent5: "Paano ko aalisin ang aking mga item sa app?",
    textContent6: "Paano ko babaguhin ang pagkakasunod-sunod ng mga kategorya?",
    textSubcontent1:
      "Puwede mo itong gawin, pero kailangan mong gumamit ng ibang email address para sa bawat merchant na irerehistro mo.",
    textSubcontent2:
      "Sa kasalukuyan, puwede kang kumita sa aming Service nang walang komisyon. Puwede mong tukuyin ang presyo ng mga putahe gaya sa isang menu.",
    textSubcontent3:
      "Babayaran ng driver ang order gamit ang sarili nilang pera, pagkatapos, makakatanggap sila ng bayad mula sa kliyente.",
    textSubcontent4:
      "I-tap ang Buksan kung handa ka nang kumuha ng mga order at i-tap ang Isara kung tapos ka nang magtrabaho.",
    textSubcontent5:
      "Hindi mo iyon magagawa sa ngayon. I-deactivate na lang ang item mo o palitan ito ng isa pa. ",
    textSubcontent6:
      "Nakaayos ang mga kategorya mula pinakaluma hanggang pinakabago, at hindi mo mababago ang pagkakasunod-sunod ng mga ito nang manu-mano.",
  },
  guides: {
    register: {
      textStep1:
        "Punan ang iyong impormasyon: maglagay ng password na gagamitin mo para mag-log in, gayundin ng iyong numero ng telepono.",
      textStep2: "Tukuyin ang address ng iyong cafe o lugar na paghahatiran.",
      textStep3:
        "I-upload ang logo ng iyong kumpanya o isang larawan ng iyong tindahan.",
      textStep4:
        "Tapos na! Puwede mo na ngayong i-upload ang iyong mga ibinebenta.",
    },
    addProduct: {
      textStep1:
        "Para magsimula, gumawa ng kategorya ng produkto, halimbawa, Mga meryenda. Puwede kang magdagdag ng maraming kategorya hangga't kailangan mo.",
      textStep2:
        'I-tap ang "susunod" sa tabi ng pangalan ng kategorya para idagdag ang iyong mga produkto. ',
      textStep3_1:
        "Ilagay ang impormasyon tungkol sa iyong produkto, tukuyin ang diskwento, kung gusto mong magbigay nito. Mas magiging kapansin-pansin ang iyong mga ibinebenta kung mayroon itong diskwento at magandang larawan.",
      textStep3_2:
        'Kung mamarkahan mo ang iyong produkto ng "Inirerekomenda", isa ito sa mga unang ipapakita sa iyong tindahan.',
    },
    processOrder: {
      textStep1:
        "Mag-tap sa Buksan para magsimulang magtrabaho. Kapag may bagong order, makakatanggap ka ng notification.",
      textStep2_1:
        'I-tap ang "Kumpirmahin ang order" para magsimulang gawin ito, o "Kanselahin ang order" kung, halimbawa, wala nang stock ang produkto sa ngayon.',
      textStep2_2:
        "Kung hindi mo kukumpirmahin ang order sa loob ng tatlong minuto, awtomatiko itong makakansela.",
      textStep3:
        "Inirerekomenda naming hintayin mo ang courier bago simulang ihanda ang order.",
    },
    placeOrder: {
      textStep1:
        'Pipiliin ng kliyenta ang rate ng Foods&Goods at magta-tap sa "What to buy"',
      textStep2:
        "Puwede silang pumili mula sa mga tindahan na nasa listahan o maghanap ng isang partikular na produkto.",
      textStep3:
        "Mauuna ang mga ibinebentang minarkahan mo bilang Inirerekomenda.",
      textStep4:
        "Puwedeng tukuyin ng kliyente ang bilang ng mga kalakal, at awtomatikong makakalkula ulit kung magkano ito.",
      textStep5:
        "Nasa app ang lahat ng feature para sa kaginhawaan ng kliyente, gaya ng pag-order para sa ibang tao o hindi nakakaabalang oras ng paghahatid.",
      textStep6:
        "Puwedeng subaybayan ng kliyente ang courier na may hawak ng kanilang order sa online map.",
    },
  },
  home: {
    card1: {
      textTitle: "Ibenta ang iyong mga kalakal sa Maxim",
      textContent:
        "Magkaroon ng access sa mga aktibo at nagbabayad na kliyente nang walang dagdag na bayad.",
      textButton: "Magrehistro na",
    },
    card2: {
      textTitle: "Makakuha ng mas marami pang kliyente",
      textContent1:
        "I-post ang iyong mga ibinebenta para makita ng libo-libong user ng Maxim app.",
      textContent2:
        "Tingnan ang lahat ng bentahe ng pakikipagtulungan sa amin.",
      textContentTitle1: "Walang komisyon",
      textContentTitle2: "Mabilis na paghahatid",
      textContentTitle3: "Libreng advertising",
      textSubcontent1:
        "Hindi kami naniningil ng porsyento ng benta — makuha ang buong kita sa pagbebenta ng mga kalakal at magtakda ng mga presyo gaya sa isang menu para makahikayat ng mga customer.",
      textSubcontent2:
        "Ihahatid ang iyong order ng courier na pinakamalapit sa lugar ng pick-up. Babayaran ng customer ang paghahatid. ",
      textSubcontent3:
        "Gagawa kami ng joint banner para sa iyong negosyo at magpo-post ng impormasyon tungkol sa iyong kumpanya sa aming mga app para sa mga driver at kliyente.",
    },
    card3: {
      textTitle: "Kumuha ng mga order sa isang madaling gamiting app",
      textContent1:
        "Magdagdag ng maraming outlet hangga't kailangan mo para sa isang brand. ",
      textContent2:
        "Gumawa ng kahit anong kategoryang gusto mo. Halimbawa, mga inumin, mga soup, mga second course, mga salad, atbp. ",
      textContent3:
        "Mag-upload ng mga nakakaengganyong larawan para mas mapansin kaysa sa ibang mga negosyo. ",
      textContent4:
        "Magtakda ng diskwento para makahikayat ng mas maraming customer.",
      textContent5:
        "Pag-aralan ang istatistika ng pagbili para makapag-alok ng mas marami pang sikat na item.",
    },
    card4: {
      textTitle: "Magsimula na!",
      textContent1: "Magrehistro",
      textContent2: "I-download ang aming app",
      textContent3: "Idagdag ang iyong mga ibinebenta",
      textContent4: "Hintayin ang mga una mong customer!",
      textButton: "Magrehistro na",
    },
    card5: {
      textTitle: "Libreng offline advertising",
      textContent1:
        "Gawing mas kapansin-pansin ang iyong negosyo. Magdidisenyo kami ng layout at gagawa ng joint banner para sa iyo nang libre Makakatulong ito sa iyong makahikayat ng mga kliyente at ipaalam sa kanila ang tungkol sa iyong online na serbisyo sa pag-order. ",
      textContent2:
        "Available sa mga nakarehistrong merchant ang pag-order ng banner. Makipag-ugnayan sa isa sa aming mga opisina.",
      textButton: "Tumingin ng mga office address",
    },
    card6: {
      textTitle: "Mga detalyadong gabay",
      textContent1: "Matuto kung paano gamitin ang aming serbisyo.",
      textSubcontent1: "Paano magrehistro",
      textSubcontent2: "Paano magdagdag ng mga ibinebenta",
      textSubcontent3: "Paano magproseso ng mga order",
      textSubcontent4: "(Para sa mga kliyente) Paano maglagay ng mga order",
      textSubcontent5: "(Para sa mga courier) Paano maghatid ng mga order",
    },
  },
  contact: {
    card1: {
      textTitle: "Opisina",
      textContent:
        "Jl. KH. Noer Ali No. 192, Kayuringin Jaya, Kec. Bekasi Sel., Kota Bks, West Java 17144",
      textPhone: "+ 6221-5092-8720",
    },
  },
  addgoods: {
    card1: {
      textTitle: "Paano idagdag ang iyong mga ibinebenta sa MaximMerchant app",
      textContent1:
        'Sa tab na ITEM, piliin ang KATEGORYA, i-tap ang "+" at gumawa ng bagong kategorya.',
      textContent2: "Gumawa ng maraming kategorya hangga't kailangan mo.",
      textContent3: 'Sa tab na ITEM, piliin ang MENU at i-tap ang "+".',
      textContent4_0:
        "Punan ang lahat ng field. Kung gusto mong maipakita ang mga ibinebenta mo sa home page ng merchant, i-activate ang rekomendasyon. Sa deskripsyon, puwede mong tukuyin ang pangalan ng item.",
      textContent4_1:
        "I-toggle ang switch para i-activate ang item. Makikita na ito ngayon ng mga kliyente sa kanilang app. Sa kasalukuyan, hindi ka makakapag-alis ng iyong mga item, pero puwede mong i-deactivate ang mga ito o palitan ang mga ito ng iba.",
      textContent4_2:
        "I-tap ang BUKSAN kung handa ka nang kumuha ng mga order. Pagkatapos mong magtrabaho, siguraduhing i-tap ang ISARA.",
    },
    card2: {
      textTitle: "Handa nang magsimula?",
      textContent:
        "Magrehistro at magsimulang i-promote ang iyong negosyo kasama namin!",
      textButton: "Pagrerehistro",
    },
  },
  merchant: {
    product: {
      labelShowCustomer: "Ipakita sa mga customer",
      labelName: "Pangalan ng produkto",
      labelDesc: "Deskripsyon",
      labelCat: "Kategorya",
      labelPrice: "Presyo",
      labelDisc: "Diskwento",
      labelDiscAmount: "Halaga ng diskwento",
      labelRecommend: "Inirerekomenda",
      labelChoosePhoto: "Pumili ng larawan",
      labelSaveAdd: "I-save ang produkto",
      labelSaveEdit: "I-save ang mga pagbabago",
      labelRequired: "Kinakailangan",
      labelNotRequired: "Hindi kinakailangan",
      LabelCancelEdit: "Kanselahin",
      LabelVariationAttach: "Naka-attach ang pagkakaiba",
    },
    btn: {
      pic: "Pumili ng larawan",
      submit: "Isumite",
      close: "Isara",
      add_more: "Magdagdag pa",
      schedule: "Magdagdag ng bagong iskedyul",
      edit: "I-edit",
      delete: "Alisin",
      variationAttach:
        "Mag-click dito para tingnan ang naka-attach na pagkakaiba sa item na ito",
    },
    schedule: {
      text: {
        title: "Mga oras na bukas",
        add: { title: "Idagdag ang iskedyul" },
        edit: { title: "I-edit ang iskedyul" },
        delete: {
          title: "Alisin ang iskedyul",
          alert: "Sigurado ka bang gusto mong alisin ang data?",
        },
      },
      day: {
        sunday: "Linggo",
        monday: "Lunes",
        tuesday: "Martes",
        wednesday: "Miyerkules",
        thursday: "Huwebes",
        friday: "Biyernes",
        saturday: "Sabado",
      },
      form: {
        day: "Araw",
        open: "Oras ng Pagbubukas",
        close: "Oras ng Pagsasara",
      },
    },
    err: {
      pic_fail: `Hindi ma-upload ang larawan, gumamit ng iba`,
      empty: `Hindi puwedeng blangko ang form`,
      no_pic: "Pumili ng larawan na ia-upload",
      get: "Nagka-error habang kumukuha ng data",
      no_data: "Walang data",
      add: "Nagka-error habang nagdadagdag ng data",
      edit: "Nagka-error habang nag-e-edit ng data",
      delete: "Nagka-error habang nag-aalis ng data",
      categoryFetch: "Hindi nakuha ang kategorya, pakisubukang mag-log in ulit",
      variationFetch:
        "Hindi nakuha ang pagkakaiba, pakisubukang mag-log in ulit",
      productFetch: "Hindi nakuha ang produkto, pakisubukang mag-log in ulit",
      connectedFetch:
        "Hindi nakuha ang naka-attach na pagkakaiba, pakisubukang mag-log in ulit",
      processImage:
        "Pumalyang maproseso ang larawan, subukan ulit o subukang mag-upload ng ibang larawan",
    },
  },
  register: {
    err: {
      title: "Error!",
      no_pic: "Pumili ng larawan na ia-upload",
      sent: "Error habang nagpapadala ng data sa server",
      email: "May ganito nang email na nakarehistro",
      empty: `Hindi puwedeng blangko ang form`,
      no_loc:
        "Piliin ang iyong lokasyon mula sa mapa (i-click nang dalawang beses ang mapa para makuha ang iyong kasalukuyang lokasyon o dalhin ang pointer sa iyong lokasyon)",
      required: "Kinakailangang punan",
      email_not_valid: "Hindi valid na email",
      pass_not_strong:
        "Hindi sapat ang lakas ng password. Gumamit ng hindi bababa sa 7 character at subukang pagsamahin ang malalaking titik, maliliit na titik, mga numero, at mga special character (!@#$%^&*_+=-)",
      pass_strong: "Malakas na password",
      pass_perfect: "Perpektong password",
    },
    btn: {
      close: "Isara",
      continue: "Magpatuloy",
      back: "Nakaraang hakbang",
      next: "Susunod na hakbang",
      register: "Magrehistro",
      photo: "Pumili ng larawan",
    },
    text: {
      step: {
        first: "Hakbang 1 sa 3",
        second: "Hakbang 2 sa 3",
        third: "Hakbang 3 sa 3",
        done: "Tapos na",
      },
      title: {
        merchant_info: "Impormasyon sa merchant",
        merchant_location: "Lokasyon ng tindahan",
        merchant_picture: "Larawan ng tindahan",
      },
      note: {
        registe_image:
          "Puwede mong i-upload ang iyong logo o ang larawan ng iyong pangunahing produkto.",
        agreement:
          "Sa pamamagitan ng pag-click sa Magrehistro, sumasang-ayon akong",
        tnc: "Mga tuntunin at kondisyon",
        done: "Matagumpay ang pagrerehistro. Para magsimulang magtrabaho, hintaying makumpirma namin ang iyong account, na karaniwang tumatagal nang ilang araw. Tatawagan ka namin o papadalhan ng email.",
        doneAndDownload:
          "Sa ngayon, puwede mong ihanda ang iyong mga ibinebenta sa pamamagitan ng pag-download ng aming app at paglalagay ng mga ito doon.",
      },
    },
    form: {
      phone: "Numero ng telepono",
      name: "Pangalan ng tindahan",
      email: "Email",
      desc: "Deskripsyon",
      password: "Password",
      referral: "Referral (kung mayroon ka)",
      country: "Bansa",
      city: "Lungsod",
      district: "Distrito",
      address: "Address",
    },
    question: {
      any: "May mga tanong ba?",
      check: "Tingnan ang aming mga tutorial.",
      button: "Tingnan ang mga tutorial",
    },
  },
  webView: {
    complete_add_title: "TAGUMPAY NA PAGDAGDAG NG PRODUKTO",
    complete_add_description:
      "Idinagdag ang produkto. Maaari kang magdagdag ng higit pa o maaari kang mag-isara ng pahinang ito.",
    complete_edit_title: "TAGUMPAY NA PAG-EDIT NG PRODUKTO",
    complete_edit_description:
      "Na-edit ang produkto. Maaari mong isara ang pahinang ito.",
    imageUploadError: "Ang file ng imahe ay masyadong malaki! Maximum na 5 MB.",
  },
};
export default En;
