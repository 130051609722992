import React from "react"
import { TextField } from "@mui/material"
import { Button, Container } from "react-bootstrap"
import Footer from "../../component/footer/footer"
import TopNavHome from "../../component/navbar/TopNavHome"
import AnyQuestion from "../../component/home/anyQuestion"
import { t, toUpper } from '../../../misc/utils'
import { StepOne, StepTwo, StepThree, StepFour } from "./registerStep"
import { useDispatch, useSelector } from "react-redux"
import { backPage, DataMerchantDetail, getCity, getCountry, getDistrict, LocationMerchantDetail, PositionMerchant, RegisterMerchant, removeRes, setLoading } from "../../../application/action"
import { PasswordMeter } from "password-meter"
// import imageplus from '../../../../assets/image/imageplus.png'
import heic2any from "heic2any"
import { Modal } from "react-bootstrap"
import ReactLoading from 'react-loading'
import vector from '../../../assets/image/Vector.png'
import TopNavMain from "../../component/navbar/TopNavMain"
import { getCroppedImg } from "../../../misc/imgutils"
import ErrorImageModalProduct from "../../../component/modal/ErrorImageModalProduct"


const Register = () => {
    const dispatch = useDispatch()
    const {
        name,
        email,
        password,
        desc,
        phone,
        ref,
        page,
        cities,
        district,
        lat,
        lon,
        Address,
        cities_id,
        district_id,
        response,
        loading,
        countries
    } = useSelector((state) => {
        return {
            name: state.Register.name,
            email: state.Register.email,
            password: state.Register.password,
            desc: state.Register.desc,
            phone: state.Register.phone,
            ref: state.Register.ref,
            page: state.Register.page,
            cities: state.Register.data_cities,
            district: state.Register.data_district,
            lat: state.Register.lat,
            lon: state.Register.lon,
            Address: state.Register.address,
            cities_id: state.Register.cities_id,
            district_id: state.Register.district_id,
            response: state.Register.responses,
            loading: state.Register.loading,
            countries: state.Register.data_country
        }
    })


    const [itemForm, setItemForm] = React.useState({
        name,
        desc,
        email,
        password,
        phone,
        ref,
        Address
    })

    const [bar, setBar] = React.useState({
        status: '',
        percent: 0
    })
    const [invalidEmail, setInvalidEmail] = React.useState(false)

    function validateEmail(email) {

        const re = /^[a-zA-Z0-9](?:[\w-\.]*[a-zA-Z0-9])+@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/g;
        let test = re.test(String(email).toLowerCase());
        setInvalidEmail(!test)

    }
    function validatePassWord(pass) {
        let passTest = new PasswordMeter().getResult(pass)
        setBar({
            status: passTest.status,
            percent: passTest.percent
        })
    }
    const handleItemform = (key, value) => {
        if (key === 'password') {
            validatePassWord(value)
        }
        if (key === 'email') {
            validateEmail(value)
        }
        setItemForm(prevState => ({
            ...prevState,
            [key]: key === 'phone' ? parseInt(value) : value
        }))
    }

    const [selectedCountry, setSelectedCountry] = React.useState(null)
    const [selectedCity, setSelectedCity] = React.useState(null)

    React.useEffect(() => {
        dispatch(getCountry())
        dispatch(getCity(selectedCountry))
        dispatch(getDistrict(selectedCity))
    }, [selectedCity, selectedCountry])

    const [selectedDistrict, setSelectedDistrict] = React.useState(null)
    const [err, setError] = React.useState(null)
    // const [address, setAddress] = React.useState(Address)


    const [imageShow, setImageShow] = React.useState(vector)
    const [image, setImage] = React.useState({})
    // const [cropper, setCropper] = React.useState()
    const [loadHeic, setLoadHeic] = React.useState(false)
    const [crop, setCrop] = React.useState({ x: 0, y: 0 })
    const [rotation, setRotation] = React.useState(0)
    const [zoom, setZoom] = React.useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = React.useState(null)
    const [croppedImage, setCroppedImage] = React.useState(null)

    const onCropComplete = React.useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])
    const [errImage, setErrImage] = React.useState(false)


    const handleChoose = (e) => {

        let imageName = e.target.value
        let extImage = imageName.substr(imageName.lastIndexOf('.') + 1)
        let img = e.target.files[0]

        if (img) {
            if (img.size <= 5000000) {
                setImageShow(URL.createObjectURL(img))
                setImage(img)
                console.log(extImage.toLowerCase(), 'cek heic')
                if (extImage.toLowerCase() === 'heic') {
                    setLoadHeic(true)
                    heic2any({ blob: img, toType: 'image/jpg', quality: 1 })
                        .then((newImage) => {
                            setImage(newImage)
                            setImageShow(URL.createObjectURL(newImage))
                            setLoadHeic(false)
                        })
                }
            } else {
                alert(t.webView.imageUploadError);
            }
        }
        else setImageShow(vector)

    }

    function dataURLtoFile(dataurl, filename) {

        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
    }

    const handleSubmit = async (step) => {
        if (step === 1) {

            for (const [key, value] of Object.entries(itemForm)) {
                if (value == null) {
                    if (key === 'phone') handleItemform(key, 62)
                    else if (key === 'Address') handleItemform(key, null)
                    else handleItemform(key, '')
                }
            }

            if (itemForm.name != '' && itemForm.name != null && itemForm.email != '' && !invalidEmail && itemForm.email != null && itemForm.phone != '' && itemForm.phone != null && itemForm.password != '' && itemForm.password != null && bar.percent >= 70) {
                let dataSave = {...itemForm}
                dataSave['name'] = toUpper(dataSave['name'])
                dispatch(DataMerchantDetail(dataSave))
            }
            // elseif(bar.percent )
            // else {
            //     if (itemForm.password && bar.percent < 70) alert(`password ${bar.status.toUpperCase()}`)
            //     else if (invalidEmail) alert('Invalid Email')
            //     else alert(`Can't empty`)
            // }
        }
        if (step === 2) {
            for (const [key, value] of Object.entries(itemForm)) {
                if (value == null) {
                    if (key === 'phone') handleItemform(key, 62)
                    else handleItemform(key, '')
                }
            }
            // if (itemForm.Address == '' || itemForm.Address == null || selectedCity === null || selectedDistrict === null || selectedCountry === null) alert(t.err.notfilled)
            if (!lat && !lon) alert(t.merchant.err.no_loc)
            else {
                let data = {
                    address: itemForm.Address,
                    cities_id: selectedCity,
                    district_id: selectedDistrict
                }

                dispatch(LocationMerchantDetail(data))
            }
        }
        if (step === 'back') {
            if (selectedCity && selectedDistrict && selectedCountry) {
                setSelectedCountry(null)
                setSelectedCity(null)
                setSelectedDistrict(null)
            }
            dispatch(backPage())
        }
        if (step === 3) {
            if (imageShow === vector) alert(t.register.err.no_pic)
            else if (Address == '' || Address == null || selectedCity == null || selectedDistrict == null || name === '' || name == null || email === '' || invalidEmail || email == null || phone === '' || phone == null || password === '' || password == null || bar.percent < 70) alert(t.register.err.empty)
            else {
                const croppedImage = await getCroppedImg(
                    imageShow,
                    croppedAreaPixels,
                    rotation
                )
                if (!croppedImage) {
                    console.log('failed');
                    dispatch(setLoading(false))
                    await setErrImage(true);
                    // #Note kalau crop gambar gagal
                }
                else {
                    const file_image_crop = await dataURLtoFile(croppedImage, Date.now());
                    const data = {
                        name: name,
                        email: email,
                        password: password,
                        password_confirmation: password,
                        cities_id: cities_id,
                        // desc: desc,
                        phone: phone,
                        lat: lat,
                        lon: lon,
                        districts_id: district_id,
                        image: file_image_crop,
                        address: Address,
                        ref: ref ? ref : '',
                    }
                    // setLoading(true)
                    dispatch(RegisterMerchant(data))
                }
            }

        }
    }

    // if (response === 'complete') setLoading(false)

    return (
        <div>
            <TopNavMain register={true} />
            <div style={{ margin: '30px 0px 60px 0px' }}>
                {
                    page === 1 ?
                        <StepOne
                            handleItemform={handleItemform}
                            itemForm={itemForm}
                            invalidEmail={invalidEmail}
                            bar={bar}
                            handleSubmit={handleSubmit}
                        />
                        :
                        page === 2 ?
                            <StepTwo
                                cities={cities}
                                district={district}
                                dispatch={dispatch}
                                PositionMerchant={PositionMerchant}
                                setSelectedCity={setSelectedCity}
                                setSelectedDistrict={setSelectedDistrict}
                                selectedCity={selectedCity}
                                useSelector={useSelector}
                                lat={lat}
                                handleSubmit={handleSubmit}
                                lon={lon}
                                itemForm={itemForm}
                                handleItemform={handleItemform}
                                selectedDistrict={selectedDistrict}
                                countries={countries}
                                setSelectedCountry={setSelectedCountry}
                                selectedCountry={selectedCountry}
                            />
                            : page === 3 ?
                                <StepThree
                                    handleSubmit={handleSubmit}
                                    imageShow={imageShow}
                                    imageplus={vector}
                                    handleChoose={handleChoose}
                                    // setCropper={setCropper}
                                    loadHeic={loadHeic}
                                    cropImage={{ zoom, crop, rotation, setCrop, setRotation, onCropComplete, setZoom }}
                                />
                                : page === 4 ?
                                    <StepFour />
                                    :
                                    <></>
                }
                {/* <StepFour /> */}
            </div>
            {/* {croppedImage &&
            <div>
                <img src={croppedImage}/>
            </div>
            } */}
            <AnyQuestion />
            <Footer />
            {/* Loading */}
            <Modal show={loading} centered>
                <Modal.Body>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <ReactLoading type='spin' color='red' height={70} width={70} />
                        <h1 style={{ fontSize: 30, fontWeight: 500, marginTop: 15, marginLeft: 20 }}>Loading ...</h1>
                    </div>

                </Modal.Body>
            </Modal>

            {/* err response */}
            <Modal show={response} onHide={() => {
                dispatch(removeRes())
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>{t.register.err.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{response === 'Error 404' ? t.register.err.sent : response === "The email has already been taken." ? t.register.err.email : t.register.err.sent}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => {
                        dispatch(removeRes())
                    }}>
                        {t.register.btn.close}
                    </Button>
                </Modal.Footer>
            </Modal>

            <ErrorImageModalProduct errImage={errImage} setErrImage={setErrImage} t={t} />

        </div>
    )
}
export default Register