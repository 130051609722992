const En = {
  languageModal: {
    textTitle: "请选择您的语言和国家",
    textChooseCity: "选择城市",
    search: "查找",
  },
  navbar: {
    textItem1: "教程",
    textItem2: "联系人",
    textButton: "立即注册",
    textRegis: "注册",
    textRegistration: "如何添加您的商品",
    textProcess: "如何处理订单",
    textOrders: "（针对客户）如何下单",
    textComplete: "如何完成订单",
    textSm: "在社交媒体上关注我们",
    textSm1: "获取如何使用应用程序和服务的提示和建议",
  },
  footer: {
    text: "2022 Maxim——保留所有权利",
    textPrivacy: "隐私政策",
  },
  qna: {
    textTitle: "常见问题",
    textContent1: "我可以注册多个商家吗？",
    textContent2: "您的佣金费是多少？",
    textContent3: "谁向商家付款？",
    textContent4: "如何在应用程序中打开我的专营店？",
    textContent5: "如何删除应用中的项目？",
    textContent6: "如何改变类别顺序？",
    textSubcontent1: "可以，但您需要为每个注册商家使用不同的电子邮箱地址。",
    textSubcontent2:
      "目前您可以通过我们的服务免佣金赚钱。你可以在菜单中指定价格。",
    textSubcontent3: "司机用自己的钱支付订单，然后收到客户的付款。",
    textSubcontent4: "如果您准备好接单，点击打开，完成后点击关闭。",
    textSubcontent5:
      "现在无法做这件事。只需停用你的物品或用另一个物品替换它。 ",
    textSubcontent6: "类别从最早到最新进行排序，您不能手动更改它们的顺序。",
  },
  guides: {
    register: {
      textStep1: "填写您的信息：请输入登录密码以及联系电话号码。",
      textStep2: "指定您的咖啡厅或送货点的地址。",
      textStep3: "上传您公司的logo或您商店的图片。",
      textStep4: "全部完成！您现在可以继续上传您的商品。",
    },
    addProduct: {
      textStep1: "首先要创建产品类别，例如零食。您可以添加尽可能多的类别。",
      textStep2: "点击类别名称旁边的“+”来添加您的产品。 ",
      textStep3_1:
        "输入产品的信息，请指定您愿意提供的折扣。折扣和高质量的照片将使您的商品更出色。",
      textStep3_2:
        "如果您将您的产品标记为推荐产品，它将显示在您的商店中的首批产品中。",
    },
    processOrder: {
      textStep1: "点击打开开始工作。当有新订单时，您将收到通知。",
      textStep2_1:
        "点击“确认订单”开始工作。若您的产品目前没有库存，则点击“取消订单”。",
      textStep2_2: "如果您在3分钟内不确认订单，它将自动取消。",
      textStep3: "我们建议您等快递员到达再开始准备订单。",
    },
    placeOrder: {
      textStep1: "客户选择食品和商品费率，然后点击“购买”。",
      textStep2: "他们可以从列表中选择商店或搜索特定产品。",
      textStep3: "您标记为推荐的商品先出现。",
      textStep4: "客户可以指定商品数量，费用将自动重新计算。",
      textStep5:
        "该应用的功能给客户带来便利，例如给不同的人下单或指定合适的配送时间。",
      textStep6: "客户可以通过在线地图追踪配送订单的快递员。",
    },
  },
  home: {
    card1: {
      textTitle: "用Maxim出售您的商品",
      textContent: "无需额外费用就能与活跃的消费群体联系。",
      textButton: "立即注册",
    },
    card2: {
      textTitle: "获取更多客户",
      textContent1: "将您的商品发布给成千上万的Maxim应用用户查看。",
      textContent2: "查看所有与我们合作的好处。",
      textContentTitle1: "免佣金",
      textContentTitle2: "快速配送",
      textContentTitle3: "免费广告",
      textSubcontent1:
        "我们不收取销售费用（从销售商品中获得所有收入）的提成，并在菜单中设置价格以吸引客户。",
      textSubcontent2:
        "您的订单将由靠近取货点的快递员配送。配送费由客户支付。 ",
      textSubcontent3:
        "我们将为您的业务制作一个横幅广告，并在我们的应用中给司机和客户发布有关您公司的信息。",
    },
    card3: {
      textTitle: "在简易的应用中下单",
      textContent1: "为一个品牌尽可能多地添加销售点。 ",
      textContent2: "创建您想要的任何类别。例如，饮料、汤、主菜、沙拉等等。 ",
      textContent3: "上传有吸引力的照片，让您的业务更加出色。 ",
      textContent4: "设置折扣以吸引更多客户。",
      textContent5: "分析购买数据以提供更多受欢迎的物品。",
    },
    card4: {
      textTitle: "立即开始！",
      textContent1: "注册",
      textContent2: "下载应用程序",
      textContent3: "添加您的商品",
      textContent4: "等待您的首位客户！",
      textButton: "立即注册",
    },
    card5: {
      textTitle: "免费离线广告",
      textContent1:
        "让您的业务处于突出地位。我们将为您设计一个布局并免费为您制作一个横幅广告。它将帮助您吸引客户，并告诉他们您的在线订购服务信息。 ",
      textContent2: "已注册商家可订购广告。请联系我们的办事处。",
      textButton: "查看办事处地址",
    },
    card6: {
      textTitle: "详细指南",
      textContent1: "学习如何使用我们的服务。",
      textSubcontent1: "如何注册",
      textSubcontent2: "如何添加商品",
      textSubcontent3: "如何处理订单",
      textSubcontent4: "（针对客户）如何下单",
      textSubcontent5: "（针对快递员）如何配送订单",
    },
  },
  contact: {
    card1: {
      textTitle: "办事处",
      textContent:
        "Jl. KH. Noer Ali No. 192, Kayuringin Jaya, Kec. Bekasi Sel., Kota Bks, West Java 17144",
      textPhone: "+ 6221-5092-8720",
    },
  },
  addgoods: {
    card1: {
      textTitle: "如何在MaximMerchant应用中添加您的商品",
      textContent1: "在物品栏中，选择类别，点击“+”并创建一个新类别。",
      textContent2: "创建您需要的尽可能多的类别。",
      textContent3: '在物品栏中，选择菜单然后点击 "+" 。',
      textContent4_0:
        "填写所有字段。如果您想在商家的主页上显示您的商品，请激活推荐。在描述中，您可以指定物品名称。",
      textContent4_1:
        "切换开关以激活该物品。现在，客户将在应用中看到它们。目前，您不能删除您的物品，但您可以停用它们或用其他东西替换。",
      textContent4_2: "当您准备接单时，点击打开。完成后，请确保点击关闭。",
    },
    card2: {
      textTitle: "准备开始？",
      textContent: "注册并与我们一起推广您的业务！",
      textButton: "注册",
    },
  },
  merchant: {
    product: {
      labelShowCustomer: "向客户显示",
      labelName: "产品名称",
      labelDesc: "描述",
      labelCat: "类别",
      labelPrice: "价格",
      labelDisc: "折扣",
      labelDiscAmount: "折扣金额",
      labelRecommend: "推荐",
      labelChoosePhoto: "选择照片",
      labelSaveAdd: "保存产品",
      labelSaveEdit: "保存修改",
      labelRequired: "必填",
      labelNotRequired: "不必填",
      LabelCancelEdit: "取消",
      LabelVariationAttach: "可选已添加",
    },
    btn: {
      pic: "选择照片",
      submit: "提交",
      close: "关闭",
      add_more: "添加更多",
      schedule: "添加新日程",
      edit: "编辑",
      delete: "删除",
      variationAttach: "点击这里查看商品附加可选项",
    },
    schedule: {
      text: {
        title: "营业时间",
        add: { title: "添加新日程" },
        edit: { title: "编辑日程" },
        delete: {
          title: "删除日程",
          alert: "确定删除数据吗？",
        },
      },
      day: {
        sunday: "星期日",
        monday: "星期一",
        tuesday: "星期二",
        wednesday: "星期三",
        thursday: "星期四",
        friday: "星期五",
        saturday: "星期六",
      },
      form: {
        day: "日",
        open: "开启时间",
        close: "关闭时间",
      },
    },
    err: {
      pic_fail: `无法上传图像，请使用其他照片`,
      empty: `表格不能为空`,
      no_pic: "选择照片上传",
      get: "获取数据出错",
      no_data: "无数据",
      add: "添加数据出错",
      edit: "编辑数据出错",
      delete: "删除数据出错",
      categoryFetch: "获取类别失败，请重新登录",
      variationFetch: "获取可选项失败，请重新登录",
      productFetch: "获取产品失败，请重新登录",
      connectedFetch: "获取可选项失败，请重新登录",
      processImage: "处理图像失败，请重试或尝试上传不同的图像",
    },
  },
  register: {
    err: {
      title: "错误！",
      no_pic: "选择照片上传",
      sent: "向服务器发送数据时出错",
      email: "邮箱已被注册",
      empty: `表格不能为空`,
      no_loc:
        "在地图中选择您的位置（点击地图两次以获得您当前位置或移动指针到您的位置 ）",
      required: "必填项",
      email_not_valid: "无效邮箱",
      pass_not_strong:
        "密码强度不够。至少使用7个字符，并尽量结合大写、小写、数字和特殊字符（!@#$%^&*_+=-）。",
      pass_strong: "强密码",
      pass_perfect: "最佳密码",
    },
    btn: {
      close: "关闭",
      continue: "继续",
      back: "上一步",
      next: "下一步",
      register: "注册",
      photo: "选择照片",
    },
    text: {
      step: {
        first: "步骤1/3",
        second: "步骤2/3",
        third: "步骤3/3",
        done: "完成",
      },
      title: {
        merchant_info: "商家信息",
        merchant_location: "店铺位置",
        merchant_picture: "店铺照片",
      },
      note: {
        registe_image: "您可以上传您的logo或主产品的图片。",
        agreement: "点击注册即表示同意",
        tnc: "条款和条件",
        done: "注册成功。开始工作前，您需要等待我们确认您的账户，这通常需要几天。我们会给您打电话或发送电子邮件。",
        doneAndDownload:
          "同时，您可以通过下载我们的应用并添加商品来为销售做准备。",
      },
    },
    form: {
      phone: "电话号码",
      name: "店铺名称",
      email: "邮箱",
      desc: "描述",
      password: "密码",
      referral: "推荐来源（若有）",
      country: "国家",
      city: "城市",
      district: "地区",
      address: "地址",
    },
    question: {
      any: "是否有问题？",
      check: "查看我们的教程。",
      button: "观看教程",
    },
  },
  webView: {
    complete_add_title: "添加产品成功",
    complete_add_description: "产品已添加。您可以继续添加或关闭此页面",
    complete_edit_title: "编辑产品成功",
    complete_edit_description: "产品已编辑。您可以关闭此页面",
    imageUploadError: "图片文件太大了！最大为5 MB。",
  },
};
export default En;
