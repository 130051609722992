import {t} from '../../../misc/utils'

const ScheduleCard = ({ key, idx, data, onEdit, onDelete }) => {
    const days = data.day === 0 ? t.merchant.schedule.day.sunday 
    : data.day === 1 ? t.merchant.schedule.day.monday 
    : data.day === 2 ? t.merchant.schedule.day.tuesday 
    : data.day === 3 ? t.merchant.schedule.day.wednesday 
    : data.day === 4 ? t.merchant.schedule.day.thursday 
    : data.day === 5 ? t.merchant.schedule.day.friday 
    : data.day === 6 ? t.merchant.schedule.day.saturday 
    : 'Undifined'

    return (
        <div key={key} className='schedule-card'>
            <div style={{ width:150, marginBottom:10 }}>
                <span style={{ fontSize: 18, fontWeight: 600 }}>{days},</span>
            </div>
            <div style={{ marginBottom:10 }}>
                <span style={{ fontSize:18 }}>{data.open}</span> - <span>{data.close}</span>
            </div>
            <div style={{ marginBottom:10 }}>
                <button onClick={() => onEdit(idx)} className='btn-schedule-card'> {t.merchant.btn.edit} </button>
                <button onClick={() => onDelete(idx)} className='btn-schedule-card'>{t.merchant.btn.delete}</button>
            </div>
        </div>
    )
}

export default ScheduleCard