import React, { } from "react";
import { Modal } from "react-bootstrap";
import {
  connectAddon,
  fetchCatAddon,
  fetchConnectedCatAddon,
  fetchItemCatWithItem,
} from "../../../application/action";

import './style.css'
import ReactLoading from "react-loading";
import {t} from '../../../misc/utils'
import { useDispatch, useSelector } from "react-redux";
import ErrorFetchModalProduct from "../../../component/modal/ErrorFetchModalProduct";

const Variation = (props) => {
  const dispatch = useDispatch()
  // console.log(props, 'xixixaxa');
  const token = new URLSearchParams(props.location.search).get("token");
  const itemID = new URLSearchParams(props.location.search).get("id");
  const catAddonID = new URLSearchParams(props.location.search).get("cat_addon_id");
  const [connected, setConnected] = React.useState([]);
  const [connectedCat, setConnectedCat] = React.useState([]);
  const [deleteConnectedCat, setDeleteConnectedCat] = React.useState([]);
  const [cat, setCat] = React.useState(null);
  const [Item, setItem] = React.useState(null);
  const [dimension, setDimension] = React.useState(window.innerWidth);
  const [refreshData, setRefreshData] = React.useState(false)
  const [loading, setLoading] = React.useState(true)
  const [error, setError] = React.useState({})

  const { FetchErr } = useSelector((state) => {
    return {
        FetchErr: state.Product.errors_message,
    };
});

  console.log(dimension, 'xaxaxi');

  function areEqual(arr1, arr2) {
    console.log(arr1, arr2, 'equal');
    // sort the data by id
    arr1 = arr1.sort((prev, next) => prev.id - next.id)
    arr2 = arr2.sort((prev, next) => prev.id - next.id)

    // Check if the arrays are the same length
    if (arr1.length !== arr2.length) {
      return false;
    }

    // Check if each object in arr1 exists in arr2
    for (let i = 0; i < arr1.length; i++) {
      if (JSON.stringify(arr1[i]) !== JSON.stringify(arr2[i])) {
        return false;
      }
    }

    // If we made it here, the arrays match
    return true;
  }

  const checkboxHandle = (checked, addon, type, checkCatProd = null) => {
    let processData = [...connectedCat];
    let processDataDelete = [...deleteConnectedCat]

    if (checked) {
      if (checkCatProd !== null) {
        checkCatProd.map((product) => {
          const indexDeleted = processDataDelete.findIndex((item) => item.id_merchant_item === product.id)
          if (!processData.some(item => item.id_merchant_item === product.id)) {
            if (indexDeleted >= 0) {
              processData.push({ ...processDataDelete[indexDeleted], deleted_at: null })
              processDataDelete.splice(indexDeleted, 1)
            }
            else {
              processData.push({ id_addon_cat: parseInt(catAddonID), id_merchant_item: product.id })
            }
          }
        })
      } else {
        const indexDeleted = processDataDelete.findIndex((item) => (type === 'item' ? item.id_addon_cat : item.id_merchant_item) === addon.id)
        if (indexDeleted >= 0) {
          processData.push({ ...processDataDelete[indexDeleted], deleted_at: null })
          processDataDelete.splice(indexDeleted, 1)
        }
        else {
          processData.push(type === 'item' ? { id_addon_cat: addon.id, id_merchant_item: itemID } : { id_addon_cat: parseInt(catAddonID), id_merchant_item: addon.id })
        }
      }
    } else {
      if (checkCatProd !== null) {
        checkCatProd.map((product) => {
          const indexConnected = processData.findIndex((item) => item.id_merchant_item === product.id)
          const connected_delete = connected.findIndex((item) => item.id_merchant_item === product.id)
          if (indexConnected >= 0) {
            if (processData[indexConnected].deleted_at === null) {
              processDataDelete.push({ ...processData[indexConnected], deleted_at: connected_delete < 0 ? 1 : connected[connected_delete].deleted_at === null ? 1 : connected[connected_delete].deleted_at })
            }
            processData.splice(indexConnected, 1)
          }
          else {
            processData.splice(indexConnected, 1)
          }
        })
      }
      else {
        const indexConnected = processData.findIndex((item) => (type === 'item' ? item.id_addon_cat : item.id_merchant_item) === addon.id)
        const connected_delete = connected.findIndex((item) => (type === 'item' ? item.id_addon_cat : item.id_merchant_item) === addon.id)
        if (indexConnected >= 0) {
          if (processData[indexConnected].deleted_at === null) {
            processDataDelete.push({ ...processData[indexConnected], deleted_at: connected_delete < 0 ? 1 : connected[connected_delete].deleted_at === null ? 1 : connected[connected_delete].deleted_at })
          }
          processData.splice(indexConnected, 1)
        }
        else {
          processData.splice(indexConnected, 1)
        }
      }
    }
    setDeleteConnectedCat(processDataDelete)
    setConnectedCat(processData);
  };

  const handleSave = () => {
    setLoading(true)
    if (!areEqual(connectedCat, connected.filter(element => element.deleted_at === null))) {
      let dataConnected = [...connectedCat]
      dataConnected = dataConnected.sort((prev, next) => prev.id - next.id).filter((item, index) => {
        if (!connected.sort((prev, next) => prev.id - next.id).filter(element => element.deleted_at === null).some(element => JSON.stringify(element) === JSON.stringify(item))) {
          delete dataConnected[index]['created_at']
          delete dataConnected[index]['updated_at']
          return item
        }
      })
      let dataDeleted = [...deleteConnectedCat]
      dataDeleted = dataDeleted.filter((item) => {
        if (item.deleted_at === 1) {
          return item
        }
      })
      if (dataConnected.length !== 0) {
        dataConnected = {
          data_connect: [...dataConnected]
        }
        connectAddon(token, dataConnected).then((res) => {
          setRefreshData((prev) => prev = !prev)
        })
      }
      if (dataDeleted.length !== 0) {
        dataDeleted = {
          data_connect: [...dataDeleted]
        }
        connectAddon(token, dataDeleted).then((res) => {
          setRefreshData((prev) => prev = !prev)
        })
      }
    }
    setLoading(false)
  };

  const subMainFunction = {
    handleCancel: () => {
      props.history.goBack();
    },
    updateDimensions: () => {
      setDimension(window.innerWidth);
    }
  }

  React.useEffect(() => {
    if (itemID) {
      fetchCatAddon(token).then((res) => {
        setCat(res?.result);
      }).catch(() => {
        dispatch({
        type: 'ERRFETCH',
        payload: 'Category variation failed'
        })
      });
      fetchConnectedCatAddon(token, itemID, 'item').then((res) => {
        setConnected(res?.result);
        const connect = res?.result.map((item) => {
          if (item.deleted_at === null) {
            return item
          }
          else {
            return null
          }
        }).filter(item => item !== null)
        const notConnected = res?.result.map((item) => {
          if (item.deleted_at !== null) {
            return item
          }
          else {
            return null
          }
        }).filter((item) => item !== null)
        setDeleteConnectedCat(notConnected)
        setConnectedCat(connect);

      }).catch(() => {
        dispatch({
          type: 'ERRFETCH',
          payload: 'Variation failed'
      })
      });
    }
    else if (catAddonID) {
      fetchItemCatWithItem(token).then((res) => {
        setItem(res?.result)
      }).catch(() => {
        dispatch({
          type: 'ERRFETCH',
          payload: 'Product failed'
      })
      })
      fetchConnectedCatAddon(token, catAddonID, 'catAddon').then((res) => {
        setConnected(res?.result);
        const connect = res?.result.map((item) => {
          if (item.deleted_at === null) {
            return item
          }
          else {
            return null
          }
        }).filter(item => item !== null)
        const notConnected = res?.result.map((item) => {
          if (item.deleted_at !== null) {
            return item
          }
          else {
            return null
          }
        }).filter((item) => item !== null)
        setDeleteConnectedCat(notConnected)
        setConnectedCat(connect);

      }).catch(() => {
        dispatch({
          type: 'ERRFETCH',
          payload: 'Variation failed'
      })
      });
    }
  }, [refreshData]);

  React.useEffect(() => {
    window.addEventListener("resize", subMainFunction.updateDimensions);
    return () => window.removeEventListener("resize", subMainFunction.updateDimensions);
  }, [])


  console.log(cat, connectedCat)

  return (
    <div className="container-product">
      <div className="container-product-content">
        {Item?.map((item_cat) => {
          function check_item_cat() {
            let dataConnected = [...connectedCat]
            dataConnected = dataConnected.filter(
              (choosed) => {
                if (item_cat?.items?.some(item => item.id === choosed.id_merchant_item)) {
                  return choosed
                }
              }
            )
            console.log(dataConnected, item_cat.items)
            return dataConnected.length === item_cat.items.length
          }
          return (
            <div className="container-variation">
              <div className="content-body-variation">
                <div className="item-variation">                  
                  <div className="item-variation-text-title">
                    <span className="item-variation-text-title">{item_cat.name}</span>
                  </div>
                  <div className="item-variation-input">
                    <input
                      checked={check_item_cat()}
                      type={"checkbox"}
                      onChange={(e) => checkboxHandle(e.target.checked, null, 'catProductCheck', item_cat.items)}
                    />
                  </div>
                </div>

                {item_cat?.items?.map((product) => {
                  function check() {
                    return connectedCat.filter(
                      (choosed) => {
                        // delete choosed['item_addon_cat_connector_id']
                        return product.id === choosed.id_merchant_item
                      }
                    ).length === 0
                      ? false
                      : true;
                  }
                  return (
                    <>
                      <div className="container-variation">
                        <div className="content-body-variation-product" onClick={(e) => dimension > 500 ? checkboxHandle(!check(), product, 'product') : ""}>
                          <div className="item-variation" onClick={(e) => dimension <= 500 ? checkboxHandle(!check(), product, 'product') : ""}>
                            <div className="item-variation-text">
                              <span>{product.name}</span>
                            </div>
                            <div className="item-variation-input">
                              <input
                                checked={check()}
                                type={"checkbox"}
                                onChange={(e) => checkboxHandle(e.target.checked, product)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )
                })}
              </div>
            </div>
          )
        })}
        <div className="container-variation">
          {cat?.map((item) => {
            function check() {
              return connectedCat.filter(
                (choosed) => {
                  // delete choosed['item_addon_cat_connector_id']
                  return item.id === choosed.id_addon_cat
                }
              ).length === 0
                ? false
                : true;
            }
            return (
              <div className="content-body-variation" onClick={(e) => dimension > 500 ? checkboxHandle(!check(), item, 'item') : ""}>
                <div className="item-variation" onClick={(e) => dimension <= 500 ? checkboxHandle(!check(), item, 'item') : ""}>
                  <div className="item-variation-input">
                    <div className="item-variation-text">
                      <span>{item?.name}</span>
                      <div className="item-variation-desc">
                        <span>{item.require ? t.merchant.product.labelRequired : t.merchant.product.labelNotRequired}</span>
                        <br />
                        <span> </span>
                        {item?.item_addon?.map((item1, idx) => {
                          return (
                            <span>{(idx ? ', ' : '') + item1.name}</span>
                          )
                        })}
                        
                      </div>
                    </div>
                    <input
                      checked={check()}
                      type={"checkbox"}
                      onChange={(e) => checkboxHandle(e.target.checked, item, 'item')}
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="content-bottom-variation">
          <div className="content-bottom-variation-button">
            {catAddonID ? 
            !areEqual(connectedCat, connected.filter(element => element.deleted_at === null)) && <button className="content-button-variation-submit button-variation-width-90-percent" onClick={handleSave}>{t.merchant.product.labelSaveEdit}</button>
            :
            <>
            <button className="content-button-variation-cancel" onClick={subMainFunction.handleCancel}>{areEqual(connectedCat, connected.filter(element => element.deleted_at === null)) ? t.merchant.btn.close : t.merchant.product.LabelCancelEdit}</button>
            &nbsp;
            <button className="content-button-variation-submit" disabled={areEqual(connectedCat, connected.filter(element => element.deleted_at === null))} onClick={handleSave}>{t.merchant.product.labelSaveEdit}</button>
            </>
            }
          </div>
        </div>
      </div>
      <ErrorFetchModalProduct  FetchErr={FetchErr} t={t} dispatch={dispatch} />
    </div>
  );
};

export default Variation;
