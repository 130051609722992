const InputImageProduct = ({ ImagePreview, imageDefault, handleChooseImage, error }) => {
  return (
    <>
      <label htmlFor="upload-photo">
        <form encType="multipart/form-data">
          <input
            src={imageDefault}
            style={{ display: "none" }}
            id="upload-photo"
            name="IMG"
            type="file"
            accept=".jpg, .jpeg, .png, .heic"
            onChange={handleChooseImage}
          />
        </form>
        <span>
          <img src={ImagePreview} className='image-default-container'
          />
        </span>
      </label>
      {error &&
        <p className="text-error-image">No Image</p>
      }
    </>
  )
}

export default InputImageProduct