// Component
import { Card, Carousel, Row, Col } from "react-bootstrap"
import { useMediaQuery } from "react-responsive"
import React, { useState } from "react"
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import 'swiper/swiper.min.css';


import registration from '../../../../assets/component/regis.svg'
import addproduct from '../../../../assets/component/goods.svg'
import processing from '../../../../assets/component/process.svg'
import clienthome from '../../../../assets/component/place.svg'
import driver from '../../../../assets/component/driver.svg'
import { t } from '../../../../misc/utils'

const Guides = () => {
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };

    const desktopScreen = useMediaQuery({
        query: '(max-width:1235px)'
    })
    const mobile = useMediaQuery({
        query: '(max-width:743px)'
    })

    const arrGuides = [
        { text: t.home.card6.textSubcontent1, image: registration, alt: 'Registration', route: '/guides/registration' },
        { text: t.home.card6.textSubcontent2, image: addproduct, alt: 'Adding products', route: '/guides/addingproduct' },
        { text: t.home.card6.textSubcontent3, image: processing, alt: 'Processing orders', route: '/guides/processproduct' },
        { text: t.home.card6.textSubcontent4, image: clienthome, alt: 'Client make orders', route: '/guides/makeorder' },
        { text: t.home.card6.textSubcontent5, image: driver, alt: 'Drivers receive orders', route: '/guides/acceptorder' },
    ]

    return (
        <>
            {
                desktopScreen && mobile == false ?
                    <Card.Body className='card-white card-6'>
                        <div className="container-default">
                            <div className='text'>
                                <p className='label'>{t.home.card6.textTitle}</p>
                                <p>{t.home.card6.textContent1}</p>
                                {/* <p>{t.subTitle.Card6_2}</p> */}
                                <Swiper
                                    modules={[Navigation, Pagination, Scrollbar, A11y]}
                                    spaceBetween={100}
                                    slidesPerView={3}
                                    navigation
                                    pagination={{ clickable: true }}
                                    scrollbar={{ draggable: true }}
                                >
                                    <SwiperSlide>
                                        <Card className='text-center'>
                                            <a href='/guides/registration'>
                                                <Card.Body>
                                                    <img src={registration} alt='Registration' />
                                                </Card.Body>
                                                <Card.Text>
                                                    {t.home.card6.textSubcontent1}
                                                </Card.Text>
                                            </a>
                                        </Card>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <Card className='text-center'>
                                            <a href='/guides/addingproduct'>
                                                <Card.Body>
                                                    <img src={addproduct} alt='Registration' />
                                                </Card.Body>
                                                <Card.Text>
                                                    {t.home.card6.textSubcontent2}
                                                </Card.Text>
                                            </a>
                                        </Card>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <Card className='text-center'>
                                            <a href='/guides/processproduct'>
                                                <Card.Body>
                                                    <img src={processing} alt='Registration' />
                                                </Card.Body>
                                                <Card.Text>
                                                    {t.home.card6.textSubcontent3}
                                                </Card.Text>
                                            </a>
                                        </Card>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <Card className='text-center'>
                                            <a href='/guides/makeorder'>
                                                <Card.Body>
                                                    <img src={clienthome} alt='Registration' />
                                                </Card.Body>
                                                <Card.Text>
                                                    {t.home.card6.textSubcontent4}
                                                </Card.Text>
                                            </a>
                                        </Card>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <Card className='text-center'>
                                            <a href='/guides/acceptorder'>
                                                <Card.Body>
                                                    <img src={driver} alt='Registration' />
                                                </Card.Body>
                                                <Card.Text>
                                                    {t.home.card6.textSubcontent5}                                                    
                                                </Card.Text>
                                            </a>
                                        </Card>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <Card className='text-center' hidden>
                                            <a>
                                                <Card.Body>
                                                    <img src={driver} alt='Registration' />
                                                </Card.Body>
                                                <Card.Text>
                                                    {t.home.card6.textSubcontent5}
                                                </Card.Text>
                                            </a>
                                        </Card>
                                    </SwiperSlide>
                                </Swiper>
                            </div>
                        </div>
                    </Card.Body >
                    :
                    mobile == true ?
                        <Card.Body className='card-white card-6'>
                            <div className="container-default">
                                <div className='text'>
                                    <p className='label'>{t.home.card6.textTitle}</p>
                                    <p>{t.home.card6.textContent}</p>
                                    {/* <p>{t.subTitle.Card6_2}</p> */}
                                    <Swiper
                                        modules={[Navigation, Pagination, Scrollbar, A11y]}
                                        spaceBetween={240}
                                        slidesPerView={2}
                                        navigation
                                        pagination={{ clickable: true }}
                                        scrollbar={{ draggable: true }}
                                    >
                                        <SwiperSlide>
                                            <Card className='text-center'>
                                                <a href='/guides/registration'>
                                                    <Card.Body>
                                                        <img src={registration} alt='Registration' />
                                                    </Card.Body>
                                                    <Card.Text>
                                                        {t.home.card6.textSubcontent1}
                                                    </Card.Text>
                                                </a>
                                            </Card>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <Card className='text-center'>
                                                <a href='/guides/addingproduct'>
                                                    <Card.Body>
                                                        <img src={addproduct} alt='Registration' />
                                                    </Card.Body>
                                                    <Card.Text>
                                                        {t.home.card6.textSubcontent2}
                                                    </Card.Text>
                                                </a>
                                            </Card>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <Card className='text-center'>
                                                <a href='/guides/processproduct'>
                                                    <Card.Body>
                                                        <img src={processing} alt='Registration' />
                                                    </Card.Body>
                                                    <Card.Text>
                                                        {t.home.card6.textSubcontent3}
                                                    </Card.Text>
                                                </a>
                                            </Card>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <Card className='text-center'>
                                                <a href='/guides/makeorder'>
                                                    <Card.Body>
                                                        <img src={clienthome} alt='Registration' />
                                                    </Card.Body>
                                                    <Card.Text>
                                                        {t.home.card6.textSubcontent4}
                                                    </Card.Text>
                                                </a>
                                            </Card>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <Card className='text-center'>
                                                <a href='/guides/acceptorder'>
                                                    <Card.Body>
                                                        <img src={driver} alt='Registration' />
                                                    </Card.Body>
                                                    <Card.Text>
                                                        {t.home.card6.textSubcontent5}
                                                    </Card.Text>
                                                </a>
                                            </Card>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <Card className='text-center' hidden>
                                                <a>
                                                    <Card.Body>
                                                        <img src={driver} alt='Registration' />
                                                    </Card.Body>
                                                </a>
                                            </Card>
                                        </SwiperSlide>
                                    </Swiper>
                                </div>
                            </div>
                        </Card.Body >
                        :
                        <Card.Body className='card-white card-6'>
                            <div className="container-default">
                                <div className='text'>
                                    <p className='label'>{t.home.card6.textTitle}</p>
                                    <p>{t.home.card6.textContent}</p>
                                    {/* <p>{t.subTitle.Card6_2}</p> */}
                                    <Row>
                                        {arrGuides.map((item, index) => {
                                            return (
                                                <Col xs={4} key={index}>
                                                    <Card className='text-center'>
                                                        <a href={item.route} alt=''>
                                                            <Card.Body>
                                                                {item.image === driver ?
                                                                    <div style={mobile ? {} : {}}>
                                                                        <img src={item.image} alt={item.alt} />
                                                                    </div> :
                                                                    <img src={item.image} alt={item.alt} />
                                                                }
                                                                <Card.Text>
                                                                    {item.text}
                                                                </Card.Text>
                                                            </Card.Body>
                                                        </a>
                                                    </Card>
                                                </Col>
                                            )
                                        })}
                                    </Row>
                                </div>
                            </div>
                        </Card.Body >
            }
        </>
    )
}

export default Guides